import axios from "axios";
import * as H from "history";
import { IUser } from "./api";

class RouterUtils {
  public history?: H.History<unknown> = undefined;

  public setupRouterUtils(history: H.History<unknown>) {
    this.history = history;
  }
}

export const routerUtils = new RouterUtils();

/* const client = axios.create({
  baseURL: "https://server-develop.facilitelink.com.br/",
  timeout: 60000,
}); */

const client = axios.create({
  baseURL: "https://server.facilitelink.com.br/",
  timeout: 60000,
});

// const client = axios.create({
//   baseURL: "http://localhost:3000/",
//   timeout: 60000,
// });

if (localStorage.getItem("user")) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentUser = JSON.parse(localStorage.getItem("user")!) as IUser;
  if (currentUser) {
    client.defaults.headers["access-token"] =
      currentUser.auth_params.access_token;
    client.defaults.headers.client = currentUser.auth_params.client;
    client.defaults.headers.uid = currentUser.auth_params.uid;
  }
}

if (localStorage.getItem("loggedInAs")) {
  const userId = JSON.parse(localStorage.getItem("loggedInAs")!) as string;
  if (userId) {
    client.defaults.headers["HTTP-IMPERSONATE-USER"] = userId;
  }
}

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
      }
    }
    return Promise.reject(error);
  },
);

// client.interceptors.response.use(
//   (response) => Promise.resolve(response),
//   (error) => {
//     // if (error.response.status === 403) {
//     //   routerUtils.history?.push("/planos");
//     // }
//     // return Promise.reject(error);
//   },
// );

export default client;
