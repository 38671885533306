import { makeAutoObservable } from "mobx";
import React from "react";

import { InputProps } from "src/ui/components";

export default class FormControllerStore<FormData> {
  private fields: FormData;

  constructor(fields: FormData) {
    this.fields = fields;
    makeAutoObservable(this);
  }

  public onChange = (
    event: React.ChangeEvent<InputProps>,
    field: keyof FormData,
    mask?: (value: string) => string,
  ) => {
    this.fields = {
      ...this.fields,
      [field]: mask ? mask(event.target.value as string) : event.target.value,
    };
  };

  public getFields = () => this.fields;

  public getField = (field: keyof FormData) => ({
    name: field,
    value: this.fields[field],
    onChange: (event, mask?: (value: string) => string) =>
      this.onChange(event, field, mask),
  });

  public setField = (field: keyof FormData, value?: string | number) => {
    this.fields = {
      ...this.fields,
      [field]: value,
    };
  };
}
