import styled from "styled-components";

export const Container = styled.div`
  > label {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    margin-bottom: 8px;
    display: block;
  }
  > .input-container {
    display: flex;
    width: 100%;
    /* height: 55px; */
    margin-bottom: 27px;
    border-radius: 5px;
    flex-direction: row;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: ${({ theme }) => theme.colors.white};
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    > .icon {
      width: 33px;
      height: 33px;
      margin-right: 15px;
      display: flex;
      background-color: ${({ theme }) => theme.colors.greyDarker};
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        display: block;
      }
    }
    > .iconDelete {
      right: 0;
      top: 0;
      position: absolute;
      cursor: pointer;
      > img {
        width: 20px;
        height: 20px;
      }
    }
    > .placeholder {
      color: ${({ theme }) => theme.colors.greyDarker};
      font-size: ${({ theme }) => theme.fontSizes.s16}px;
      display: block;
    }
    > .input-wrapper {
      flex: 1;
      > input {
        width: 100%;
        height: 50px;
      }
    }
  }
`;
