import styled from "styled-components";

export const Container = styled.div`
  > label {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    margin-bottom: 8px;
    display: block;
    > span {
      color: #f00;
    }
  }
  > .input-container {
    display: flex;
    width: 100%;
    height: 55px;
    margin-bottom: 27px;
    border-radius: 5px;
    flex-direction: row;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: ${({ theme }) => theme.colors.white};
    > input {
      flex: 1;
      height: 100%;
      border: none;
      padding: 0 15px;
      font-size: ${({ theme }) => theme.fontSizes.s18}px;
      color: ${({ theme }) => theme.colors.greyDarker};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      border-radius: 5px;
      ::placeholder {
        color: ${({ theme }) => theme.colors.grey};
      }
    }
    > button.toggleVisible {
      width: 55px;
      height: 100%;
      border: none !important;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.colors.greyDark};
    }
  }
`;
