import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 0;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 45px;
  > button.go-back {
    border: none;
    background: transparent;
    margin-right: 26px;
  }
  > h1 {
    font-size: ${({ theme }) => theme.fontSizes.s24}px;
    color: ${({ theme }) => theme.colors.purpleDark};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
