const formatters = {
  toCurrency: (cents: number) =>
    (cents / 100).toLocaleString(
      window !== undefined ? window.navigator.language : "pt-BR",
      {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
    ),
};

export default formatters;
