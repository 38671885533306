import styled from "styled-components";

export const Content = styled.div`
  margin-top: 16px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;

  h1 {
    font-size: 20px;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ClientForm = styled.div`
  margin-top: 16px;

  label {
    font-size: 14px;
  }

  .input-container,
  .select-container {
    height: 40px;
    filter: none;
    border: 1px solid var(--gray-400, #ced4da);

    input,
    select {
      font-size: 14px;
    }
  }
`;
