import client from "src/http/client";

export interface IState {
  id: number;
  name: string;
  short_name: string;
}

export default class StatesService {
  public async getStates(): Promise<IState[]> {
    const response = await client.get<IState[]>("/storefront/v1/states");
    return response.data;
  }
}
