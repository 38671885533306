import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  > .code-wrapper {
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    > img {
      min-width: 260px;
      max-width: 480px;
      width: 100%;
      height: fit-content;
      display: block;
      object-fit: contain;
      margin-top: -20px;
    }
    > .share-button-wrapper {
      min-width: 260px;
      max-width: 480px;
      width: 100%;
    }
  }
`;
