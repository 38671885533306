import client from "src/http/client";
import { IDaysAndTimes } from "src/http/interfaces/IDaysAndTimes";

export interface ICompany {
  address: string;
  auto_scheduling: string;
  avatar: string;
  cashier_auto: string;
  company_name: string;
  created_at: string;
  font_color: string;
  id: string;
  link: string;
  overdue_bills_reminder: string;
  primary_color: string;
  scheduling_for_hour: string;
  secondary_color: string;
  slug: string;
  updated_at: string;
  user_id: string;
  profile: string;

  whatsapp?: string;
}

export default class CompaniesService {
  public async getCompany(slug: string): Promise<ICompany> {
    const response = await client.get<ICompany>(`/${slug}`);
    return response.data;
  }

  public async getWorkingDays(companyId: string): Promise<IDaysAndTimes[]> {
    const url = `/storefront/v1/companies/working_days?company_user=${companyId}`;
    const response = await client.get<IDaysAndTimes[]>(url);
    return response.data;
  }
}
