import { action, makeObservable, observable } from "mobx";

import api from "src/http/api";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public loader: LoadingStore;
  public financial;

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      createFinancial: action,
      updateFinancial: action,
      deleteFinancial: action,
      financial: observable,
    });
  }

  public createFinancial = async (onSuccess: () => void, financial) => {
    try {
      this.loader.start();

      await api.createFinancial(financial);
      this.showSuccess("Financeiro inserido com sucesso!");
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public updateFinancial = async (
    onSuccess: () => void,
    id,
    paid,
    user_id,
    discount,
  ) => {
    try {
      this.loader.start();

      await api.updateFinancial(id, paid, user_id, discount);
      this.showSuccess("Financeiro atualizado com sucesso!");
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public deleteFinancial = async (onSuccess: () => void, id) => {
    try {
      this.loader.start();

      await api.deleteFinancial(id);
      this.showSuccess("Financeiro deletado com sucesso!");
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public getOneFinancial = async (id) => {
    try {
      this.loader.start();

      this.financial = await api.getOneFinancial(id);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
