import React from "react";

import { MenuMobile, MenuDesktop, Footer } from "src/ui/components";

import { Container } from "./styles";

interface ScreenContainerProps {
  accountBlocked?: boolean;
}
const ScreenContainer: React.FC<ScreenContainerProps> = (props) => {
  const { children, accountBlocked } = props;
  return (
    <Container>
      <MenuDesktop accountBlocked={accountBlocked} />
      <MenuMobile accountBlocked={accountBlocked} />
      <div className="content-wrapper">{children}</div>
      <Footer absolute />
    </Container>
  );
};

export default ScreenContainer;
