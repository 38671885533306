import React from "react";
import { IoMdCopy } from "react-icons/io";
import useCopyClipboard from "react-use-clipboard";

import { Container } from "./styles";
interface IProps {
  title: string;
  copiedAlertText: string;
  textToCopy: string;
  color: string;
}

export const ShareButton: React.FC<IProps> = (props) => {
  const { title, copiedAlertText, textToCopy, color } = props;

  const [isCopied, setCopied] = useCopyClipboard(textToCopy, {
    successDuration: 2000,
  });

  return (
    <Container onClick={setCopied} color={color}>
      <div className="wrapper">
        <span className="text">{isCopied ? copiedAlertText : title}</span>
        <IoMdCopy size={30} />
      </div>
    </Container>
  );
};
