import { action, makeObservable, observable } from "mobx";

import SupportService, { IContactUs } from "src/services/support";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public contactUs: IContactUs[] = [];
  public loader: LoadingStore;
  public supportService: SupportService;

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    this.supportService = new SupportService();

    makeObservable(this, {
      getContactUs: action,
      contactUs: observable,
    });
  }

  public getContactUs = async () => {
    try {
      this.loader.start();
      this.contactUs = await this.supportService.getContactUs();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
