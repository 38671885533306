import styled from "styled-components";

export const ButtonCopy = styled.button`
  width: 100%;
  height: 45px;
  background: ${({ theme }) => theme.colors.orangeDark};
  border-radius: 10px;
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  > .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    > .text {
      width: 230px;
      display: block;
      margin-right: 15px;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
    }
  }
`;

export const ContainerButtons = styled.button`
  width: 80%;
  display: flex;
  justify-content: center;
`;
