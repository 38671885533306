import * as H from "history";
import { observer } from "mobx-react";
import React from "react";

import masks from "src/resources/masks";
import theme from "src/theme";
import { Button, Input, ModalHeader } from "src/ui/components";

import { Container } from "./styles";

interface IProps {
  onGoBack: () => void;
  createOrder: (onSuccess: () => void) => void;
  closeModal: () => void;
  formController: any;
  history: H.History<unknown>;
}

const CardForm: React.FC<IProps> = (props) => {
  const { formController, onGoBack, createOrder, closeModal, history } = props;

  const onSuccess = () => {
    closeModal();
    history.push("/");
  };

  const onPressConfirm = () => {
    createOrder(onSuccess);
  };

  return (
    <Container>
      <ModalHeader title="Pagar com cartão" onGoBack={onGoBack} />
      <div className="form-wrapper">
        <Input
          label="Nome do titular do cartão"
          {...formController.getField("name")}
        />
        <Input
          label="Número do cartão"
          maxLength={19}
          {...formController.getField("cardNumber")}
          onChange={(e) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            formController.getField("cardNumber").onChange(e, masks.cardNumber)
          }
        />
        <Input
          label="Validade"
          placeholder="Ex: 10/2028"
          maxLength={7}
          {...formController.getField("cardExpirationMonth")}
          onChange={(e) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            formController
              .getField("cardExpirationMonth")
              .onChange(e, masks.cardExpirationMonth)
          }
        />
        <Input
          label="Código de segurança"
          maxLength={3}
          {...formController.getField("cardCVV")}
        />
      </div>
      <Button
        title="Finalizar"
        color={theme.colors.purpleDark}
        onClick={onPressConfirm}
      />
    </Container>
  );
};

export default observer(CardForm);
