import React from "react";

import { Container } from "./styles";

interface IInputCheckBox extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactElement;
  onClick?: () => void;
  colorBorder?: string;
}

export const InputCheckBox: React.FC<IInputCheckBox> = (props) => {
  const { label, onClick, colorBorder, style, ...rest } = props;

  return (
    <Container onClick={onClick} colorBorder={colorBorder} style={style}>
      {label}
      <input type="checkbox" {...rest} />
      <span className="checkmark" />
    </Container>
  );
};
