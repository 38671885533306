/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { observer } from "mobx-react";
import React, { useState } from "react";
import { BiHelpCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { FaPhone } from "react-icons/fa";

import ScreenContainer from "src/layout/ScreenContainer";

import theme from "src/theme";
import { Button, Card, CardLabel } from "src/ui/components";

import { CardsRow } from "../ScheduleScreen/styles";
import ContactUs from "./components/ContactUs";
import Tutorials from "./components/Tutorials";
import UserProfile from "./components/UserProfile";
import { Container, MenuItem } from "./styles";

const SupportScreen: React.FC = () => {
  const [activatedMenu, setActivatedMenu] = useState("Fale conosco");

  function handleActiveMenu(menu) {
    setActivatedMenu(menu);
  }

  return (
    <ScreenContainer>
      <Container>
        <CardLabel>Ajuda e Suporte técnico</CardLabel>
        <CardsRow style={{ gap: 15, marginBottom: 40 }}>
          <Card>
            <MenuItem
              isActive={activatedMenu === "Fale conosco"}
              onClick={() => handleActiveMenu("Fale conosco")}
            >
              <span className="title">Fale Conosco</span>
              <div className="icon">
                <FaPhone />
              </div>
            </MenuItem>
          </Card>

          <Card>
            <MenuItem
              isActive={activatedMenu === "Tutoriais"}
              onClick={() => handleActiveMenu("Tutoriais")}
            >
              <span className="title">Tutoriais</span>
              <div className="icon">
                <BiHelpCircle />
              </div>
            </MenuItem>
          </Card>

          <Card>
            <MenuItem
              isActive={activatedMenu === "Perfil"}
              onClick={() => handleActiveMenu("Perfil")}
            >
              <span className="title">Perfil</span>
              <div className="icon">
                <CgProfile />
              </div>
            </MenuItem>
          </Card>
        </CardsRow>

        {(function () {
          if (activatedMenu === "Fale conosco") {
            return <ContactUs />;
          } else if (activatedMenu === "Tutoriais") {
            return <Tutorials />;
          } else if (activatedMenu === "Perfil") {
            return <UserProfile />;
          } else {
            return null;
          }
        })()}

        <Button
          color={theme.colors.green}
          title="Whatsapp"
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=5547992230429&text=Preciso%20de%20suporte",
            );
          }}
          mb={15}
        />
      </Container>
    </ScreenContainer>
  );
};

export default observer(SupportScreen);
