import { makeObservable, observable, action } from "mobx";

import api from "src/http/api";
import { IServicesProvided } from "src/http/interfaces/IServicesProvided";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public servicesProvided: IServicesProvided[] = [];
  public serviceProvided;
  public loader: LoadingStore;

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      servicesProvided: observable,
      serviceProvided: observable,
      getServicesProvided: action,
    });
  }

  public getServicesProvided = async (userId) => {
    try {
      this.loader.start();
      const servicesProvidedTeste = await api.getServicesProvided(userId);
      this.servicesProvided = [...servicesProvidedTeste];
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
