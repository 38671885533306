import styled from "styled-components";

export const Container = styled.div`
  > img {
    margin: auto;
    display: block;
  }
  > h1 {
    font-size: ${({ theme }) => theme.fontSizes.s32}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: center;
    margin-bottom: 16px;
  }
  > span {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    text-align: center;
    margin-bottom: 16px;
    display: block;
  }
`;
