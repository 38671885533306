import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 30px;
  > span {
    display: block;
    margin-bottom: 10px;
  }
`;

export const RadioOptions = styled.label`
  font-weight: 500;
  display: flex;

  > input[type="radio"] {
    -webkit-appearance: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    appearance: none;
    background-color: #fff;
    font: inherit;
    color: currentColor;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid ${({ theme }) => theme.colors.greyDarker};
    border-radius: 50%;
    transform: translateY(-0.075em);
    margin-right: 10px;
    margin-bottom: 5px;

    display: grid;
    place-content: center;
  }

  > input[type="radio"]::before {
    content: "";
    width: 1.05em;
    height: 1.05em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em
      ${({ theme, color }) => (color ? color : theme.colors.purple)};
  }

  > input[type="radio"]:checked::before {
    transform: scale(1);
  }
`;
