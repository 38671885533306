import { action, makeObservable, observable } from "mobx";

import api, { INewPayment, IOrder } from "src/http/api";
import masks from "src/resources/masks";
import CitiesService, { ICity } from "src/services/cities";
import StatesService, { IState } from "src/services/states";
import ViaCepService, { IZipCodeData } from "src/services/viaCep";
import AuthStore from "src/stores/AuthStore";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private loader: LoadingStore;
  private authStore: AuthStore;
  public statesService: StatesService;
  public states: IState[] = [];
  public citiesService: CitiesService;
  public cities: ICity[] = [];
  public viaCepServiceService: ViaCepService;
  public zipCodeData?: IZipCodeData;
  public order?: IOrder;
  public currentScreen: "initial" | "credit_card" | "pix" | "bank_slip" =
    "initial";
  public formController = new FormControllerStore({
    name: "",
    cardCVV: "",
    zip_code: "",
    number: "",
    street: "",
    neighborhood: "",
    city_id: "",
    state_id: "",
    complement: "",
    cardNumber: "",
    cardExpirationMonth: "",
    paymentMethod: "",
  });

  constructor(loader: LoadingStore, authStore: AuthStore) {
    super();
    this.loader = loader;
    this.authStore = authStore;
    this.statesService = new StatesService();
    this.citiesService = new CitiesService();
    this.viaCepServiceService = new ViaCepService();
    makeObservable(this, {
      states: observable,
      cities: observable,
      zipCodeData: observable,
      order: observable,
      currentScreen: observable,
      createOrder: action,
      getStates: action,
      getCitiesByState: action,
      getZipCodeData: action,
      setCurrentScreen: action,
    });
  }

  public getStates = async () => {
    try {
      this.loader.start();
      this.states = await this.statesService.getStates();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public getCitiesByState = async (stateId: number) => {
    try {
      this.loader.start();
      this.cities = await this.citiesService.getCitiesByState(stateId);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public setCurrentScreen = (currentScreen: typeof this.currentScreen) => {
    this.currentScreen = currentScreen;
  };

  public createOrder = async (planId: number, onSuccess?: () => void) => {
    try {
      this.loader.start();
      const {
        name,
        cardNumber,
        cardCVV,
        cardExpirationMonth,
        zip_code,
        number,
        street,
        neighborhood,
        city_id,
        state_id,
        complement,
        paymentMethod,
      } = this.formController.getFields();

      const ownerName = name.split(" ");

      const paymentPayload: INewPayment = {
        plan_id: planId,
        payment_method: paymentMethod,
        card_params:
          paymentMethod === "credit_card"
            ? {
                first_name: ownerName[0],
                last_name: ownerName[ownerName.length - 1],
                number: cardNumber.trim(),
                verification_value: cardCVV.trim(),
                month: cardExpirationMonth.split("/")[0],
                year: cardExpirationMonth.split("/")[1],
                installments: 0,
              }
            : undefined,
        user_address: {
          zip_code,
          number,
          street,
          neighborhood,
          city:
            this.cities.find((city) => city.id === parseInt(city_id))?.name ||
            "",
          state:
            this.states.find((state) => state.id === parseInt(state_id))
              ?.short_name || "",
          complement,
          address_charge: true,
        },
      };

      this.order = await api.createPayment(paymentPayload);

      if (paymentMethod === "credit_card" && onSuccess) {
        onSuccess();
        return;
      }
      if (paymentMethod === "pix") {
        this.setCurrentScreen("pix");
        return;
      }
      if (paymentMethod === "bank_slip") {
        this.setCurrentScreen("bank_slip");
        return;
      }
    } catch (e) {
      this.showError(e.response.data.message);
    } finally {
      this.loader.end();
    }
  };

  public onChangeZipCode = (e) => {
    this.formController.getField("zip_code").onChange(e, masks.zipCode);
    const zipCode = e.target.value.replace(/\D/g, "");
    this.getZipCodeData(zipCode);
  };

  public getZipCodeData = async (zipCode: string) => {
    if (zipCode.length === 0) {
      this.formController.setField("street", "");
      this.formController.setField("neighborhood", "");
      this.formController.setField("state_id", "");
      this.formController.setField("city_id", "");
    }

    if (zipCode.length < 8) {
      return;
    }

    try {
      this.loader.start();
      const response = await this.viaCepServiceService.getZipCodeData(zipCode);

      if (response.erro) {
        this.showError("Cep não encontrado!");
      }

      this.formController.setField("street", response.logradouro);
      this.formController.setField("neighborhood", response.bairro);
      const state = this.states
        .filter(function (uf) {
          return uf.short_name === response.uf;
        })
        .map(function (uf) {
          return uf.id;
        });

      this.formController.setField("state_id", state[0]);
      const cities = await this.citiesService.getCitiesByState(state[0]);
      const selectedCity = cities
        .filter(function (allCities) {
          return allCities.name === response.localidade;
        })
        .map(function (allCities) {
          return allCities.id;
        });

      this.formController.setField("city_id", selectedCity[0]);

      this.zipCodeData = response;
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public releaseAfterPayment = (invoice) => api.checkPayment(invoice.invoice);
}
