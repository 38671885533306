import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  > .code-wrapper {
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    > img {
      width: 100%;
      max-width: 425px;
      min-width: 260px;
      height: 70px;
      object-fit: contain;
    }
    > span {
      font-size: ${({ theme }) => theme.fontSizes.s12}px;
      text-align: center;
      width: 100%;
      max-width: 425px;
      min-width: 260px;
      margin: 10px 0 40px 0;
    }
    > .share-button-wrapper {
      width: 100%;
      max-width: 425px;
      min-width: 260px;
    }
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;

  > caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }

  > thead {
    > tr {
      // border: 1px solid #ddd;
      padding: 0.35em;

      > th {
        font-size: 0.85em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 0.625em;
        text-align: center;
      }
    }
  }

  > tbody {
    > tr {
      // border: 1px solid #ddd;
      padding: 0.35em;

      > td {
        padding: 0.625em;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 600px) {
    border: 0;

    > caption {
      font-size: 1.3em;table
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    > thead {
      > tr {
        border-bottom: 1px solid #ddd;
        display: none;
        margin-bottom: 0.625em;
      }
    }

    > tbody {
      > tr {
        // border-bottom: 1px solid #ddd;
        border: 0;
        display: block;
        margin-bottom: 0.625em;

        > td {
          border-bottom: 1px solid #ddd;
          display: block;
          font-size: 1em;
          text-align: right;
        }

        > td::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
          margin-right: 20px;
        }

        > td:last-child {
          border-bottom: 0;
        }
      }
    }
  }
`;
