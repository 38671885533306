import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";

import { useGlobalStore } from "src/context/global";
import { Table, Card, CardInner } from "src/ui/components/index";

import Store from "./store";
import { Container } from "./styles";

const Tutorials: React.FC = () => {
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  useEffect(() => {
    store.getTutorials();
  }, []);

  return (
    <Container>
      <Card>
        <CardInner>
          <Table>
            <thead>
              <tr>
                <td colSpan={3}>Videoaulas</td>
              </tr>
            </thead>

            <tbody>
              {store.tutorials.map((tutorial) => (
                <tr key={tutorial.title}>
                  <td>{tutorial.title}</td>
                  <td>
                    <a href={tutorial.url} target="_blank" rel="noreferrer">
                      Assistir
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardInner>
      </Card>
    </Container>
  );
};

export default observer(Tutorials);
