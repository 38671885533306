import { observer } from "mobx-react";
import React from "react";
import { ThemeProvider } from "styled-components";

import { GlobalContextProvider } from "src/context/global";
import { ModalProvider } from "src/context/modal";
import { Loading } from "src/ui/components";

import GlobalStyle from "../resources/global-style";
import Routes from "../routes";
import theme from "../theme";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const App = () => (
  <React.StrictMode>
    <GlobalContextProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <GlobalStyle />
            <Routes />
            <Loading />
          </ModalProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </GlobalContextProvider>
  </React.StrictMode>
);

export default observer(App);
