import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 0;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 45px;

  > button.go-back {
    border: none;
    background: transparent;
    margin-right: 26px;
  }

  > div h1 {
    font-size: ${({ theme }) => theme.fontSizes.s24}px;
    color: ${({ theme }) => theme.colors.purpleDark};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  > div span {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.greyDark};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    margin-bottom: 5px;

    @media (min-width: 500px) {
      & {
        font-size: ${({ theme }) => theme.fontSizes.s16}px !important;
      }
    }
  }

  label {
    font-size: ${({ theme }) => theme.fontSizes.s12}px !important;

    @media (min-width: 500px) {
      & {
        font-size: ${({ theme }) => theme.fontSizes.s16}px !important;
      }
    }
  }

  .daysAndTime {
    > .daysAndTime__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      label,
      span {
        flex: 1;
      }

      .daysAndTime__launch {
        color: ${({ theme }) => theme.colors.greyDarker};
        font-size: ${({ theme }) => theme.fontSizes.s12}px;

        @media (min-width: 500px) {
          & {
            font-size: ${({ theme }) => theme.fontSizes.s16}px !important;
          }
        }
      }

      .daysAndTime__hour input {
        border-radius: 5px;
        border: 1px solid #f8834b;
        margin-left: 5px;
        width: 100px;
        height: 28px;
        font-size: 16px;
        color: #f8834b;
        padding: 7px;
        text-align: center;
      }

      .daysAndTime__button_duplicate {
        margin-left: 5px;
        width: 15px;

        button {
          color: ${({ theme }) => theme.colors.purpleDark};
        }
      }
    }
  }
`;
