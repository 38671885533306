import styled from "styled-components";

interface ContainerProps {
  color: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  border-left: solid 4px;
  border-left-color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.white};
  padding: 10px;
  align-items: center;
  border-radius: 6px;
  margin: 15px 0;
  > div.icon-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: ${({ color }) => color};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }
  > .message-wrapper {
    flex: 1;
    > b {
      font-size: ${({ theme }) => theme.fontSizes.s16}px;
      color: ${({ theme }) => theme.colors.greyDarker};
    }
    > p {
      font-size: ${({ theme }) => theme.fontSizes.s14}px;
      color: #6f6f6f;
    }
  }
`;
