import React from "react";

import placeholder from "src/assets/images/blank.png";
import { INewSocialNetworking } from "src/http/interfaces/INewSocialNetworking";
import { IService } from "src/services/services";
import theme from "src/theme";
import { Calendar, Card } from "src/ui/components";

import {
  Container,
  IphoneX,
  Footer,
  Header,
  Service,
  ServiceList,
  Wrapper,
  Image,
  ContainerHeader,
  Title,
  PreviewContainer,
  TextFooter,
  SocialNetworking,
  SocialNetworkingList,
  Icon,
  ContainerTitle,
  CardLabel,
} from "./styles";

interface IProps {
  primary_color: string;
  secondary_color: string;
  font_color: string;
  services: IService[];
  title: string;
  address: string;
  image: string;
  service: IService | null;
  setService: (service: IService) => void;
  socialMedia: INewSocialNetworking[];
}

const ProfilePicture: React.FC<IProps> = (props) => {
  const {
    font_color,
    image,
    address,
    services,
    title,
    primary_color,
    secondary_color,
    service,
    socialMedia,
    setService,
  } = props;

  return (
    <Container>
      <IphoneX>
        <PreviewContainer>
          <Header color={primary_color || theme.colors.purpleDark}>
            <ContainerHeader>
              <Image src={image || placeholder} />
              <ContainerTitle>
                <Title color={font_color}>{title}</Title>
                <Title color={font_color}>{address}</Title>
              </ContainerTitle>
            </ContainerHeader>
          </Header>
          <Wrapper>
            {services.length > 0 && (
              <>
                <CardLabel>
                  Selecione um ou mais serviços para agendar
                </CardLabel>
                <ServiceList>
                  {services.map((item) => (
                    <Service
                      key={item.id}
                      onClick={() => setService(item)}
                      color={service === item ? primary_color : ""}
                      defaultValue={
                        service === item ? theme.colors.white : primary_color
                      }
                    >
                      {item.name}
                    </Service>
                  ))}
                </ServiceList>
              </>
            )}
            <CardLabel>Datas disponíveis para agendamento</CardLabel>
            <Card fontColor={theme.colors.greyDarker}>
              <Calendar color={secondary_color} />
            </Card>
            <SocialNetworkingList>
              {socialMedia.map((social) => (
                <SocialNetworking key={social.link} color={primary_color}>
                  <Icon src={social.logo_url} />
                </SocialNetworking>
              ))}
            </SocialNetworkingList>
          </Wrapper>
          <Footer color={primary_color || theme.colors.purpleDark}>
            <TextFooter color={font_color || theme.colors.white}>
              Uma ferramenta Facilite Link
            </TextFooter>
          </Footer>
        </PreviewContainer>
      </IphoneX>
    </Container>
  );
};

export default ProfilePicture;
