import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;

  .schedules {
    display: flex;
    gap: 20px;

    &__item {
      width: 100%;

      .title {
        margin-bottom: 8px;
        margin-top: 42px;
      }
    }

    &__cards {
      display: flex;
      gap: 25px;

      .card {
        width: 230px;
        height: 289px;
        background: #ffffff;
        box-shadow: 0px 2.50602px 2.50602px rgba(0, 0, 0, 0.25);
        border-radius: 9.39759px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex-grow: 1;

        &__title {
          font-size: 24px;
          color: #4f4f4f;
          margin-top: 15px;
          height: 80px;
        }

        &__value {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 64px;
          width: 100%;
          height: 100%;
          color: #f8834b;

          &.purple {
            color: #b111bb;
          }
        }

        @media (max-width: 1000px) {
          & {
            width: 166px;
            height: 158px;

            &__title {
              font-size: 16px;
              margin-top: 5px;
              height: 30px;
            }
          }
        }
      }
    }

    @media (max-width: 900px) {
      & {
        display: block !important;
      }
    }
  }
`;

export const CardsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    width: 48%;
  }
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 16px;
  .react-calendar__tile--active {
    background: transparent !important;
    border-radius: 5px;
    > abbr {
      color: ${({ theme }) => theme.colors.greyDarker};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }
`;

interface IScheduledClients {
  scheduleType: "day" | "week";
}

export const ScheduledClients = styled.div<IScheduledClients>`
  padding: 8px 17px;
  text-align: center;
  width: 100%;
  > span {
    color: ${({ theme }) => theme.colors.greyDarker};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }
  > h1 {
    color: ${({ theme, scheduleType }) =>
      scheduleType === "day"
        ? theme.colors.orangeDark
        : theme.colors.purpleDark};
    font-size: ${({ theme }) => theme.fontSizes.s64}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const ScheduleWaiting = styled.div`
  > span.title {
    color: ${({ theme }) => theme.colors.greyDarker};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    display: block;
  }
  > span.date {
    color: ${({ theme }) => theme.colors.purpleDark};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
  }
`;

export const CongratsFirstLogin = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s18}px;
  line-height: 25px;
  text-align: center;
  color: ${({ theme }) => theme.colors.greyDarker};
  margin-bottom: 15px;
  > b {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.purpleDark};
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
  }
`;

export const HeaderModalFirstLogin = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s18}px;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.purpleDark};
  margin-bottom: 15px;
`;

export const BodyModalFirstLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .firstLogin__row {
    display: flex;
    margin-bottom: 35px;
    gap: 35px;
  }

  .firstLogin__item {
    width: 122px;
    height: 110px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.orangeDark};
    font-weight: bold;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > svg {
      margin-bottom: 10px;
      width: 42.9px;
      height: 42.9px;
    }
  }

  .firstLogin__item:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  }
`;

export const AlertMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    > div > p {
      margin-bottom: 20px;
    }
  }
`;
