import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    border: none;
  }

  button {
    background: transparent;
    cursor: pointer;
  }

  html, body, #root {
    height: 100%;
    background: #f2f2f2;
    color: #4F4F4F;
  }

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
    height: 10px;

  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey};
    border-radius: 4px;
    height: 10px;
  }

  .swal-overlay {
    z-index: 9999999;
  }

  .grow0 {
    flex-grow: 0;
  }

  .grow1 {
    flex-grow: 1;
  }

  .grow2 {
    flex-grow: 2;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .overlay-modal {
    position: fixed;
    inset: 0px;
    background-color: rgba(79, 79, 79, 0.75);
  }

  .tooltip {
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 9999999999999999999;
    margin-left: -107px;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .swal2-container {
    z-index: 9999999999999999999;
  }
`;
