import React from "react";

import { INewSocialNetworking } from "src/http/interfaces/INewSocialNetworking";
import { IService } from "src/services/services";

import { Modal, ModalHeader } from "../../../../components";
import PhoneBox from "./PhoneBox";
import { Container } from "./styles";

interface IProps {
  visible: boolean;
  setVisible: () => void;
  primary_color: string;
  secondary_color: string;
  font_color: string;
  services: IService[];
  service: IService | null;
  setService: (service: IService) => void;
  image: string;
  address: string;
  title: string;
  socialMedia: INewSocialNetworking[];
}

const ProfilePicture: React.FC<IProps> = (props) => {
  const {
    visible,
    image,
    setVisible,
    primary_color,
    secondary_color,
    font_color,
    services,
    setService,
    service,
    address,
    title,
    socialMedia,
  } = props;

  return (
    <Container>
      <Modal
        visible={visible}
        closeModal={setVisible}
        content={
          <>
            <ModalHeader title="Visualizar prévia" onGoBack={setVisible} />
            <PhoneBox
              image={image}
              title={title}
              address={address}
              socialMedia={socialMedia}
              setService={setService}
              service={service}
              services={services}
              primary_color={primary_color}
              secondary_color={secondary_color}
              font_color={font_color}
            />
          </>
        }
      />
    </Container>
  );
};

export default ProfilePicture;
