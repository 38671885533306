import { makeObservable, observable } from "mobx";

import api from "src/http/api";
import { IDaysAndTimes } from "src/http/interfaces/IDaysAndTimes";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public daysAndTimes: IDaysAndTimes[] = [];
  public loader: LoadingStore;

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      daysAndTimes: observable,
    });
  }

  public getDaysAndTimes = async (user_id) => {
    try {
      this.loader.start();
      this.daysAndTimes = await api.getDaysAndTimes(user_id);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
