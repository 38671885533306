import { action, makeObservable, observable } from "mobx";

import api, { INewUser } from "src/http/api";
import AuthStore from "src/stores/AuthStore";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";
import { validaCpfCnpj } from "src/resources/utils";

export default class Store extends UIStore {
  private loader: LoadingStore;
  private authStore: AuthStore;
  public acceptTerms = false;
  public acceptMessage = false;
  public acceptPrivacy = false;
  public formController = new FormControllerStore({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    acting: "",
    completePhone: "",
    cpf_cnpj: "",
    reference: "",
    quantityEmployees: "",
  });

  constructor(loader: LoadingStore, authStore: AuthStore) {
    super();
    this.loader = loader;
    this.authStore = authStore;
    makeObservable(this, {
      register: action,
      toggleAcceptTerms: action,
      toggleAcceptMessage: action,
      toggleAcceptPrivacy: action,
      acceptTerms: observable,
      acceptMessage: observable,
      acceptPrivacy: observable,
    });
  }

  public register = async (onSuccess: CallableFunction, plan: number) => {
    try {
      this.loader.start();
      const {
        name,
        email,
        password,
        password_confirmation,
        acting,
        completePhone,
        cpf_cnpj,
        reference,
        quantityEmployees,
      } = this.formController.getFields();

      const phone_prefix = completePhone.replace(/\D/gim, "").substr(0, 2);
      const phone = completePhone.replace(/\D/gim, "").substr(2, 11);

      const userPayload: INewUser = {
        name,
        email,
        password,
        password_confirmation,
        cpf_cnpj,
        phone,
        phone_prefix,
        acting,
        player_ids: "",
        profile: "hall",
        reference,
        quantityEmployees,
        plan,
      };
      if (!this.acceptTerms) {
        this.showError(
          "Você precisa aceitar os Termos & Condições e a Política de Privacidade para continuar o seu cadastro!",
        );
        return;
      }
      if (!validaCpfCnpj(cpf_cnpj)) {
        this.showError(
          "Seu CPF ou CNPJ é inválido, verifique-o e tente novamente.",
        );
        return;
      }
      let cpfCnpjExists = false;
      try {
        const result1 = await api.searchCPFCNPJ(cpf_cnpj);
        if (result1.data.length > 0) {
          cpfCnpjExists = true;
        }
      } catch (error) {
        cpfCnpjExists = false;
      }
      if (cpfCnpjExists) {
        this.showError(
          "Seu CPF ou CNPJ já existe na nossa base de dados.",
        );
        return;
      }
      const createdUser = await api.register(userPayload);
      this.authStore.setAxiosHeadersForAuthenticatedUsers(createdUser);
      onSuccess(createdUser);
      this.authStore.loginRegister(
        this.formController.getField("email").value,
        this.formController.getField("password").value,
      );
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public toggleAcceptTerms = (accepted: boolean) => {
    this.acceptTerms = accepted;
  };

  public toggleAcceptMessage = (accepted: boolean) => {
    this.acceptMessage = accepted;
  };

  public toggleAcceptPrivacy = (accepted: boolean) => {
    this.acceptPrivacy = accepted;
  };
}
