import { observer } from "mobx-react-lite";
import { useLocalStore } from "mobx-react-lite";
import React from "react";

import LoadingStore from "src/stores/LoadingStore";

import AuthStore from "../stores/AuthStore";
import UIStore from "../stores/UIStore";

interface IGlobalStore {
  authStore: AuthStore;
  loadingStore: LoadingStore;
  uiStore: UIStore;
}

const GlobalStoreContext = React.createContext<IGlobalStore | null>(null);

export const GlobalContextProvider = observer((props) => {
  const loadingStore = useLocalStore(() => new LoadingStore());
  const authStore = useLocalStore(() => new AuthStore(loadingStore));
  const uiStore = useLocalStore(() => new UIStore());

  return (
    <GlobalStoreContext.Provider
      value={{
        authStore,
        uiStore,
        loadingStore,
      }}
    >
      {props.children}
    </GlobalStoreContext.Provider>
  );
});

export const useGlobalStore = () => {
  const store = React.useContext(GlobalStoreContext);
  if (!store) {
    throw new Error("Cannot Access Store outside it's context");
  }

  return store;
};
