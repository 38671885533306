import { observer, useLocalStore } from "mobx-react";
import React from "react";
import { FiChevronRight } from "react-icons/fi";

import ScreenContainer from "src/layout/ScreenContainer";

import { useGlobalStore } from "src/context/global";
import theme from "src/theme";
import { Button, Card } from "src/ui/components";

import Preview from "./components/Preview";
import SocialNetworkingModal from "./components/SocialNetworkingModal";
import Store from "./store";
import { CardProfileForm, Container, TableCardProfile } from "./styles";
import { Link } from "react-router-dom";
import { IUser } from "src/http/api";

const CustomizeScreen: React.FC = () => {
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  const saveAll = () => {
    store.editCompany();
  };

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  const linkUrl = `https://www.app.facilitelink.com.br/u/${
    store.company ? store.company.slug : ""
  }`;

  const [socialVisible, setSocialVisible] = React.useState<boolean>(false);

  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    store.getUsersEmployees(user.id);
  }, [user]);

  return (
    <ScreenContainer>
      <Container>
        <Card label="Cadastrar e configurar">
          <CardProfileForm>
            <TableCardProfile color={theme.colors.orangeDark}>
              <tr>
                <th>Profissional</th>
                <th>Status</th>
              </tr>
              <tr>
                <td>adriana</td>
                <td>ativo</td>
                <td>
                  <Link to="/alterarConfiguracoesUsuario">
                    <FiChevronRight color={theme.colors.orangeDark} />
                  </Link>
                </td>
              </tr>
              <tr>
                <td>felipe</td>
                <td>ativo</td>
                <td>
                  <FiChevronRight color={theme.colors.orangeDark} />
                </td>
              </tr>
            </TableCardProfile>
          </CardProfileForm>
        </Card>

        <Link to="/cadastrarFuncionario">
          <Button
            title="Adicionar novo"
            color={theme.colors.orangeDark}
            style={{ marginTop: 50, marginBottom: 20 }}
          />
        </Link>
        <SocialNetworkingModal
          setVisible={() => setSocialVisible(false)}
          visible={socialVisible}
          socialMedia={store.socialsMedia}
          onChange={store.setLinkingCompany}
          onEditSocialNetworking={() => store.editSocialNetworking()}
          socialNetworking={store.socialsMediaCompany}
        />
        <Preview
          image={store.avatarUrl}
          title={store.formController.getField("name").value}
          primary_color={store.formController.getField("primary_color").value}
          address={store.formController.getField("address").value}
          secondary_color={
            store.formController.getField("secondary_color").value
          }
          font_color={store.formController.getField("font_color").value}
          setService={(service) => store.setSelectedService(service)}
          service={store.service}
          visible={visible}
          services={store.services}
          setVisible={() => setVisible(false)}
          socialMedia={store.socialsMedia}
        />
      </Container>
    </ScreenContainer>
  );
};

export default observer(CustomizeScreen);
