import styled, { css } from "styled-components";

interface ThemeProps {
  active?: boolean;
  primary?: string;
  textColor?: string;
}

export const Avatar = styled.img`
  width: 124px;
  height: 124px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
`;

export const Content = styled.div`
  margin-top: 16px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 32px;
`;

export const ServiceList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 12px;
`;

export const ServiceItem = styled.p<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  margin-top: 8px;
  cursor: pointer;
  width: 100%;

  line-height: 150%;
  font-size: 12px;

  ${({ active, primary, textColor }) =>
    active
      ? css`
          background-color: ${primary};
          color: ${textColor};
        `
      : css`
          border: solid 1px ${primary};
          color: ${primary};
        `}
`;
