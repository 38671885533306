import { observer, useLocalStore } from "mobx-react";
import React, { useState } from "react";
import useClipboard from "react-use-clipboard";

import ScreenContainer from "src/layout/ScreenContainer";

import { useGlobalStore } from "src/context/global";
import theme from "src/theme";
import { Button, Card, Input, Modal, ModalHeader } from "src/ui/components";

import ColorPicker from "./components/ColorPicker";
import Preview from "./components/Preview";
import PhoneBox from "./components/Preview/PhoneBox";
import ProfilePicture from "./components/ProfilePicture";
import ShareButton from "./components/ShareButton";
import SocialNetworkingModal from "./components/SocialNetworkingModal";
import SocialNetworkInput from "./components/SocialNetworkInput";
import Store from "./store";
import {
  CardInner,
  InputContainer,
  CardProfileForm,
  CardProfileBottom,
  MobileNone,
  CardSocialAndScheduling,
  DesktopNone,
  Container,
  ContainerButtonCopy,
} from "./styles";
import { IUser } from "src/http/api";
import { BodyModal } from "./components/SocialNetworkingModal/styles";
import { IUsers } from "src/http/interfaces/IUsers";
import placeholder from "src/assets/images/blank.png";

const CustomizeScreen: React.FC = () => {
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));
  const [employee, setEmployee] = useState<IUsers>();

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;
  const loggedInAs = JSON.parse(localStorage.getItem("loggedInAs")!) as string;

  const saveAll = () => {
    store.editCompany();
  };

  const saveEmployee = () => {
    store.editEmployee(Number(loggedInAs) || user.id, employee, selectedImage);
  };

  const getUniqueSocialCompany = (id: number) => {
    const socialFilter = store.socialsMediaCompany.find(
      (social) => social.id === id,
    );
    return socialFilter;
  };

  const linkUrl = `https://app.facilitelink.com.br/u/${
    store.company ? store.company.slug : ""
  }`;

  const [isCopied, setCopied] = useClipboard(linkUrl, {
    successDuration: 2000,
  });

  const [socialVisible, setSocialVisible] = React.useState<boolean>(false);

  const [visible, setVisible] = React.useState<boolean>(false);

  const [changePassword, setChangePassword] = React.useState<boolean>(false);

  const [newPassword, setNewPassword] = React.useState<string>("");

  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  React.useEffect(() => {
    store.getCompany();
    if (loggedInAs || user.profile === "employee") {
      getUser();
    }
  }, []);

  const getUser = async () =>
    setEmployee(await store.getUser(loggedInAs || String(user.id)));

  const handlePasswordChange = async () => {
    await store.changePassword(newPassword);
    setChangePassword(false);
  };

  return (
    <ScreenContainer>
      {(user.profile === "employee" || loggedInAs) && employee ? (
        <Container>
          <Card label="Dados da profissional">
            <CardProfileForm>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {employee.image_url ? (
                  <div>
                    <img
                      alt={employee.name}
                      width={"200px"}
                      height={"200px"}
                      style={{ borderRadius: "10px" }}
                      src={employee.image_url}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      alt="not found"
                      width={"200px"}
                      height={"200px"}
                      style={{ borderRadius: "10px" }}
                      src={placeholder}
                    />
                  </div>
                )}
                <input
                  type="file"
                  name="myImage"
                  onChange={(event) => {
                    if (event.target.files && event.target.files.length > 0) {
                      setSelectedImage(event.target.files[0]);
                      setEmployee({
                        ...employee,
                        image_url: URL.createObjectURL(event.target.files[0]),
                      });
                    }
                  }}
                />
              </div>
              <InputContainer>
                <Input
                  label="Nome da profissional"
                  value={employee.name}
                  onChange={(e) =>
                    setEmployee({ ...employee, name: e.target.value })
                  }
                />
              </InputContainer>
            </CardProfileForm>
          </Card>
          <Button
            title="Salvar"
            onClick={() => saveEmployee()}
            color={theme.colors.orangeDark}
            style={{ marginTop: 50, marginBottom: 20 }}
          />
          <Button
            title="Alterar senha"
            onClick={() => setChangePassword(true)}
            outlined
            color={theme.colors.orangeDark}
            style={{ marginTop: 20, marginBottom: 15 }}
          />
          <DesktopNone>
            <ShareButton
              link={linkUrl}
              showSomething={() =>
                store.showSuccess("Link copiado com sucesso!")
              }
              setCopied={setCopied}
              copied={isCopied}
            />
          </DesktopNone>
          <Modal
            visible={changePassword}
            closeModal={() => setChangePassword(false)}
            content={
              <BodyModal>
                <ModalHeader
                  title="Alterar senha"
                  onGoBack={() => setChangePassword(false)}
                />
                <Input label="Senha atual" type="password" />
                <Input
                  label="Nova senha"
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <Button
                  title="Salvar senha"
                  onClick={handlePasswordChange}
                  outlined
                  color={theme.colors.orangeDark}
                  style={{ marginTop: 20, marginBottom: 15 }}
                />
              </BodyModal>
            }
          />
        </Container>
      ) : (
        <Container>
          <DesktopNone>
            <label htmlFor="">Seu link</label>
            <ContainerButtonCopy>
              <button className="">{linkUrl}</button>
            </ContainerButtonCopy>
            <ShareButton
              link={"Clique aqui para copiar"}
              showSomething={() =>
                store.showSuccess("Link copiado com sucesso!")
              }
              setCopied={setCopied}
              copied={isCopied}
            />
          </DesktopNone>
          {/* <MobileNone>
          <label htmlFor="">Seu link</label>
          <ContainerButtonCopy>
            <button className="">{linkUrl}</button>
          </ContainerButtonCopy>
          <ShareButton
            link={"Clique aqui para copiar"}
            showSomething={() => store.showSuccess("Link copiado com sucesso!")}
            setCopied={setCopied}
            copied={isCopied}
          />
        </MobileNone> */}
          <Card label="Dados da empresa">
            <CardProfileForm>
              <ProfilePicture
                avatar={store.avatarUrl}
                onSetImageUrl={(value, file) => store.setImage(value, file)}
              />
              <InputContainer>
                <Input
                  label="Nome da sua empresa"
                  {...store.formController.getField("name")}
                />
                <Input
                  label="Endereço"
                  {...store.formController.getField("address")}
                />
                <ColorPicker
                  label="Cor primária"
                  {...store.formController.getField("primary_color")}
                />
                <ColorPicker
                  label="Cor secundária"
                  {...store.formController.getField("secondary_color")}
                />
                <ColorPicker
                  label="Cor do texto"
                  {...store.formController.getField("font_color")}
                />
                <DesktopNone>
                  <Button
                    title="Visualizar prévia"
                    outlined
                    onClick={() => store.hasColors(() => setVisible(true))}
                    color={theme.colors.orangeDark}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  />
                </DesktopNone>
              </InputContainer>
            </CardProfileForm>
          </Card>
          <CardProfileBottom>
            <CardSocialAndScheduling>
              <Card label="Adicionar redes sociais">
                <CardInner>
                  {store.socialsMedia.map((social, index) => (
                    <SocialNetworkInput
                      key={social.social_network_id}
                      onChange={(value) =>
                        store.setLinkingForNewCompany(
                          store.socialsMedia[index],
                          index,
                          value,
                          getUniqueSocialCompany(social.social_network_id) &&
                            getUniqueSocialCompany(social.social_network_id)
                              ?.prefix,
                        )
                      }
                      placeholder={
                        getUniqueSocialCompany(social.social_network_id) &&
                        getUniqueSocialCompany(social.social_network_id)?.prefix
                      }
                      onRemove={() => store.onRemoveOneSocialMedia(social.id)}
                      defaultValue={social.link}
                      icon={social.logo_url}
                    />
                  ))}
                  <Button
                    title="Adicionar nova rede social"
                    onClick={() => setSocialVisible(true)}
                    outlined
                    color={theme.colors.orangeDark}
                    style={{ marginTop: 20, marginBottom: 15 }}
                  />
                </CardInner>
              </Card>
            </CardSocialAndScheduling>
            <MobileNone>
              <Card
                style={{
                  marginBottom: 35,
                  marginLeft: "auto",
                  width: "100%",
                }}
                label="Visualização da prévia"
              >
                <CardInner>
                  <PhoneBox
                    image={store.avatarUrl}
                    title={store.formController.getField("name").value}
                    primary_color={
                      store.formController.getField("primary_color").value
                    }
                    address={store.formController.getField("address").value}
                    secondary_color={
                      store.formController.getField("secondary_color").value
                    }
                    font_color={
                      store.formController.getField("font_color").value
                    }
                    setService={(service) => store.setSelectedService(service)}
                    service={store.service}
                    services={store.services}
                    socialMedia={store.socialsMedia}
                  />
                </CardInner>
              </Card>
            </MobileNone>
          </CardProfileBottom>
          {/* <Card label="Agendamento">
          <CardInner>
            <SchedulingCard
              cashierAuto={store.cashierAuto}
              autoScheduling={store.autoScheduling}
              overdueBillsReminder={store.overdueBillsReminder}
              schedulingForHour={store.schedulingForHour}
              onChangeCashierAuto={store.onChangeCashierAuto}
              onChangeAutoScheduling={store.onChangeAutoScheduling}
              onChangeOverdueBillsReminder={store.onChangeOverdueBillsReminder}
              onChangesShedulingForHour={store.onChangeHours}
              onChangeHoursForLess={store.onChangeHoursForLess}
            />
          </CardInner>
                </Card>*/}
          <Button
            title="Salvar"
            onClick={() => saveAll()}
            color={theme.colors.orangeDark}
            style={{ marginTop: 50, marginBottom: 20 }}
          />
          <SocialNetworkingModal
            setVisible={() => setSocialVisible(false)}
            visible={socialVisible}
            socialMedia={store.socialsMedia}
            onChange={store.setLinkingCompany}
            onEditSocialNetworking={() => store.editSocialNetworking()}
            socialNetworking={store.socialsMediaCompany}
          />
          <Preview
            image={store.avatarUrl}
            title={store.formController.getField("name").value}
            primary_color={store.formController.getField("primary_color").value}
            address={store.formController.getField("address").value}
            secondary_color={
              store.formController.getField("secondary_color").value
            }
            font_color={store.formController.getField("font_color").value}
            setService={(service) => store.setSelectedService(service)}
            service={store.service}
            visible={visible}
            services={store.services}
            setVisible={() => setVisible(false)}
            socialMedia={store.socialsMedia}
          />
        </Container>
      )}
    </ScreenContainer>
  );
};

export default observer(CustomizeScreen);
