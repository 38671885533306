import { action, makeObservable, observable } from "mobx";

import SupportService, { IUserProfile } from "src/services/support";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public userProfile: IUserProfile;
  public loader: LoadingStore;
  public supportService: SupportService;

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    this.supportService = new SupportService();
    this.userProfile = {
      created_at: null,
      email: "",
      phone: "",
      phone_prefix: "",
      interval_type: "",
      payable_with: "",
      expiration_date: "",
      recurring_charge: false,
    };

    makeObservable(this, {
      getUserProfile: action,
      userProfile: observable,
    });
  }

  public getUserProfile = async () => {
    try {
      this.loader.start();
      this.userProfile = await this.supportService.getUserProfile();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
