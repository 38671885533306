import styled from "styled-components";

export const Container = styled.button`
  width: 100%;
  height: 45px;
  background: ${({ theme, color }) => color || theme.colors.orangeDark};
  border-radius: 10px;
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  > .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    > .text {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      padding-right: 10px;
    }
  }
`;
