import React from "react";

import { Container } from "./styles";

interface IProps {
  content: string | number;
}
export const DayBadge: React.FC<IProps> = (props) => {
  const { content } = props;
  return <Container>{content}</Container>;
};
