import React from "react";

import formatters from "src/resources/formatters";
import theme from "src/theme";

import { Button } from "../../../../components";
import { Container } from "./styles";

interface PlanProps {
  title: string;
  description: string;
  valueByMonthWithoutDiscount: number;
  valueByMonthWitDiscount: number;
  image: string;
  chargeType: string;
  highlight?: boolean;
  invoice?: string;
  identifier?: boolean;
  onPressSubscribe: () => void;
  onPressUpdate: () => void;
}

const Plan: React.FC<PlanProps> = (props) => {
  const {
    title,
    description,
    valueByMonthWithoutDiscount,
    valueByMonthWitDiscount,
    image,
    chargeType,
    highlight,
    onPressSubscribe,
    onPressUpdate,
    invoice,
    identifier,
  } = props;

  const openInvoice = (link: string) => {
    if (link) {
      window.open(link, "_blank");
    }

    return;
  };

  return (
    <Container highlight={highlight}>
      <h1 className="title">{title}</h1>
      <span className="description">{description}</span>
      <span className="old-price">
        De: {formatters.toCurrency(valueByMonthWithoutDiscount)}
      </span>
      <img src={image} />
      <span className="price-with-discount">
        Por: <b>{formatters.toCurrency(valueByMonthWitDiscount)}</b> /mês
      </span>
      <span className="charge-type">{chargeType}</span>
      {identifier ? (
        <Button
          title="Imprimir minha fatura"
          color={highlight ? theme.colors.orangeDark : theme.colors.purpleDark}
          outlined={!highlight}
          onClick={() => openInvoice(invoice ?? "")}
        />
      ) : (
        <Button
          title="Eu quero esse!"
          color={highlight ? theme.colors.orangeDark : theme.colors.purpleDark}
          outlined={!highlight}
          onClick={onPressSubscribe}
        />
      )}
    </Container>
  );
};

export default Plan;
