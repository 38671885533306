import client from "src/http/client";
import { ISocialNetworking } from "src/http/interfaces/ISocialNetworking";
import { ISocialNetworkingCompany } from "src/http/interfaces/ISocialNetworkingCompany";

export interface IService {
  created_at: string;
  duration: string;
  duration_time: string;
  fixed_price: boolean;
  id: number;
  name: string;
  observation: string;
  price: number;
  updated_at: string;
  user_id: number;
}

export default class ServicesService {
  public async getServices(employee?: string) {
    let url = "storefront/v1/services";
    if (employee) {
      url += "?user_id=" + employee;
    }
    const response = await client.get<IService[]>(url);
    return response.data;
  }

  public async getSocialNetworking(
    companyId: string,
  ): Promise<ISocialNetworking[]> {
    const response = await client.get<ISocialNetworking[]>(
      "/storefront/v1/company_social_networks/link",
      {
        headers: {
          user_id: companyId,
        },
      },
    );
    return response.data;
  }

  public async getSocialNetworkingAll(
    companyId: string,
  ): Promise<ISocialNetworkingCompany[]> {
    const response = await client.get<ISocialNetworkingCompany[]>(
      "/storefront/v1/social_networks",
      {
        headers: {
          user_id: companyId,
        },
      },
    );
    return response.data;
  }

  public async getCompanyServices(companyId: string, userId?: number) {
    let response;
    if (userId) {
      response = await client.get<IService[]>(
        "storefront/v1/services?user_id=" + String(userId),
      );
    } else {
      response = await client.get<IService[]>("storefront/v1/services/link", {
        headers: {
          user_id: companyId,
        },
      });
    }
    return response.data;
  }

  public async getProviderServices(companyId: string) {
    return await client
      .get<IService[]>("storefront/v1/services/link", {
        headers: { user_id: companyId },
      })
      .then((response) => response.data);
  }
}
