import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.greyLight};
  position: relative;
  margin: 20px auto;
  cursor: pointer;
  > img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    border: 0;
    outline: none;
  }
`;

export const TextOpacity = styled.p`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.greyDarker};
`;

export const BodyModal = styled.div`
  padding: 20px 0;
`;
