import { action, makeObservable, observable } from "mobx";

import api from "src/http/api";
import { IDaysAndTimes } from "src/http/interfaces/IDaysAndTimes";
import AuthStore from "src/stores/AuthStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public daysAndTimes: IDaysAndTimes[] = [];
  public loader: LoadingStore;
  public auth: AuthStore;
  public cashierAuto: boolean;
  public autoScheduling: boolean;
  public schedulingForHour = 0;
  public overdueBillsReminder: boolean;

  constructor(loader: LoadingStore, auth: AuthStore) {
    super();
    this.loader = loader;
    this.auth = auth;
    makeObservable(this, {
      cashierAuto: observable,
      autoScheduling: observable,
      schedulingForHour: observable,
      overdueBillsReminder: observable,
      getPreferences: action,
    });
  }

  public async getPreferences(user_id: number) {
    try {
      this.loader.start();
      const result = await api.getUserPreferences(user_id);
      this.cashierAuto = result.cashier_auto;
      this.autoScheduling = result.auto_scheduling;
      this.schedulingForHour = result.scheduling_for_hour;
      this.overdueBillsReminder = result.overdue_bills_reminder;
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getPreferencesIndividual(company_id: number) {
    try {
      this.loader.start();
      const result = await api.getPreferences(company_id);
      this.cashierAuto = result.cashier_auto;
      this.autoScheduling = result.auto_scheduling;
      this.schedulingForHour = result.scheduling_for_hour;
      this.overdueBillsReminder = result.overdue_bills_reminder;
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public updatePreferences = async (preferences, user_id: number) => {
    try {
      this.loader.start();

      await api.updateUserPreferences(user_id, preferences);

      this.showSuccess("Preferencias atualizadas com sucesso");
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public updatePreferencesIndividual = async (preferences, company_id: number) => {
    try {
      this.loader.start();

      await api.updateUserPreferencesIndividual(company_id, preferences);

      this.showSuccess("Preferencias atualizadas com sucesso");
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
