import axios from "axios";

export interface IZipCodeData {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  uf: string;
  localidade: string;
  erro: boolean;
}

export default class ViaCepService {
  public async getZipCodeData(zipCode: string): Promise<IZipCodeData> {
    const response = await axios.get<IZipCodeData>(
      `https://viacep.com.br/ws/${zipCode}/json/`,
    );
    return response.data;
  }
}
