import styled, { css } from "styled-components";

interface IContainerProps {
  primaryColor?: string;
  secondaryColor?: string;
  highlightedTextColor?: string;
  absolute?: boolean;
}

export const Container = styled.footer<IContainerProps>`
  width: 100%;
  height: auto;
  padding: 15px;
  background: ${({ theme, primaryColor }) =>
    primaryColor || theme.colors.purpleDark};
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  color: ${({ theme, highlightedTextColor }) =>
    highlightedTextColor || theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clear: both;
  ${({ absolute }) =>
    absolute
      ? css`
          position: absolute;
          bottom: 0;
        `
      : css`
          position: relative;
        `}
  > hr {
    background: ${({ theme, highlightedTextColor }) =>
      highlightedTextColor || theme.colors.white};
    opacity: 0.3;
    height: 1px;
    width: 100%;
    max-width: 200px;
    margin: 5px 0;
  }
  > p {
    font-size: 11px;
    display: block;
    text-align: center;
  }
  > .social-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 18px;

    > a {
      margin: 0 8px;
      > img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }
  }
`;
