import { makeObservable, observable } from "mobx";

import { ICoupon } from "src/services/coupons";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public loader: LoadingStore;
  public formController = new FormControllerStore({
    name: "",
    date_start: "",
    date_end: "",
    type_coupon: "",
    reuse: "",
    value: "",
  });

  public couponList: ICoupon[] = [];
  public coupon: ICoupon | null = null;

  constructor(loader: LoadingStore) {
    super();
    makeObservable(this, {
      couponList: observable,
      coupon: observable,
    });
    this.loader = loader;
  }
}
