import { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface IContainerProps extends InputHTMLAttributes<HTMLInputElement> {}

export const Container = styled.div<IContainerProps>`
  > label {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    margin-bottom: 8px;
    display: block;
  }
  > .input-container {
    display: flex;
    width: 100%;
    height: 55px;
    margin-bottom: 27px;
    border-radius: 5px;
    flex-direction: row;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: ${({ theme }) => theme.colors.white};
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    position: relative;
    > .color-input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    > .color-preview {
      width: 33px;
      height: 33px;
      left: 44px;
      top: 599px;
      background: ${({ value }) => value};
      border-radius: 2.5px;
      margin-right: 15px;
    }
    > .value {
      color: ${({ theme }) => theme.colors.greyDarker};
      font-size: ${({ theme }) => theme.fontSizes.s16}px;
      display: block;
      flex: 1;
    }
  }
`;
