import styled from "styled-components";

interface LegendProps {
  color: string;
}

interface ColorProps {
  color: string;
}

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .title-filters {
    text-align: center;
    color: ${({ theme }) => theme.colors.purple};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 500px) {
      & {
        display: block !important;
      }
    }
  }
`;

export const Summary = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    background: white;
    width: 300px;
    max-height: 300px;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }

  .output {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .output__outhers {
      span {
        font-size: 12px;
        color: #828282;
      }
    }
  }

  .text-green {
    color: #41ce88;
    font-weight: bold;
  }

  .text-red {
    color: #eb5757;
    font-weight: bold;
  }

  @media (max-width: 800px) {
    & {
      display: block !important;

      > div {
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }
`;

export const ExtractUpdated = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .table {
    .table__header {
      display: flex;
      justify-content: space-between;
      color: #c2c2c2;
      margin-bottom: 15px;
      padding: 0px 10px;
    }

    > .table__section {
      .table__title {
        display: flex;
        align-items: center;
        padding: 0px 10px;
        height: 31px;
        background: #f2f2f2;
      }

      > .table__item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #f2f2f2;

        > .table__value {
          display: flex;
          align-items: center;
          padding: 10px;
          font-size: 16px;

          .table__details {
            color: #c2c2c2;
            font-size: 13px;
          }
        }
      }
    }

    .table__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 35px;
      height: 31px;
      background: #f2f2f2;
      font-weight: bold;
    }
  }

  .text-end {
    text-align: end;
  }

  .btn-insert-manual {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 15px;
      width: 250px;
    }
  }

  .filters__legend {
    display: flex;
    padding: 25px;
    gap: 5px;

    .filters__title {
      font-size: 24px;
      color: #c2c2c2;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .filters__input {
      display: flex;
      gap: 15px;
    }
  }
`;

export const Icon = styled.div<ColorProps>`
  width: 19px;
  height: 30px;
  margin-right: 10px;
  background-color: ${({ color }) => color};
`;

export const Value = styled.div<ColorProps>`
  color: ${({ color }) => color};
  display: flex;
  flex-direction: column;

  .value__paid {
    font-size: 12px;
    color: #41ce88;
  }

  .value__discount {
    color: #41ce88;
    font-size: 12px;
    text-align: right;
    text-decoration: line-through;
  }
`;

export const Legend = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 15px;
  padding: 15px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const LegendSquare = styled.div<LegendProps>`
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 2.5px;
  border-color: ${({ color }) => color};
`;

export const LegendTitle = styled.span`
  font-size: 16px;
  margin-left: 15px;
`;
