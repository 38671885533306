import React from "react";

import deleteIcon from "src/assets/images/delete.png";

import { Container } from "./styles";

interface ColorPickerProps {
  label?: string;
  icon?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  onRemove?: () => void;
}

const SocialNetworkInput: React.FC<ColorPickerProps> = (props) => {
  const { label, onRemove, icon, defaultValue, value, onChange, placeholder } =
    props;
  return (
    <Container>
      {label && <label>{label}</label>}
      <div className="input-container">
        <div className="icon">
          <img src={icon} />
        </div>
        {placeholder && <span className="placeholder">{placeholder}</span>}
        <div className="input-wrapper">
          <input
            onChange={(e) => onChange(e.target.value)}
            type="text"
            value={value}
            defaultValue={defaultValue}
            placeholder="suaempresa"
          />
        </div>
        {onRemove && (
          <div className="iconDelete">
            <img onClick={onRemove} src={deleteIcon} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default SocialNetworkInput;
