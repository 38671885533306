import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
`;

export const ContainerImage = styled.div`
  width: 200px;
  height: 200px;
  @media (max-width: 900px) {
    width: 100px;
    height: 100px;
  }
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.greyLight};
  position: relative;
  margin: 15px auto;
  cursor: pointer;
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 1;
`;

export const Image = styled.img`
  width: 200px;
  height: 200px;
  @media (max-width: 900px) {
    width: 100px;
    height: 100px;
  }
  border-radius: 10px;
  position: absolute;
  top: 0;
`;

export const EditTitle = styled.p`
  color: ${({ theme }) => theme.colors.orangeDark};
  font-size: ${({ theme }) => theme.fontSizes.s8};
  text-align: center;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const EditButton = styled.div`
  border-radius: 10px;
  width: 200px;
  height: 250px;
  @media (max-width: 900px) {
    width: 100px;
    height: 150px;
  }
  position: absolute;
  cursor: pointer;
  z-index: 1;
`;
