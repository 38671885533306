import * as H from "history";
import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

import { useGlobalStore } from "src/context/global";
import { IOrder } from "src/http/api";
import formatters from "src/resources/formatters";
import theme from "src/theme";
import { Button, ModalHeader, ShareButton } from "src/ui/components";

import Store from "./store";
import { Container, Table } from "./styles";

interface IProps {
  order?: IOrder;
  onGoBack: () => void;
  closeModal: () => void;
  history: H.History<unknown>;
  formController: any;
}

const BankSlip: React.FC<IProps> = (props) => {
  const { order, history, closeModal, formController } = props;

  const { loadingStore, authStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore, authStore));

  const onPressConfirm = () => {
    closeModal();
    history.push("/");
  };

  const getPlan = async () => {
    await store.getPlans();
    store.plan = store.plans.find(function (planFilter) {
      return (
        planFilter.id === parseInt(formController.getField("plan_id").value)
      );
    });
  };

  useEffect(() => {
    getPlan();
    ReactPixel.init("339248497849346", undefined, {
      autoConfig: true,
      debug: false,
    });
    ReactPixel.pageView();
    ReactPixel.track("Purchase");
  }, []);

  return (
    <Container>
      <ModalHeader title="Pagar com boleto" onGoBack={closeModal} />
      <p>Obrigado. Seu pedido foi recebido!</p>
      <div style={{ marginBottom: "30px" }}>
        {store.plan && (
          <Table>
            <thead>
              <tr>
                <th scope="col">NÚMERO DO PEDIDO</th>
                <th scope="col">DATA</th>
                <th scope="col">E-MAIL</th>
                <th scope="col">TOTAL</th>
                <th scope="col">MÉTODO DE PAGAMENTO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  data-label="Número do pedido"
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {order?.order_number}
                </td>
                <td data-label="Data">{order?.order_data}</td>
                <td
                  data-label="E-mail"
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {formController.getField("email").value}
                </td>
                <td data-label="Total">
                  R${" "}
                  {formatters.toCurrency(store.plan.value_cents_with_discount)}
                </td>
                <td data-label="Método de Pagamento">
                  {formController.getField("paymentMethod").value.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
      {order && (
        <>
          <div className="code-wrapper">
            <img src={order.barcode} />
            <span>{order.line}</span>
            <div className="share-button-wrapper">
              <ShareButton
                title="Copiar código de barras"
                copiedAlertText="O código de barras foi copiado!"
                textToCopy={order.line}
                color={theme.colors.purpleDark}
              />
            </div>
          </div>
          <Button
            title="Finalizar"
            color={theme.colors.purpleDark}
            onClick={onPressConfirm}
          />
        </>
      )}
    </Container>
  );
};

export default observer(BankSlip);
