import { observer, useLocalStore } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import arrowLeft from "src/assets/svgs/arrowLeft.svg";
import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import api, { IQuantityEmployees } from "src/http/api";
import masks from "src/resources/masks";
import theme from "src/theme";
import { Button, Input, InputCheckBox, Link, Select } from "src/ui/components";

import Store from "./store";
import { Container, Header } from "./styles";

interface RegisterScreenProps {
  closeModal: () => void;
  plan: number;
}

const RegisterScreen: React.FC<RegisterScreenProps> = (props) => {
  const { closeModal, plan } = props;
  const [currentForm, setCurrentForm] = useState("register");
  const { showModal } = useModal();
  const history = useHistory();
  const [quantityEmployees, setQuantityEmployees] = useState<
    IQuantityEmployees[]
  >([]);

  const { loadingStore, authStore } = useGlobalStore();

  const store = useLocalStore(() => new Store(loadingStore, authStore));

  const legalLinks = {
    terms: "https://app.facilitelink.com.br/terms/terms.html",
    privacy: "https://app.facilitelink.com.br/terms/privacy.html",
  };

  useEffect(() => {
    const reference = new URLSearchParams(location.search).get("refer");
    getQuantityEmployees();
    if (reference) {
      store.formController.setField("reference", reference);
    }
  }, []);

  const getQuantityEmployees = async () => {
    const response = await api.getQuantityEmployees();
    setQuantityEmployees(response.data);
  };

  return (
    <Container>
      <Header>
        <button
          className="go-back"
          onClick={
            currentForm === "register"
              ? closeModal
              : () => setCurrentForm("register")
          }
        >
          <img src={arrowLeft} />
        </button>
        <h1>
          {currentForm === "register"
            ? "Preencha seus dados"
            : "Plano e pagamento"}
        </h1>
      </Header>
      <div className="register-form">
        <Input
          label="Nome da sua empresa"
          placeholder="Ex: Facilite Link"
          {...store.formController.getField("name")}
        />
        <Input
          label="E-mail"
          {...store.formController.getField("email")}
          placeholder="Ex: joseroberto@gmail.com"
        />
        <Input
          label="Celular"
          {...store.formController.getField("completePhone")}
          onChange={(e) =>
            store.formController
              .getField("completePhone")
              .onChange(e, masks.phone)
          }
          maxLength={15}
          placeholder="(99) 99999-9999"
        />
        <Input
          label="CPF/CNPJ"
          {...store.formController.getField("cpf_cnpj")}
          onChange={(e) =>
            store.formController
              .getField("cpf_cnpj")
              .onChange(e, masks.CPFAndCNPJ)
          }
          maxLength={18}
          placeholder="999.999.999-99"
        />
        <Input
          label="Segmento"
          {...store.formController.getField("acting")}
          placeholder="Ex: salão de beleza, barbearia, etc..."
        />
        <Select
          label="Mude sua faixa de profissionais aqui :)"
          options={quantityEmployees.map((quantityEmployee) => ({
            value: quantityEmployee.id,
            name: quantityEmployee.description,
          }))}
          {...store.formController.getField("quantityEmployees")}
        />
        <Input
          label="Senha"
          {...store.formController.getField("password")}
          type="password"
          placeholder="*********"
        />
        <Input
          label="Confirme sua senha"
          {...store.formController.getField("password_confirmation")}
          type="password"
          placeholder="*********"
        />
        <InputCheckBox
          label={
            <>
              Concordo com os{" "}
              <b>
                <Link
                  label="Termos & Condições"
                  url="https://facilitelink.com.br/termos-e-condicoes/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </b>{" "}
              e a{" "}
              <b>
                <Link
                  label="Politica de Privacidade"
                  url="https://facilitelink.com.br/politicas-de-privacidade/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </b>
            </>
          }
          onChange={(event) => {
            store.toggleAcceptTerms(event.target.checked);
          }}
          checked={store.acceptTerms}
          style={{ margin: "30px 0" }}
        />
        <Button
          title="Pronto!"
          color={theme.colors.purpleDark}
          onClick={() => store.register(closeModal, plan)}
        />
      </div>
    </Container>
  );
};

export default observer(RegisterScreen);
