import { action, makeObservable, observable } from "mobx";

// import api from "src/http/api";
import AuthStore from "src/stores/AuthStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";
import FormControllerStore from "src/stores/FormControllerStore";

export default class Store extends UIStore {
  public employee;
  public loader: LoadingStore;
  public employeeProfileImageUrl = "";
  public employeeProfileFile: File | null = null;
  public formController = new FormControllerStore({
    name: "",
    employee_name: "",
    address: "",
    primary_color: "",
    secondary_color: "",
    font_color: "",
  });

  constructor(loader: LoadingStore, authStore: AuthStore) {
    super();
    this.loader = loader;

    makeObservable(this, {
      employee: observable,
      getEmployee: action,
      employeeProfileImageUrl: observable,
      setEmployeeProfileImage: action,
      employeeProfileFile: observable,
    });
  }

  public getEmployee = (id) => {
    try {
      this.loader.start();
      //  this.employee = await api.getEmployee(id);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public setEmployeeProfileImage = (value: string, file: File) => {
    this.employeeProfileImageUrl = value;
    this.employeeProfileFile = file;
  };

  public createOrUpdateEmployee = (onSuccess: () => void, employee) => {
    try {
      this.loader.start();
      //  await api.createOrUpdateEmployee(employee);

      this.showSuccess("Professional cadastrado com sucesso!");
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
