import React from "react";
import { IoMdCopy } from "react-icons/io";

import { ButtonCopy, ContainerButtons } from "./styles";
interface IProps {
  link: string;
  copied: boolean;
  setCopied: () => void;
  showSomething: (message: string) => void;
}

const ShareButton: React.FC<IProps> = (props) => {
  const { link, showSomething, copied, setCopied } = props;
  return (
    <ContainerButtons onClick={setCopied}>
      <ButtonCopy>
        <div className="wrapper">
          {copied && showSomething("Link copiado com sucesso")}
          <span className="text">{link}</span>
          <IoMdCopy size={30} />
        </div>
      </ButtonCopy>
    </ContainerButtons>
  );
};

export default ShareButton;
