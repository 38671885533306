import React from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";

import placeholder from "src/assets/images/blank.png";

import {
  ContainerImage,
  EditButton,
  Image,
  EditTitle,
  Container,
} from "./styles";

interface IProps {
  onSetImageUrl: (value: string, file: File) => void;
  avatar: string;
}

const ProfilePicture: React.FC<IProps> = (props) => {
  const { onSetImageUrl, avatar } = props;
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList: ImageListType) => {
    setImages(imageList as never[]);
  };

  return (
    <Container>
      <ContainerImage>
        <ImageUploading
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
        >
          {({ imageList, onImageUpload, isDragging, dragProps }) => (
            <div className="upload__image-wrapper">
              <EditButton
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              />
              &nbsp;
              {imageList.map((image, index) => {
                if (image.dataURL && image.file) {
                  onSetImageUrl(image.dataURL, image.file);
                }
                return (
                  <div key={index} className="image-item">
                    <Image src={image.dataURL || placeholder} alt="profile" />
                  </div>
                );
              })}
            </div>
          )}
        </ImageUploading>
        <div className="image-item">
          <Image src={avatar || placeholder} alt="profile" />
        </div>
      </ContainerImage>
      <EditTitle>{avatar ? "Editar imagem" : "Adicionar imagem"}</EditTitle>
    </Container>
  );
};

export default ProfilePicture;
