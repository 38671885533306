import * as H from "history";
import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";

import { useGlobalStore } from "src/context/global";
import { IOrder } from "src/http/api";
import theme from "src/theme";
import { Button, ModalHeader, ShareButton } from "src/ui/components";

import Store from "../../store";
import { Container } from "./styles";

interface IProps {
  order?: IOrder;
  onGoBack: () => void;
  closeModal: () => void;
  history: H.History<unknown>;
}

const BankSlip: React.FC<IProps> = (props) => {
  const { order, onGoBack, history, closeModal } = props;

  const { loadingStore, authStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore, authStore));

  const onPressConfirm = () => {
    closeModal();
    history.push("/");
  };

  const clearReleasePayment = () => {
    clearInterval(releasePayment);
  };

  let releasePayment;

  useEffect(() => {
    releasePayment = setInterval(() => {
      // console.log(order?.invoice);
      store.releaseAfterPayment(order).then((res) => {
        // console.log(res);
        if (res.invoice_status === "paid") {
          clearReleasePayment();
          onPressConfirm();
        }
      });
    }, 10000);
  }, []);

  return (
    <Container>
      <ModalHeader title="Pagar com boleto" onGoBack={onGoBack} />
      {order && (
        <>
          <div className="code-wrapper">
            <img src={order.barcode} />
            <span>{order.line}</span>
            <div className="share-button-wrapper">
              <ShareButton
                title="Copiar código de barras"
                copiedAlertText="O código de barras foi copiado!"
                textToCopy={order.line}
                color={theme.colors.purpleDark}
              />
            </div>
          </div>
          <Button
            title="Finalizar"
            color={theme.colors.purpleDark}
            onClick={onPressConfirm}
          />
        </>
      )}
    </Container>
  );
};

export default observer(BankSlip);
