import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const DesktopNone = styled.div`
  @media (max-width: ${({ theme }) => theme.dimentions.tablet}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > label {
      align-self: flex-start;
      padding-bottom: 5px;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > label {
    display: flex;
    align-self: flex-start;
    margin-bottom: 5px;
  }
`;

export const MobileNone = styled.div`
  @media (max-width: ${({ theme }) => theme.dimentions.tablet}px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > label {
    display: flex;
    align-self: flex-start;
    margin-bottom: 5px;
  }
`;

export const ContainerButtonCopy = styled.div`
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 5px;
  padding: 3px;

  button {
    width: 98%;
    height: 37px;
    border-radius: 5px;
    overflow: hidden;
    max-height: 25px;
    text-overflow: ellipsis;
    position: relative;
    max-width: 90%;
    color: #808080;
  }
`;

export const CardInner = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
`;

export const CardSocialAndScheduling = styled.div`
  width: 100%;
`;

export const CardProfileForm = styled.div`
  @media (max-width: ${({ theme }) => theme.dimentions.tablet}px) {
    display: block;
  }
  padding: 16px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CardProfileBottom = styled.div`
  @media (max-width: ${({ theme }) => theme.dimentions.tablet}px) {
    display: block;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
`;

export const InputContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.dimentions.tablet}px) {
    margin-left: 0px;
    width: 100%;
  }
  width: 70%;
  margin-left: 25px;
`;
