import styled, { css } from "styled-components";

import { ModalPosition, ModalLargeSize } from ".";

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 45px;
  > button.close {
    border: none;
    background: transparent;
    margin-right: 26px;
    color: ${({ theme }) => theme.colors.purpleDark};
  }
`;

interface ContainerProps {
  position?: ModalPosition;
  size?: ModalLargeSize;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media (min-width: 992px) {
    ${({ position }) =>
      position !== "center" &&
      css`
        display: block;
        padding: 0;
      `}
  }
  > .modal-out {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
  > .modal {
    height: auto;
    max-height: 90vh;
    ${({ size }) =>
      size === "large" &&
      css`
        width: 100%;
      `}

    ${({ size }) =>
      size === "middle" &&
      css`
        width: 70vw;
      `}

    ${({ size }) =>
      size === "small" &&
      css`
        width: 50vw;
      `}
    @media (max-width: 768px) {
      width: 100%;
    }
    background: ${({ theme }) => theme.colors.white};
    border-radius: 15px 15px 0px 0px;
    padding: 15px;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
      ${({ position }) =>
        position !== "center" &&
        css`
          max-height: 100vh;
          height: 100%;
          width: 50vw;
          border-radius: 0;
        `}

      ${({ position }) =>
        position === "right" &&
        css`
          float: right;
        `}

        ${({ position }) =>
        position === "left" &&
        css`
          float: left;
        `}
    }
  }
`;
