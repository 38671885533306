import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > h1 {
    font-size: ${({ theme }) => theme.fontSizes.s24}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: center;
  }
  > p {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme }) => theme.colors.greyDarker};
  }
`;
