import * as H from "history";
import { useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import Swal from "sweetalert2";

import { useGlobalStore } from "src/context/global";
import { IOrder } from "src/http/api";
import formatters from "src/resources/formatters";
import theme from "src/theme";
import { ModalHeader, ShareButton } from "src/ui/components";

import Store from "./store";
import { Container, Table } from "./styles";

interface IProps {
  onGoBack: () => void;
  order?: IOrder;
  closeModal: () => void;
  history: H.History<unknown>;
  formController: any;
}

const Pix: React.FC<IProps> = (props) => {
  const { order, closeModal, history, formController } = props;

  const { loadingStore, authStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore, authStore));

  const onPressConfirm = () => {
    closeModal();
    history.push("/");
  };

  const clearReleasePayment = () => {
    clearInterval(releasePayment);
  };

  const getPlan = async () => {
    await store.getPlans();
    store.plan = store.plans.find(function (planFilter) {
      return (
        planFilter.id === parseInt(formController.getField("plan_id").value)
      );
    });
  };

  let releasePayment;
  let timer;

  useEffect(() => {
    getPlan();

    ReactPixel.init("339248497849346", undefined, {
      autoConfig: true,
      debug: false,
    });
    ReactPixel.pageView();
    ReactPixel.track("Purchase");

    releasePayment = setInterval(() => {
      store.releaseAfterPayment(order?.invoice).then((res) => {
        if (res.invoice_status === "paid") {
          clearReleasePayment();
          Swal.fire({
            icon: "success",
            title: "Pagamento Confirmado",
            html: "Redirecionamento automatico em <span></span>s",
            confirmButtonText: "Acessar o app",
            confirmButtonColor: "#218838",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            timer: 60000,
            didOpen: () => {
              timer = setInterval(() => {
                const seg = Swal.getTimerLeft();
                const htmlContent =
                  Swal.getHtmlContainer()?.querySelector("span");
                if (htmlContent && seg) {
                  htmlContent.textContent = (seg / 1000).toFixed(0);
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timer);
            },
          }).then(() => {
            closeModal();
            authStore.loginRegister(
              formController.getField("email").value,
              formController.getField("password").value,
            );
          });
        }
      });
    }, 30000);

    // clearReleasePayment();
  }, []);

  return (
    <Container>
      <ModalHeader title="Pagar com PIX" onGoBack={closeModal} noBack />
      <p>Obrigado. Seu pedido foi recebido!</p>
      <div style={{ marginBottom: "30px" }}>
        {store.plan && (
          <Table>
            <thead>
              <tr>
                <th scope="col">NÚMERO DO PEDIDO</th>
                <th scope="col">DATA</th>
                <th scope="col">E-MAIL</th>
                <th scope="col">TOTAL</th>
                <th scope="col">MÉTODO DE PAGAMENTO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  data-label="Número do pedido"
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {order?.order_number}
                </td>
                <td data-label="Data">{order?.order_data}</td>
                <td
                  data-label="E-mail"
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {formController.getField("email").value}
                </td>
                <td data-label="Total">
                  R${" "}
                  {formatters.toCurrency(store.plan.value_cents_with_discount)}
                </td>
                <td data-label="Método de Pagamento">
                  {formController.getField("paymentMethod").value.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
      {order && (
        <>
          <div className="code-wrapper">
            <img src={order.qrcode} />
            <div className="share-button-wrapper">
              <ShareButton
                title="Copiar código do QR Code"
                copiedAlertText="O código do QR Code foi copiado!"
                textToCopy={order.qrcode_text}
                color={theme.colors.purpleDark}
              />
            </div>
          </div>
          {/* <Button
            title="Finalizar"
            color={theme.colors.purpleDark}
            onClick={onPressConfirm}
          /> */}
        </>
      )}
    </Container>
  );
};

export default Pix;
