import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

import { Input } from "../Input";

interface InputMoneyProps extends NumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
}

export const InputMoney = (props: InputMoneyProps) => {
  const { onChange, label, value, disabled, required } = props;
  return (
    <NumberFormat
      onChange={onChange}
      fixedDecimalScale
      decimalScale={2}
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      placeholder="R$ 0,00"
      customInput={Input}
      label={label}
      value={value}
      disabled={disabled}
      required={required}
    />
  );
};
