import React from "react";

import { INewSocialNetworking } from "src/http/interfaces/INewSocialNetworking";
import { ISocialNetworkingCompany } from "src/http/interfaces/ISocialNetworkingCompany";
import theme from "src/theme";

import { Button, Modal, ModalHeader, Empty } from "../../../../components";
import SocialNetworkInput from "../SocialNetworkInput";
import { Container, BodyModal } from "./styles";

interface IProps {
  visible: boolean;
  setVisible: () => void;
  onChange: (
    socialMedia: INewSocialNetworking,
    placeholder: string,
    index: number,
  ) => void;
  socialNetworking: ISocialNetworkingCompany[];
  socialMedia: INewSocialNetworking[];
  onEditSocialNetworking: () => void;
}

const SocialNetworkingModal: React.FC<IProps> = (props) => {
  const {
    visible,
    setVisible,
    socialMedia,
    onChange,
    onEditSocialNetworking,
    socialNetworking,
  } = props;

  const filterValuesForNewCompany = () => {
    for (const value of socialNetworking) {
      if (value.link && value.link !== "") {
        socialMedia.push({
          id: value.id + socialNetworking.length,
          logo_url: value.logo_url,
          social_network: value.name,
          link: value.link,
          social_network_id: value.id,
        });
      }
      value.link = "";
    }
    setVisible();
  };

  const onSave = () => {
    filterValuesForNewCompany();
    onEditSocialNetworking();
  };

  return (
    <Container>
      <Modal
        visible={visible}
        closeModal={setVisible}
        content={
          <BodyModal>
            <ModalHeader title="Adicionar rede social" onGoBack={setVisible} />
            {socialNetworking.length > 0 ? (
              <>
                {socialNetworking.map((social, index) => (
                  <SocialNetworkInput
                    label={social.name}
                    key={social.id}
                    icon={social.logo_url}
                    placeholder={social.prefix}
                    value={social.link}
                    onChange={(value) =>
                      onChange(
                        {
                          link: value,
                          social_network_id: social.id,
                        },
                        social.prefix,
                        index,
                      )
                    }
                  />
                ))}
                <Button
                  title="Adicionar"
                  onClick={() => onSave()}
                  color={theme.colors.orangeDark}
                />
              </>
            ) : (
              <Empty
                title="Sem novas redes sociais"
                description="Você já adicionou todas as redes sociais possíveis para sua conta!"
              />
            )}
          </BodyModal>
        }
      />
    </Container>
  );
};

export default SocialNetworkingModal;
