import { action, makeObservable, observable } from "mobx";

import { IUpdateSubscription } from "src/http/api";
import api from "src/http/api";
// import masks from "src/resources/masks";

import AuthStore from "src/stores/AuthStore";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private loader: LoadingStore;
  private authStore: AuthStore;
  public currentScreen: "initial" | "credit_card" | "pix" | "bank_slip" =
    "initial";

  public formController = new FormControllerStore({
    plan: "",
    paymentMethod: "",
    subscription: "",
    name: "",
    cardNumber: "",
    cardExpirationMonth: "",
    cardCVV: "",
  });

  public subscription;
  public updatePayment;

  constructor(loader: LoadingStore, authStore: AuthStore) {
    super();
    this.loader = loader;
    this.authStore = authStore;

    makeObservable(this, {
      subscription: observable,
      currentScreen: observable,
      updatePayment: observable,
      // getSubscription: action,
      setCurrentScreen: action,
      updateSubscriptionPayment: action,
      releaseAfterPayment: action,
    });
  }

  public setCurrentScreen = (currentScreen: typeof this.currentScreen) => {
    this.currentScreen = currentScreen;
  };

  public updateSubscriptionPayment = async (onSuccess?: () => void) => {
    try {
      this.loader.start();
      const {
        plan,
        paymentMethod,
        subscription,
        name,
        cardNumber,
        cardExpirationMonth,
        cardCVV,
      } = this.formController.getFields();

      const ownerName = name.split(" ");

      const subscriptionParams: IUpdateSubscription = {
        plan_id: plan,
        subscription_id: subscription,
        payment_method: paymentMethod,
        card_params:
          paymentMethod === "credit_card"
            ? {
                first_name: ownerName[0],
                last_name: ownerName[ownerName.length - 1],
                number: cardNumber.trim(),
                verification_value: cardCVV.trim(),
                month: cardExpirationMonth.split("/")[0],
                year: cardExpirationMonth.split("/")[1],
                installments: 0,
              }
            : undefined,
      };

      this.updatePayment = await api.updateSubscription(subscriptionParams);

      if (paymentMethod === "credit_card" && onSuccess) {
        onSuccess();
        return;
      }
      if (paymentMethod === "pix") {
        this.setCurrentScreen("pix");
        return;
      }
      if (paymentMethod === "bank_slip") {
        this.setCurrentScreen("bank_slip");
        return;
      }
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public releaseAfterPayment = (invoice) =>
    api.checkPayment(invoice.data.response.id);
}
