import { observer } from "mobx-react";
import React from "react";

import { useGlobalStore } from "src/context/global";

import { Container } from "./styles";

export const Loading: React.FC = observer(() => {
  const { loadingStore } = useGlobalStore();
  return (
    <>
      {loadingStore.isLoading && (
        <Container>
          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        </Container>
      )}
    </>
  );
});
