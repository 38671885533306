import { observer, useLocalStore } from "mobx-react";
import React from "react";
import arrowLeft from "src/assets/svgs/arrowLeft.svg";
import { useGlobalStore } from "src/context/global";
import { IUser } from "src/http/api";
import theme from "src/theme";
import { Button, Input } from "src/ui/components";
import swal from "sweetalert";
import ProfilePicture from "./components/ProfilePicture";

import Store from "./store";
import { Container, Header } from "./styles";

interface IUsersScreen {
  closeModal: () => void;
  id?: number;
  reloadHomeData: () => void;
  onFailure: () => void;
}

const EmployeeProfileScreen: React.FC<IUsersScreen> = (props) => {
  const { id, closeModal, reloadHomeData, onFailure } = props;
  const { loadingStore, authStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore, authStore));

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  const onSuccess = () => {
    reloadHomeData();
    closeModal();
  };

  const onFail = () => {
    onFailure();
    closeModal();
  };

  const validateCreation = () => {
    const { name, email, password } = store.formController.getFields();

    if (name === "" || email === "" || password === "") {
      swal("Ops!", "Por favor, preencha todos os campos obrigatórios", "error");
      return;
    }

    return store.createOrUpdateEmployee(onSuccess, String(user.id), onFail);
  };

  return (
    <Container>
      <Header>
        <button className="go-back" onClick={() => closeModal()}>
          <img src={arrowLeft} />
        </button>
        <div>
          <h1>Cadastrar profissional</h1>
        </div>
      </Header>

      <ProfilePicture
        avatar={store?.employeeProfileImageUrl}
        onSetImageUrl={(value, file) =>
          store.setEmployeeProfileImage(value, file)
        }
      />
      <>
        <Input
          label="Nome do Profissional"
          required
          {...store.formController.getField("name")}
        />
        <Input
          label="Email"
          type="email"
          required
          {...store.formController.getField("email")}
        />
        <Input
          label="Senha"
          required
          {...store.formController.getField("password")}
        />
      </>
      <Button
        title="Cadastrar"
        outlined
        color={theme.colors.orangeDark}
        style={{ marginTop: 20, marginBottom: 15 }}
        onClick={() => validateCreation()}
      />
    </Container>
  );
};

export default observer(EmployeeProfileScreen);
