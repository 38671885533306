/* eslint-disable @typescript-eslint/ban-ts-comment */
import { action, makeObservable, observable } from "mobx";
import moment from "moment";

import { IDaysAndTimes } from "src/http/interfaces/IDaysAndTimes";
import AppointmentsService, {
  AvailableHour,
  NewAppointment,
  BlockedDays,
} from "src/services/appointments";
import { ICompany } from "src/services/companies";
import CompaniesService from "src/services/companies";
import ServicesService, { IService } from "src/services/services";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";
import api from "src/http/api";
import { IEmployees } from "src/http/interfaces/IEmployees";
import { Appointment } from "src/types/appointment.interface";

class ClientScreenStore extends UIStore {
  public appointment?: Appointment;

  public company?: ICompany = undefined;
  public services?: IService[] = undefined;
  public employees: IEmployees[] = [];
  public workingDays: IDaysAndTimes[] = [];
  public whatsApp = "";

  public appointmentsOfMonth: BlockedDays | null = null;
  public availableHours: AvailableHour[] = [];

  public companiesService: CompaniesService;
  private servicesService: ServicesService;
  private appointmentsService: AppointmentsService;

  public loader: LoadingStore;

  public formController = new FormControllerStore({
    name: "",
    cpf: "",
    phone: "",
    payment_method: "",
  });

  constructor() {
    super();

    this.companiesService = new CompaniesService();
    this.servicesService = new ServicesService();
    this.appointmentsService = new AppointmentsService();

    makeObservable(this, {
      appointment: observable,

      company: observable,
      employees: observable,
      services: observable,
      workingDays: observable,
      whatsApp: observable,

      appointmentsOfMonth: observable,
      availableHours: observable,

      setAppointment: action,
      setAppointmentsOfMonth: action,
      setCompany: action,
      setEmployees: action,
      setServices: action,
      setWorkingDays: action,

      getEmployeeData: action,
      getSocialNetworks: action,
      createAppointment: action,
    });
  }

  public setAppointment(appointment: Appointment) {
    this.appointment = appointment;
  }

  public setCompany(company: ICompany) {
    this.company = company;
  }

  public setEmployees(employees: IEmployees[]) {
    this.employees = employees;
  }

  public setServices(services: IService[]) {
    this.services = services;
  }

  public setWorkingDays(workingDays: IDaysAndTimes[]) {
    this.workingDays = workingDays;
  }

  public setAppointmentsOfMonth(appointmentsOfMonth: BlockedDays) {
    this.appointmentsOfMonth = appointmentsOfMonth;
  }

  public async getEmployeeData(employeeId?: number): Promise<void> {
    if (!this.company) return;

    const services = await this.servicesService.getCompanyServices(
      this.company.user_id,
      employeeId,
    );

    const workDays = await api.getDaysAndTimes(String(employeeId));

    this.employees = this.employees.map((employee) => {
      if (employee.id === employeeId) {
        employee.services = services;
        employee.workingDays = workDays;
      }

      return employee;
    });

    this.setServices(services);
    this.setWorkingDays(workDays);
  }

  public async getMonthAppointments(companyId: string, date: Date) {
    const startOfMonth = moment(date).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(date).endOf("month").format("YYYY-MM-DD");

    const data = await this.appointmentsService.getSchedulesOfMonthByLink(
      companyId,
      startOfMonth,
      endOfMonth,
    );

    this.setAppointmentsOfMonth(data);
  }

  public async getSocialNetworks(user_id: string) {
    if (user_id) {
      const response = await this.servicesService.getSocialNetworking(user_id);
      const whats = response.find((item) => item.social_network_id === 7);
      if (whats) this.whatsApp = whats.link;
    }
  }

  public async getAvailableHours(
    selectedDay: Date,
    servicesId?: number[],
    userId?: string,
  ) {
    if (userId) {
      const availableHours = await this.appointmentsService.getScheduleByLink(
        userId,
        {
          service_id: servicesId,
          start_time: moment(selectedDay).format("YYYY-MM-DD"),
        },
      );

      this.availableHours = [...availableHours];
    }
  }

  public async createAppointment() {
    if (!this.company || !this.appointment) return;

    const { services, employee, date, hour } = this.appointment;
    const { name, phone, payment_method, cpf } =
      this.formController.getFields();

    const start_time = `${moment(date).format("YYYY-MM-DD")} ${hour}`;
    const servicesIds = services?.map((service) => service.id);

    const newAppointment: NewAppointment = {
      start_time,
      service_id: servicesIds,
      payment_method: parseInt(payment_method),
      customer: { name, phone, cpf },
      user_id: employee ? String(employee?.id) : undefined,
    };

    await this.appointmentsService.createAppointmentByLink(
      newAppointment,
      this.company.user_id,
    );
  }
}

const clientScreenStore = new ClientScreenStore();
export const useClientScreenStore = () => clientScreenStore;
