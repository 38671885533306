import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import masks from "src/resources/masks";
import { validaCpfCnpj } from "src/resources/utils";
import theme from "src/theme";
import api from "src/http/api";
import {
  Button,
  Input,
  InputCheckBox,
  Link,
  ModalHeader,
  Select,
} from "src/ui/components";

import Plan from "./components/Plan";
import Store from "./store";
import { Container } from "./styles";

interface RegisterBuyPlanScreenProps {
  closeModal: () => void;
}

const RegisterBuyPlanScreen: React.FC<RegisterBuyPlanScreenProps> = (props) => {
  const { closeModal } = props;
  const { loadingStore, authStore } = useGlobalStore();

  const history = useHistory();

  const store = useLocalStore(() => new Store(loadingStore, authStore));
  const { showModal } = useModal();

  const BuyPlan = async () => {
    if (store.formController.getField("name").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo nome é obrigatório",
      });
      return;
    }

    if (store.formController.getField("email").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo e-mail é obrigatório",
      });
      return;
    }

    if (store.formController.getField("completePhone").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo telefone é obrigatório",
      });
      return;
    }

    if (store.formController.getField("cpf_cnpj").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo CPF/CNPJ é obrigatório",
      });
      return;
    }

    if (store.formController.getField("acting").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo segmento é obrigatório",
      });
      return;
    }

    if (store.formController.getField("zip_code").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo CEP é obrigatório",
      });
      return;
    }

    if (store.formController.getField("street").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo rua é obrigatório",
      });
      return;
    }

    if (store.formController.getField("number").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo número é obrigatório",
      });
      return;
    }

    if (store.formController.getField("neighborhood").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo bairro é obrigatório",
      });
      return;
    }

    if (store.formController.getField("city_id").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo cidade é obrigatório",
      });
      return;
    }

    if (store.formController.getField("state_id").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo estado é obrigatório",
      });
      return;
    }

    if (store.formController.getField("password").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo senha é obrigatório",
      });
      return;
    }

    if (
      store.formController.getField("password").value !==
      store.formController.getField("password_confirmation").value
    ) {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "O campo senha é diferente da confirmação",
      });
      return;
    }

    if (!store.acceptTerms) {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "Você precisa aceitar os Termos & Condições e a Política de Privacidade para continuar o seu cadastro!",
      });
      return;
    }

    if (!validaCpfCnpj(store.formController.getField("cpf_cnpj").value)) {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "Seu CPF ou CNPJ é inválido, verifique-o e tente novamente.",
      });
      return;
    }

    let cpfCnpjExists = false;
    try {
      const result1 = await api.searchCPFCNPJ(store.formController.getField("cpf_cnpj").value);
      if (result1.data.length > 0) {
        cpfCnpjExists = true;
      }
    } catch (error) {
      cpfCnpjExists = false;
    }
    if (cpfCnpjExists) {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "Seu CPF ou CNPJ já existe na nossa base de dados.",
      });
      return;
    }

    showModal({
      content: (
        <Plan
          closeModal={closeModal}
          history={history}
          formController={store.formController}
          store={store}
        />
      ),
    });
  };

  useEffect(() => {
    store.getStates();
    store.getPlans();
    if (store.formController.getField("state_id").value) {
      store.getCitiesByState(
        parseInt(store.formController.getField("state_id").value),
      );
    }

    store.selectedPlan = new URLSearchParams(location.search).get("refer");
    store.referKey = new URLSearchParams(location.search).get("refer_key");
  }, [store.formController.getField("state_id").value]);

  return (
    <Container>
      <ModalHeader onGoBack={closeModal} title="Preencha seus dados pessoais" />
      <div className="register-form">
        <Input
          label="Nome da sua empresa"
          placeholder="Ex: Facilite Link"
          required
          {...store.formController.getField("name")}
        />
        <Input
          label="E-mail"
          required
          {...store.formController.getField("email")}
          placeholder="Ex: joseroberto@gmail.com"
        />
        <Input
          label="Celular"
          required
          {...store.formController.getField("completePhone")}
          onChange={(e) =>
            store.formController
              .getField("completePhone")
              .onChange(e, masks.phone)
          }
          maxLength={15}
          placeholder="(99) 99999-9999"
        />
        <Input
          label="CPF/CNPJ"
          required
          {...store.formController.getField("cpf_cnpj")}
          onChange={(e) =>
            store.formController
              .getField("cpf_cnpj")
              .onChange(e, masks.CPFAndCNPJ)
          }
          maxLength={18}
          placeholder="999.999.999-99"
        />
        <Input
          label="Segmento"
          required
          {...store.formController.getField("acting")}
          placeholder="Ex: salão de beleza, barbearia, etc..."
        />
        <Input
          label="CEP"
          required
          maxLength={10}
          {...store.formController.getField("zip_code")}
          onChange={(e) => store.onChangeZipCode(e)}
        />

        <Input
          label="Rua"
          required
          {...store.formController.getField("street")}
        />
        <Input
          label="Número"
          required
          {...store.formController.getField("number")}
        />
        <Input
          label="Bairro"
          required
          {...store.formController.getField("neighborhood")}
        />
        <Input
          label="Complemento"
          {...store.formController.getField("complement")}
        />

        <Select
          label="Cidade"
          required
          options={store.cities.map((city) => ({
            name: city.name,
            value: city.id,
          }))}
          {...store.formController.getField("city_id")}
        />
        <Select
          label="Estado"
          required
          options={store.states.map((state) => ({
            name: state.name,
            value: state.id,
          }))}
          {...store.formController.getField("state_id")}
        />

        <Input
          label="Senha"
          required
          {...store.formController.getField("password")}
          type="password"
          placeholder="*********"
        />
        <Input
          label="Confirme sua senha"
          required
          {...store.formController.getField("password_confirmation")}
          type="password"
          placeholder="*********"
        />
        <InputCheckBox
          label={
            <>
              Concordo com os{" "}
              <b>
                <Link
                  label="Termos & Condições"
                  url="https://facilitelink.com.br/termos-e-condicoes/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </b>{" "}
              e a{" "}
              <b>
                <Link
                  label="Politica de Privacidade"
                  url="https://facilitelink.com.br/politicas-de-privacidade/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </b>
            </>
          }
          onChange={(event) => {
            store.toggleAcceptTerms(event.target.checked);
          }}
          checked={store.acceptTerms}
          style={{ margin: "30px 0" }}
        />
        <Button
          title="Próxima etapa >>"
          color={theme.colors.purpleDark}
          onClick={() => BuyPlan()}
        />
      </div>
    </Container>
  );
};

export default observer(RegisterBuyPlanScreen);
