import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import { FaAngleRight, FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";

import ScreenContainer from "src/layout/ScreenContainer";

import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import theme from "src/theme";
import { Button, Card, Empty } from "src/ui/components/index";

import CreateCouponScreen from "./components/CreateCouponScreen";
import ViewCouponScreen from "./components/ViewCouponScreen";
import Store from "./store";
import { Container, Coupon } from "./styles";

const CouponScreen: React.FC = () => {
  const { showModal, closeModal } = useModal();
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  const handleCreateCoupon = () => {
    showModal({
      content: <CreateCouponScreen closeModal={closeModal} />,
      onClickOut: closeModal,
    });

    loadCoupons();
    store.loader.end();
  };

  const loadCoupons = async () => {
    await store.getCoupons();
  };

  const handleViewCoupon = (coupon) => {
    showModal({
      content: <ViewCouponScreen closeModal={closeModal} couponId={coupon} />,
      onClickOut: closeModal,
    });
  };

  const removeCoupon = (couponId) => {
    Swal.fire({
      icon: "warning",
      title: "Atenção",
      text: "Você tem certeza que quer excluir esse registro?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#ee5463",
      confirmButtonText: "Excluir Registro",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        store.removeCoupon(couponId);
      }
    });
  };

  useEffect(() => {
    loadCoupons();
  }, []);

  return (
    <ScreenContainer>
      <Container>
        <div className="btn-insert-coupon">
          <Button
            title="Cadastrar Cupom"
            color={theme.colors.orangeDark}
            type="button"
            onClick={() => handleCreateCoupon()}
          />
        </div>
        <Card label="Cupons de Desconto cadastrados">
          {store.couponList.length > 0 ? (
            store.couponList.map((item, key) => (
              <Coupon key={key}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleViewCoupon(item.id)}
                >
                  {item.name}
                </div>
                <div>
                  <FaTrashAlt
                    onClick={() => removeCoupon(item.id)}
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                      fill: theme.colors.redDark,
                    }}
                  />
                  <FaAngleRight
                    onClick={() => handleViewCoupon(item.id)}
                    style={{
                      marginLeft: "6px",
                      cursor: "pointer",
                      fill: theme.colors.orange,
                    }}
                  />
                </div>
              </Coupon>
            ))
          ) : (
            <Coupon>
              <Empty
                title="Opssss...."
                description="Você ainda não tem cupons cadastrados!"
              />
            </Coupon>
          )}
        </Card>
      </Container>
    </ScreenContainer>
  );
};

export default observer(CouponScreen);
