import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import moment from "moment";
import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import theme from "src/theme";
import { Button, Table, Card, CardInner } from "src/ui/components/index";
import DaysAndTimeScreen from "src/ui/pages/DaysAndTimeScreen";

import Store from "./store";
import { Container } from "./styles";

interface DaysAndTimeProps {
  employee: string;
}

const DaysAndTime = ({ employee }: DaysAndTimeProps) => {
  const { showModal, closeModal } = useModal();
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  useEffect(() => {
    store.getDaysAndTimes(employee);
  }, []);

  return (
    <Container>
      <Card>
        <CardInner>
          <Table>
            <thead>
              <tr>
                <td>Dia</td>
                <td width={180}>Horário atendimento</td>
              </tr>
            </thead>

            <tbody>
              {store.daysAndTimes.map(({ weekday, start_time, end_time }) => (
                <tr key={weekday}>
                  <td>{weekday[0].toUpperCase() + weekday.substr(1)}</td>
                  <td>
                    {moment(start_time).zone("-0300").format("HH:mm:ss")} -{" "}
                    {moment(end_time).zone("-0300").format("HH:mm:ss")}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardInner>
      </Card>

      <Button
        title="Configurar"
        color={theme.colors.orangeDark}
        style={{ margin: "60px auto 0" }}
        onClick={() =>
          showModal({
            content: (
              <DaysAndTimeScreen
                closeModal={closeModal}
                onList={store.getDaysAndTimes}
                daysAndTimes={store.daysAndTimes}
                user_id={employee}
              />
            ),
            onClickOut: closeModal,
          })
        }
      />
    </Container>
  );
};

export default observer(DaysAndTime);
