import { observer } from "mobx-react";
import React from "react";

import theme from "src/theme";
import { Select, Button, ModalHeader } from "src/ui/components";

interface IProps {
  onPressContinue: () => void;
  formController: any;
  onGoBack: () => void;
}

const InitialForm: React.FC<IProps> = (props) => {
  const { onPressContinue, formController, onGoBack } = props;

  const paymentMethods = [
    { name: "Cartão", value: "credit_card" },
    { name: "Boleto", value: "bank_slip" },
    { name: "Pix", value: "pix" },
  ];

  return (
    <div className="payment-form">
      <ModalHeader onGoBack={onGoBack} title="Preencha os dados" />
      <Select
        label="Escolha a forma de pagamento"
        options={paymentMethods}
        {...formController.getField("paymentMethod")}
      />
      <Button
        title="Continuar"
        color={theme.colors.purpleDark}
        onClick={() => onPressContinue()}
      />
    </div>
  );
};

export default observer(InitialForm);
