import { action, makeObservable, observable } from "mobx";
import moment from "moment";

import api from "src/http/api";
import { IDaysAndTimes } from "src/http/interfaces/IDaysAndTimes";
import AppointmentsService, {
  AvailableHour,
  NewAppointment,
  TotalSchedules,
} from "src/services/appointments";
import ServicesService, { IService } from "src/services/services";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private servicesService: ServicesService;
  private appointmentsService: AppointmentsService;
  public loader: LoadingStore;
  public formController = new FormControllerStore({
    service_id: "",
    client_name: "",
    client_phone: "",
    client_cpf: "",
    payment_method: "",
    employee: "",
  });

  public services: IService[] = [];
  public selectedDay: Date | null = null;
  public selectedHour = "";
  public availableHours: AvailableHour[] = [];
  public appointmentsOfMonth: TotalSchedules | null = null;

  public daysAndTimes: IDaysAndTimes[] = [];
  public week: string[] = [];

  constructor(loader: LoadingStore) {
    super();
    makeObservable(this, {
      services: observable,
      selectedDay: observable,
      availableHours: observable,
      selectedHour: observable,
      appointmentsOfMonth: observable,
      daysAndTimes: observable,
      week: observable,
      getServices: action,
      setSelectedDay: action,
      setSelectedHour: action,
      createAppointment: action,
      deleteAppointment: action,
      getDaysAndTimes: action,
    });
    this.loader = loader;
    this.servicesService = new ServicesService();
    this.appointmentsService = new AppointmentsService();
  }

  public async getServices(employee: string) {
    try {
      this.loader.start();
      const response = await this.servicesService.getServices(employee);
      this.services = response;
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getMonthAppointments(userId: string, date: Date) {
    try {
      this.loader.start();
      const startOfMonth = moment(date).startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment(date).endOf("month").format("YYYY-MM-DD");
      this.appointmentsOfMonth =
        await this.appointmentsService.getSchedulesOfMonth(
          startOfMonth,
          endOfMonth,
          userId,
        );
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async setSelectedDay(selectedDay: Date, user_id: string) {
    this.selectedDay = selectedDay;

    try {
      this.loader.start();
      this.availableHours = await this.appointmentsService.getSchedule(
        {
          service_id: this.formController.getField("service_id").value,
          start_time: moment(selectedDay).format("YYYY-MM-DD"),
        },
        user_id,
      );
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public setSelectedHour(hour: string) {
    this.selectedHour = hour;
  }

  public deleteAppointment(idAppointment: number) {
    try {
      this.loader.start();
      this.appointmentsService.deleteAppointment(idAppointment);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async createAppointment(
    onSuccess: CallableFunction,
    idOldAppointment?: number,
  ) {
    try {
      this.loader.start();
      const {
        service_id,
        client_name,
        client_phone,
        client_cpf,
        payment_method,
        employee,
      } = this.formController.getFields();

      const date = moment(this.selectedDay).format("YYYY-MM-DD");
      const start_time = `${date} ${this.selectedHour}:00`;

      const newAppointment: NewAppointment = {
        start_time,
        payment_method: parseInt(payment_method),
        service_id,
        customer: {
          cpf: client_cpf,
          name: client_name,
          phone: client_phone,
        },
        user_id: employee,
      };

      await this.appointmentsService.createAppointment(newAppointment);

      if (idOldAppointment) {
        await this.appointmentsService.deleteAppointment(idOldAppointment);
      }

      this.showSuccess("Agendamento realizado com sucesso!");
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getDaysAndTimes(user_id: string) {
    try {
      this.loader.start();
      this.daysAndTimes = await api.getDaysAndTimes(user_id);
      this.week = [];
      this.daysAndTimes.forEach((element) => {
        this.week.push(element.weekday);
      });
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }
}
