import * as H from "history";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import Swal from "sweetalert2";

import api, { IInvoiceSubscriptions } from "src/http/api";
import { IEmployees } from "src/http/interfaces/IEmployees";
import formatters from "src/resources/formatters";
import AppointmentsService, {
  Appointments,
  DayCustomers,
  TotalSchedules,
} from "src/services/appointments";
import AuthStore from "src/stores/AuthStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private appointmentsService: AppointmentsService;
  public loader: LoadingStore;
  public history: H.History<unknown>;
  private auth: AuthStore;

  public totalSchedules: TotalSchedules | null = null;
  public dayCustomers: DayCustomers | null = null;
  public appointmentsWaitingApproval: Appointments | null = null;
  public appointmentsOfMonth: TotalSchedules | null = null;
  public daysToEndTestPeriod = 0;
  public daysPlanExpired = 0;
  public invoicesAndSubscriptions: IInvoiceSubscriptions | null = null;
  public countDays;
  public employees: IEmployees[];

  constructor(
    loader: LoadingStore,
    auth: AuthStore,
    history: H.History<unknown>,
  ) {
    super();
    makeObservable(this, {
      totalSchedules: observable,
      dayCustomers: observable,
      employees: observable,
      appointmentsWaitingApproval: observable,
      appointmentsOfMonth: observable,
      daysToEndTestPeriod: observable,
      daysPlanExpired: observable,
      invoicesAndSubscriptions: observable,
      countDays: observable,
      getTotalScheduled: action,
      getDayCustomers: action,
      getAppointmentsWaitingApproval: action,
      acceptAppointment: action,
      declineAppointment: action,
      showFinancialPending: action,
      getMonthAppointments: action,
      onClickDay: action,
      showModalFistLogin: action,
      alterLastLoginInLocalStorage: action,
      deleteAppointment: action,
      getDaysToEndTestPeriod: action,
      redirectBlockedUser: action,
      getEmployees: action,
    });
    this.appointmentsService = new AppointmentsService();
    this.loader = loader;
    this.auth = auth;
    this.history = history;
  }

  public async getAppointmentsWaitingApproval(userId?: string) {
    try {
      // this.loader.start();
      this.appointmentsWaitingApproval =
        await this.appointmentsService.getAppointments({
          status: "open",
          user_id: userId,
        });
    } catch (e) {
      /*       if (e.response.status === 403) {
        this.history.push({
          pathname: "planos",
          state: { isAccountBlocked: true },
        });
        return;
      }
      this.showError(this.handleError(e)); */
    } finally {
      // this.loader.end();
    }
  }

  public async getTotalScheduled() {
    try {
      this.loader.start();
      this.totalSchedules = await this.appointmentsService.getTotalSchedules();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getDayCustomers(userId?: string) {
    try {
      // this.loader.start();
      this.dayCustomers = await this.appointmentsService.getDayCustomers(
        userId,
      );
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      // this.loader.end();
    }
  }

  public async showFinancialPending(): Promise<void> {
    try {
      // this.loader.start();

      const user = this.auth.getCurrentUser();
      const preferences = await api.getPreferences(user.my_company.id);

      if (preferences.overdue_bills_reminder) {
        setTimeout(() => {
          (async () => {
            const { data: todayExpenses } = await api.getTodayExpenses();

            if (todayExpenses.length) {
              const storageAlertFinancial =
                localStorage.getItem("alertFinancial") || "{}";

              const alertFinancial = JSON.parse(storageAlertFinancial);
              const now = moment().format("YYYY-MM-DD");

              const dateNotify = moment(alertFinancial.lastNotify).format(
                "YYYY-MM-DD",
              );

              const showNotify =
                !moment(dateNotify).isSame(now, "day") ||
                !alertFinancial.lastNotify;

              if (showNotify) {
                let expensesHtml = "";

                for (const expense of todayExpenses) {
                  expensesHtml += `Despesa: <b>${
                    expense.description
                  }</b> | Valor: ${formatters.toCurrency(expense.price)}<br/>`;
                }

                Swal.fire({
                  title: "Olá! você tem financeiro pendente",
                  confirmButtonText: "Entendi!",
                  html: expensesHtml,
                  showDenyButton: true,
                  denyButtonText: "Fechar",
                }).then((result) => {
                  if (result.isConfirmed) {
                    const alertFinancialUpdated = {
                      lastNotify: moment().format(),
                    };

                    localStorage.setItem(
                      "alertFinancial",
                      JSON.stringify(alertFinancialUpdated),
                    );
                  }
                });
              }
            }
          })();
        }, 1500);
      }
    } catch (e) {
      this.showError(e.message);
    } finally {
      // this.loader.end();
    }
  }

  public showModalFistLogin(): boolean {
    try {
      this.loader.start();

      return !this.auth.currentUser?.last_login;
    } catch (e) {
      this.showError(e.message);
      return false;
    } finally {
      this.loader.end();
    }
  }

  public alterLastLoginInLocalStorage(): void {
    try {
      this.loader.start();
      const user = this.auth.getCurrentUser();
      this.auth.setCurrentUser({ ...user, last_login: new Date() });
    } catch (e) {
      this.showError(e.message);
    } finally {
      this.loader.end();
    }
  }

  public async getHomeData(userId?: string) {
    // this.loader.start();
    await this.getDaysToEndTestPeriod();
    await this.getUserStatus();
    await this.getAppointmentsWaitingApproval(userId);
    await this.getDayCustomers(userId);
    // await this.getTotalScheduled();
    await this.getMonthAppointments(new Date(), userId);
    await this.showFinancialPending();
    // this.loader.end();
  }

  public async acceptAppointment(appointmentId: number): Promise<void> {
    try {
      this.loader.start();
      await this.appointmentsService.acceptAppointment(appointmentId);
      await this.getHomeData();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async declineAppointment(appointmentId: number): Promise<void> {
    try {
      this.loader.start();
      await this.appointmentsService.declineAppointment(appointmentId);
      await this.getHomeData();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getMonthAppointments(date: Date, userId?: string) {
    try {
      // this.loader.start();
      const startOfMonth = moment(date).startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment(date).endOf("month").format("YYYY-MM-DD");
      this.appointmentsOfMonth =
        await this.appointmentsService.getSchedulesOfMonth(
          startOfMonth,
          endOfMonth,
          userId,
        );
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      // this.loader.end();
    }
  }

  public async onClickDay(
    day: Date,
    onClickDay: CallableFunction,
    currentEmployee: number,
  ) {
    try {
      this.loader.start();

      const today = moment();
      const selectedDay = moment(day);

      let editable = false;

      const parameters: {
        date?: string;
      } = {
        date: selectedDay.format("DD-MM-YYYY"),
      };

      const selectedDayIsAfter = selectedDay.isAfter(today);
      if (selectedDayIsAfter) {
        editable = true;
      } else {
        editable = false;
      }

      if (today.format("DD-MM") === selectedDay.format("DD-MM")) {
        editable = true;
      }

      const appointments = await this.appointmentsService.getAppointments(
        parameters,
        true,
      );
      if (appointments.total_records <= 0) {
        let block = {
          isBlocked: false,
        };
        if (this.appointmentsOfMonth) {
          for (const appointmentBlocked of this.appointmentsOfMonth
            .appointment_blocks) {
            const currentAppointmentBlocked = moment(
              appointmentBlocked.starts_at,
            ).format("YYYY-MM-DD");
            if (
              currentAppointmentBlocked === selectedDay.format("YYYY-MM-DD") &&
              (appointmentBlocked.type === "company" ||
                (appointmentBlocked.user_id &&
                  appointmentBlocked.user_id === currentEmployee))
            ) {
              block = { ...appointmentBlocked, isBlocked: true };
            }
          }
        }

        this.showInfoNoHaveAppointments(
          selectedDay.format("YYYY-MM-DD"),
          block,
        );
        return;
      }

      onClickDay(day, appointments, editable);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  private showInfoNoHaveAppointments(selectedDay: string, block) {
    const dayIsBlocked = block.isBlocked;

    Swal.fire({
      title: "Não existem agendamentos para este dia!",
      showDenyButton: !dayIsBlocked,
      showCancelButton: dayIsBlocked,
      icon: "info",
      confirmButtonText: "Ok",
      denyButtonText: "Bloquear dia p/ agendamento",
      confirmButtonColor: "#42c522cf",
      cancelButtonText: "Desbloquear p/ agendamento",
      cancelButtonColor: "#F8834B",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(async (result) => {
      if (result.isDenied) {
        this.appointmentsService.blockAppointment(
          `${selectedDay}T00:00:00.000-03:00`,
        );
        // Swal.fire("Dia bloqueado!", "", "success");

        Swal.fire({
          icon: "success",
          title: "",
          text: "Dia bloqueado!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          this.loader.start();
          window.location.reload();
        });
      } else if (result.isDismissed) {
        try {
          await this.appointmentsService.unblockAppointment(block.id);
          Swal.fire({
            icon: "success",
            title: "",
            text: "Dia desbloqueado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => {
            this.loader.start();
            window.location.reload();
          });
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "",
            text: "Não foi possível desbloquear esse dia",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => {
            this.loader.start();
            window.location.reload();
          });
        }
      }
    });
  }

  public deleteAppointment(idAppointment: number) {
    try {
      Swal.fire({
        title: "Deseja realmente excluir esse agendamento ?",
        showDenyButton: true,
        confirmButtonText: "Excluir",
        denyButtonText: "Não excluir",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loader.start();
          this.appointmentsService
            .deleteAppointment(idAppointment)
            .then((resp) => {
              if (resp.status === 200) {
                document.location.reload();
              }
            });
        }
      });
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getDaysToEndTestPeriod() {
    try {
      // this.loader.start();
      const daysToEnd = await api.getDaysToEndTestPeriod();
      this.daysToEndTestPeriod = daysToEnd.days;

      if (daysToEnd.days === 0 && daysToEnd.free_trial === true) {
        this.history.push({
          pathname: "planos",
          state: { isAccountBlocked: true },
        });
        return;
      }
    } catch (e) {
    } finally {
      // this.loader.end();
    }
  }

  public diffDateWithToday(expiredDate) {
    const expired = moment(expiredDate);
    const today = moment(new Date());
    const duration = moment.duration(today.diff(expired));

    const days = Math.floor(duration.asDays()) * -1;
    const daysFormatted = days ? `${days}d ` : "";

    const hours = duration.hours() * -1;
    const hoursFormatted = `${hours}h `;

    const minutes = duration.minutes() * -1;
    const minutesFormatted = `${minutes}m`;

    this.countDays = [daysFormatted, hoursFormatted, minutesFormatted].join("");

    return days;
  }

  public redirectBlockedUser() {
    this.history.push({
      pathname: "planos",
      state: { isAccountBlocked: true },
    });
  }

  public async getUserStatus() {
    try {
      // this.loader.start();
      this.invoicesAndSubscriptions = await api.getUserStatus();

      /* if(this.invoicesAndSubscriptions) {
        console.log("tem assina")
      } */

      this.daysPlanExpired = this.diffDateWithToday(
        this.invoicesAndSubscriptions.plan_expired,
      );

      if (this.daysPlanExpired < -15) {
        this.history.push({
          pathname: "planos",
          state: { isAccountBlocked: true },
        });
        return;
      }
    } catch (e) {
    } finally {
      // this.loader.end();
    }
  }

  public async getEmployees(idUser: string) {
    try {
      this.employees = await api.newGetEmployees(idUser);
    } catch (e) {
      // this.showError(this.handleError(e));
    } finally {
      // this.loader.end();
    }
  }
}
