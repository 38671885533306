import styled from "styled-components";

export const Company = styled.div<{ primaryColor: string; textColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  padding: 24px;
  border-radius: 16px;

  background-color: ${({ theme, primaryColor }) =>
    primaryColor || theme.colors.purpleDark};

  color: ${({ theme, textColor }) => textColor || theme.colors.white};
`;

export const Avatar = styled.img`
  width: 124px;
  height: 124px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
`;

export const CompanyText = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    max-width: 248px;
  }
`;

export const CompanyInfo = styled.div<{ isSmall: boolean }>`
  display: flex;
  flex-direction: ${({ isSmall }) => (isSmall ? "row" : "column")};
  align-items: center;
  gap: 16px 8px;

  ${Avatar} {
    width: ${({ isSmall }) => (isSmall ? "80px" : "124px")};
    height: ${({ isSmall }) => (isSmall ? "80px" : "124px")};
  }

  ${CompanyText} {
    row-gap: ${({ isSmall }) => (isSmall ? "8px" : "16px")};
    text-align: ${({ isSmall }) => (isSmall ? "left" : "center")};

    h1 {
      font-size: ${({ isSmall }) => (isSmall ? "16px" : "20px")};
    }

    p {
      font-size: ${({ isSmall }) => (isSmall ? "12px" : "16px")};
    }
  }
`;

export const WhatsAppButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: transparent;
  border: solid 1px #fff;
  color: #fff;
  cursor: pointer;
  max-width: 196px;

  span {
    font-size: 14px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;
