import React from "react";

import { Container } from "./styles";

interface ITextarea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  style?: React.CSSProperties;
}

export const Textarea: React.FC<ITextarea> = (props) => {
  const { label, placeholder, ...rest } = props;

  return (
    <Container>
      {label && <label>{label}</label>}
      <div className="input-container">
        <textarea rows={3} placeholder={placeholder} {...rest} />
      </div>
    </Container>
  );
};
