import { observer, useLocalObservable } from "mobx-react";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import faciliteLogo from "src/assets/svgs/facilite-link.svg";
import { Container, Nav, Wrapper } from "./styles";
import ClientAppointment from "src/ui/pages/ClientScreen/templates/ClientAppointment";
import CompanyCard from "src/ui/pages/ClientScreen/components/CompanyCard";
import CompaniesService from "src/services/companies";
import ServicesService from "src/services/services";
import { useClientScreenStore } from "src/ui/pages/ClientScreen/store";
import { Appointment } from "src/types/appointment.interface";
import ClientPersonData from "src/ui/pages/ClientScreen/templates/ClientPersonData";
import ClientAppointmentCompleted from "src/ui/pages/ClientScreen/templates/ClientAppointmentCompleted";

const ClientAreaScreen = () => {
  const store = useLocalObservable(useClientScreenStore);
  const { company, whatsApp } = store;

  const [page, setPage] = useState(0);
  const { slug } = useParams<{ slug: string }>();

  function handleSaveAppointment(data: Appointment) {
    store.setAppointment(data);
    setPage(1);
  }

  function handleCreateAppointment() {
    setPage(2);
  }

  useEffect(() => {
    if (slug) {
      (async () => {
        const companyData = await new CompaniesService().getCompany(slug);
        store.setCompany(companyData);

        store.getMonthAppointments(companyData.user_id, new Date());
        store.getSocialNetworks(companyData.user_id);

        if (companyData.profile === "provider") {
          const services = await new ServicesService().getProviderServices(
            companyData.user_id,
          );

          store.setServices(services);
        }
      })();
    }
  }, []);

  return (
    <Container>
      <Nav>
        <img src={faciliteLogo} />
      </Nav>

      <Wrapper>
        <CompanyCard
          company={company}
          whatsApp={whatsApp}
          isLoading={!company}
          isSmall={page !== 0}
        />
        {company && page === 0 && (
          <ClientAppointment onSubmit={handleSaveAppointment} />
        )}
        {page === 1 && <ClientPersonData onSubmit={handleCreateAppointment} />}
        {page === 2 && <ClientAppointmentCompleted />}
      </Wrapper>
    </Container>
  );
};

export default observer(ClientAreaScreen);
