import React from "react";
import { Content, HoursList, HourItem, WithoutHour } from "./styles";
import { Title } from "src/ui/pages/ClientScreen/styles";
import { CompanyTheme } from "src/types/company.interface";
import { useQuery } from "react-query";
import AppointmentsService from "src/services/appointments";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

interface Props {
  refId: string;
  servicesIds: number[];
  daySelected: Date;

  hourSelected: string;
  theme: CompanyTheme;

  onSelectHour: (hour: string) => void;
}

const AppointmentHour = ({
  refId,
  daySelected,
  servicesIds,
  theme,
  hourSelected,
  onSelectHour,
}: Props) => {
  const { data: availableHours, isLoading } = useQuery(
    ["availableHours", refId, servicesIds, daySelected],
    () =>
      new AppointmentsService().getScheduleByLink(refId, {
        service_id: servicesIds,
        start_time: moment(daySelected).format("YYYY-MM-DD"),
      }),
  );

  return (
    <Content>
      <Title>Selecione o horário:</Title>

      <HoursList>
        {isLoading && (
          <>
            <Skeleton width={85} height={28} />
            <Skeleton width={85} height={28} />
            <Skeleton width={85} height={28} />
            <Skeleton width={85} height={28} />
            <Skeleton width={85} height={28} />
            <Skeleton width={85} height={28} />
          </>
        )}

        {!isLoading &&
          availableHours?.map(({ time }) => (
            <HourItem
              key={time}
              active={hourSelected === time}
              primary={theme.primary}
              textColor={theme.color}
              onClick={() => onSelectHour(time)}
            >
              {time}
            </HourItem>
          ))}
      </HoursList>

      {!isLoading && availableHours?.length === 0 && (
        <WithoutHour>Não há horários disponíveis</WithoutHour>
      )}
    </Content>
  );
};

export default AppointmentHour;
