import styled from "styled-components";

export const Container = styled.div`
  > label {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    margin-bottom: 8px;
    display: block;
  }
  > .input-container {
    display: flex;
    width: 100%;
    margin-bottom: 27px;
    border-radius: 5px;
    flex-direction: row;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    > textarea {
      flex: 1;
      width: 100%;
      height: 100%;
      border: none;
      padding: 15px;
      font-size: ${({ theme }) => theme.fontSizes.s18}px;
      color: ${({ theme }) => theme.colors.greyDarker};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      border-radius: 5px;
      ::placeholder {
        color: ${({ theme }) => theme.colors.grey};
      }
    }
  }
`;
