import { observer, useLocalStore } from "mobx-react";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import arrowLeft from "src/assets/svgs/arrowLeft.svg";
import { useGlobalStore } from "src/context/global";
import { IUser } from "src/http/api";
import { IEmployees } from "src/http/interfaces/IEmployees";
import theme from "src/theme";
import { Button, Input, InputMoney, Select, Switch } from "src/ui/components";
import swal from "sweetalert";

import { categoryList } from "./mappers/category";
import { typeList } from "./mappers/types";
import Store from "./store";
import { Container, Header } from "./styles";

interface FinancialDetailsScreen {
  closeModal: () => void;
  onList: () => void;
  id?: number;
  employees: IEmployees[];
}

const FinancialDetailsScreen: React.FC<FinancialDetailsScreen> = (props) => {
  const { id, closeModal, onList, employees } = props;
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  const [financial_type, setFinancialType] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [recurrence, setRecurrence] = useState(1);
  const [paid, setPaid] = useState(false);
  const [due_date, setDueDate] = useState("");
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [employee, setEmployee] = useState("");

  const loggedInAs = JSON.parse(localStorage.getItem("loggedInAs")!) as string;

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!employee && !loggedInAs && user.profile === "hall") {
      swal("Ops!", "Por favor, preencha todos os campos obrigatórios", "error");
      return;
    }

    if (!id) {
      await store.createFinancial(closeModal, {
        financial_type,
        category,
        description,
        price: parseInt(price.replace(/\D/g, "")),
        discount: parseInt(discount.replace(/\D/g, "")),
        recurrence,
        due_date,
        paid,
        user_id: loggedInAs
          ? loggedInAs
          : user.profile === "hall"
          ? employee
          : String(user.id),
      });
    } else {
      await store.updateFinancial(
        closeModal,
        id,
        paid,
        loggedInAs
          ? loggedInAs
          : user.profile === "hall"
          ? employee
          : String(user.id),
        parseInt(discount.replace(/\D/g, "")),
      );
    }
    onList();
  }

  async function handleDelete() {
    await store.deleteFinancial(closeModal, id);
    onList();
  }

  useEffect(() => {
    async function sideEffects() {
      await store.getOneFinancial(id);

      setFinancialType(store.financial.financial_type);
      setCategory(store.financial.category);
      setDescription(store.financial.description);
      setPrice((store.financial.price / 100).toString());
      setDiscount((store.financial.discount / 100).toString());
      setRecurrence(store.financial.recurrence);
      setPaid(store.financial.paid);
      setDueDate(moment(store.financial.due_date).format("YYYY-MM-DD"));
      setDisabledEdit(true);
    }

    if (id) {
      sideEffects();
    }
  }, []);

  return (
    <Container>
      <Header>
        <button className="go-back" onClick={closeModal}>
          <img src={arrowLeft} />
        </button>

        <h1>{id ? "Detalhes" : "Lançar financeiro"}</h1>
      </Header>

      <form onSubmit={handleOnSubmit}>
        <Select
          label="Tipo"
          options={typeList}
          disabled={disabledEdit}
          onChange={(event) => setFinancialType(event.target.value)}
          value={financial_type}
          required
        />

        {financial_type === "output" && (
          <Input
            label="Data de vencimento"
            type="date"
            value={due_date}
            disabled={disabledEdit}
            onChange={(event) => setDueDate(event.target.value)}
            required
          />
        )}

        {financial_type && (
          <Select
            label="Categoria"
            options={categoryList(financial_type)}
            onChange={(event) => setCategory(event.target.value)}
            value={category}
            disabled={disabledEdit}
            required
          />
        )}

        {user.profile === "hall" && !loggedInAs && (
          <Select
            label="Vincular Profissional"
            options={employees.map((e) => ({
              value: String(e.id),
              name: e.name,
            }))}
            onChange={(e) => setEmployee(e.target.value)}
            value={employee}
            required
          />
        )}

        <Input
          label="Descrição"
          value={description}
          disabled={disabledEdit}
          onChange={(event) => setDescription(event.target.value)}
          required
        />

        <InputMoney
          label="Valor da conta"
          value={price}
          disabled={disabledEdit}
          onChange={(event) => setPrice(event.target.value)}
          required
        />

        {category === "revenue" && (
          <InputMoney
            label="Valor do desconto"
            value={discount}
            onChange={(event) => setDiscount(event.target.value)}
          />
        )}

        {id ? null : (
          <Input
            label="Recorrência"
            type="number"
            min="1"
            max="100"
            value={recurrence}
            disabled={disabledEdit}
            onChange={(event) => setRecurrence(parseFloat(event.target.value))}
            required
          />
        )}

        {financial_type === "output" && (
          <Switch
            label="Pago"
            onChange={(event) => setPaid(event.target.checked)}
            checked={paid}
          />
        )}

        {id ? (
          <Button
            title="Excluir Lançamento"
            color={theme.colors.purpleDark}
            outlined
            mb={10}
            type="button"
            onClick={() => handleDelete()}
          />
        ) : null}

        <Button title="Salvar" color={theme.colors.orangeDark} outlined />
      </form>
    </Container>
  );
};

export default observer(FinancialDetailsScreen);
