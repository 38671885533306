import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin-bottom: 30px;
  }

  table {
    thead > tr > td {
      text-align: center;
    }
  }
`;
