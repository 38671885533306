import React from "react";
import {
  EmployeeItem,
  EmployeeList,
  Employees,
  Avatar,
  AvatarText,
  EmployeeSkeleton,
} from "./styles";
import { Title } from "src/ui/pages/ClientScreen/styles";
import { IEmployees } from "src/http/interfaces/IEmployees";
import { useQuery } from "react-query";
import api from "src/http/api";
import { CompanyTheme } from "src/types/company.interface";
import Skeleton from "react-loading-skeleton";

interface Props {
  companyId: string;
  employeeSelected?: IEmployees;
  theme: CompanyTheme;

  onSelectEmployee: (employee: IEmployees) => void;
}

const titulos = [
  "Dr.",
  "Dra.",
  "Sr.",
  "Sra.",
  "Prof.",
  "Profa.",
  "Eng.",
  "Enga.",
  "Pe.",
];

const EmployeeItemSkeleton = () => (
  <EmployeeSkeleton>
    <Skeleton
      circle
      height="55px"
      width="55px"
      containerClassName="avatar-skeleton"
    />
    <Skeleton width={134} />
  </EmployeeSkeleton>
);

const AppointmentEmployees = ({
  companyId,
  theme,
  employeeSelected,
  onSelectEmployee,
}: Props) => {
  const { data: employees, isLoading } = useQuery(
    ["employees", companyId],
    () => api.newGetEmployees(companyId),
  );

  function getInitials(name: string): string {
    return name
      .split(" ")
      .slice(0, 2)
      .map((word) => word[0])
      .join("");
  }

  function formatEmployeeName(name: string): string {
    const words = name.split(" ");

    if (titulos.includes(words[0])) {
      if (words.length > 2) {
        words[2] = words[2][0].toUpperCase() + ".";
      }

      if (words.length > 1) {
        words[1] = words[1][0].toUpperCase() + words[1].slice(1).toLowerCase();
      }
    }

    if (words.length > 1 && !titulos.includes(words[0])) {
      words[1] = words[1][0].toUpperCase() + ".";
    }

    words[0] = words[0][0].toUpperCase() + words[0].slice(1).toLowerCase();

    return words.join(" ");
  }

  return (
    <Employees>
      <Title>Selecione um profissional:</Title>

      <EmployeeList>
        {isLoading && (
          <>
            <EmployeeItemSkeleton />
            <EmployeeItemSkeleton />
          </>
        )}

        {!isLoading &&
          employees
            ?.filter((e) => e.status === "active")
            ?.map((employee) => (
              <EmployeeItem
                key={employee.id}
                active={employeeSelected?.id === employee.id}
                primary={theme.primary}
                textColor={theme.color}
                onClick={() => onSelectEmployee(employee)}
              >
                {employee.image_url ? (
                  <Avatar src={employee.image_url} />
                ) : (
                  <AvatarText primary={theme.primary} textColor={theme.color}>
                    {getInitials(employee.name)}
                  </AvatarText>
                )}

                <p>{formatEmployeeName(employee.name)}</p>
              </EmployeeItem>
            ))}
      </EmployeeList>
    </Employees>
  );
};

export default AppointmentEmployees;
