import styled, { css } from "styled-components";

interface ThemeProps {
  active?: boolean;
  primary?: string;
  textColor?: string;
}

export const Avatar = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
`;

export const Employees = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const EmployeeList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 12px;
  align-items: flex-start;
`;

export const AvatarText = styled.div<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 55px;
  height: 55px;

  background-color: ${({ theme, primary }) =>
    primary || theme.colors.purpleDark};

  color: ${({ theme, textColor }) => textColor || theme.colors.white};
`;

export const EmployeeItem = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;

  p {
    text-align: center;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
    width: 100%;
  }

  ${({ active, primary, textColor }) =>
    active
      ? css`
          p {
            background-color: ${primary};
            color: ${textColor};
          }
        `
      : css`
          p {
            border: solid 1px ${primary};
            color: ${primary};
          }
        `}
`;

export const EmployeeSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
