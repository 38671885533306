import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .resume {
    display: flex;
    flex-direction: column;

    &__header {
      text-align: center;
      color: #c2c2c2;
      font-size: 18px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
    }
  }

  .financial {
    display: flex;
    flex-direction: column;

    &__header {
      text-align: center;
      color: #c2c2c2;
      font-size: 18px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
    }
  }
`;
