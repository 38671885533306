import { observer } from "mobx-react";
import React from "react";
import { FaUsersCog } from "react-icons/fa";
import { FiChevronLeft, FiEdit } from "react-icons/fi";
import { HiOutlineCog, HiOutlineCash } from "react-icons/hi";
import { IoMdHelpBuoy, IoMdCalendar } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { RiCoupon3Line } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";

import LogoWhite from "src/assets/images/logoWhite.png";
import { useGlobalStore } from "src/context/global";
import { IUser } from "src/http/api";
import client from "src/http/client";

import { Container, Menu, MenuOut } from "./styles";

interface MenuDesktopProps {
  accountBlocked?: boolean;
}

export const MenuDesktop: React.FC<MenuDesktopProps> = observer((props) => {
  const { accountBlocked } = props;
  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);
  const [aux, setAux] = React.useState(
    !!JSON.parse(localStorage.getItem("loggedInAs")!),
  );

  const { authStore } = useGlobalStore();
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  const items = [
    [
      {
        title: "Agenda",
        icon: <IoMdCalendar />,
        link: "/",
      },
      {
        title: "Financeiro",
        icon: <HiOutlineCash />,
        link: "/financeiro",
      },
      user.profile !== "employee" ? {
        title: "Cupons",
        icon: <RiCoupon3Line />,
        link: "/cupons",
      } : {
        title: "",
        icon: <></>,
        link: "",
      },
      {
        title: "Customizar",
        icon: <FiEdit />,
        link: "/customizar",
      },
      user.profile === "hall" && !aux
        ? {
            title: "Profissionais",
            icon: <FaUsersCog />,
            link: "/profissionais",
          }
        : {
            title: "Configurações",
            icon: <HiOutlineCog />,
            link: "/configuracoes",
          },
      user.profile === "hall" && aux
        ? {
            title: "Voltar para Salão",
            icon: <FiChevronLeft />,
            link: "/configuracoes",
          }
        : {
            title: "",
            icon: <></>,
            link: "",
          },
    ],
    [
      {
        title: "Suporte",
        icon: <IoMdHelpBuoy />,
        link: "/suporte",
      },
    ],
  ];

  const onLogout = () => {
    history.push("/");
  };

  return (
    <Container>
      <Menu>
        <ul>
          <img src={LogoWhite} alt="logo empresa facilite" />
          {!accountBlocked &&
            items[0].map((item, index) => (
              <>
                {item.title === "Voltar para Salão" ? (
                  <>
                    <li
                      key={index.toString()}
                      onClick={() => {
                        localStorage.removeItem("loggedInAs");
                        delete client.defaults.headers["IMPERSONATE_USER"];
                        window.location.reload();
                      }}
                    >
                      <Link to={item.link}>
                        {item.icon}
                        {item.title}
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li key={index.toString()}>
                      <Link to={item.link}>
                        {item.icon}
                        {item.title}
                      </Link>
                    </li>
                  </>
                )}
              </>
            ))}
        </ul>
        <ul>
          {items[1].map((item, index) => (
            <li key={index.toString()}>
              <a href={item.link} rel="noreferrer">
                {item.icon}
                {item.title}
              </a>
            </li>
          ))}
          <li>
            <a onClick={() => authStore.logout(onLogout)} href="#">
              <IoLogOutOutline /> Sair
            </a>
          </li>
        </ul>
      </Menu>
      <MenuOut onClick={() => setMobileMenuVisible(!mobileMenuVisible)} />
    </Container>
  );
});
