import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import Carousel from "react-elastic-carousel";

import { useGlobalStore } from "src/context/global";

import Store from "./store";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { Container } from "../RegisterBuyPlanScreen/styles";
import Plan from "../PlansScreen/components/Plan";
import { useModal } from "src/context/modal";
import RegisterScreen from "../RegisterScreen";

interface RegisterBuyPlanScreenProps {
  closeModal: () => void;
}

const RegisterBuyPlanScreen: React.FC<RegisterBuyPlanScreenProps> = (props) => {
  const { closeModal } = props;
  const { loadingStore } = useGlobalStore();
  const { showModal } = useModal();

  const { width } = useWindowDimensions();

  const store = useLocalStore(() => new Store(loadingStore));

  useEffect(() => {
    store.getPlans();
  }, []);

  const PlansWrapper = ({ children }) =>
    width < 670 ? (
      <Carousel isRTL={false} showArrows={false}>
        {children}
      </Carousel>
    ) : (
      <Carousel isRTL={false} showArrows>
        {children}
      </Carousel>
    );

  return (
    <Container>
      <div className="register-form">
        <PlansWrapper>
          {store.plans[0] &&
            store.plans.map((plan) => (
              <Plan
                key={plan.id}
                title={plan.name}
                description={plan.description}
                valueByMonthWitDiscount={plan.value_by_month_with_discount}
                valueByMonthWithoutDiscount={
                  plan.value_by_month_without_discount
                }
                image={plan.image}
                chargeType={plan.billing_explanation}
                highlight
                identifier={
                  store.invoicesAndSubscriptions?.plan_identifier ===
                  plan.identifier
                    ? true
                    : false
                }
                invoice={store.invoicesAndSubscriptions?.recent_invoice}
                onPressSubscribe={() => {
                  showModal({
                    content: (
                      <RegisterScreen closeModal={closeModal} plan={plan.id} />
                    ),
                    onClickOut: closeModal,
                    size: "small",
                  });
                }}
                onPressUpdate={() => {}}
              />
            ))}
        </PlansWrapper>
      </div>
    </Container>
  );
};

export default observer(RegisterBuyPlanScreen);
