import React from "react";

interface IFetchData {
  emptyComponent: React.ReactElement;
  errorComponent: React.ReactElement;
  hasError?: boolean;
  items?: any[];
}

export const FetchData: React.FC<IFetchData> = (props) => {
  const { emptyComponent, errorComponent, hasError, items, children } = props;

  const renderData = () => {
    if (hasError) {
      return errorComponent;
    }
    if (items && items.length === 0) {
      return emptyComponent;
    }
    return children;
  };

  return <div>{renderData()}</div>;
};
