import React from "react";
import Lottie from "react-lottie";

import EmptyBox from "src/assets/lotties/empty-box.json";

import { Container } from "./styles";

interface IEmpty {
  title: string;
  description?: string;
}
export const Empty: React.FC<IEmpty> = (props) => {
  const { title, description } = props;
  return (
    <Container>
      <Lottie
        options={{
          animationData: EmptyBox,
          autoplay: true,
          loop: true,
        }}
        width={200}
      />
      <h1>{title}</h1>
      <p>{description}</p>
    </Container>
  );
};
