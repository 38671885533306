import React from "react";

import theme from "src/theme";

import { Container } from "./styles";

interface IProps {
  fontColor?: string;
}

export const CardLabel: React.FC<IProps> = (props) => {
  const { children, fontColor } = props;
  return (
    <Container color={fontColor || theme.colors.greyDarker}>
      {children}
    </Container>
  );
};
