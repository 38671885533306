import * as H from "history";
import { observer } from "mobx-react";
import React from "react";
import Swal from "sweetalert2";

import BankSlip from "../BankSlip";
import CardForm from "../CardForm";
import InitialForm from "../InitialForm";
import Pix from "../Pix";

interface PlanProps {
  closeModal: () => void;
  history: H.History<unknown>;
  formController: any;
  store: any;
}

const Plan: React.FC<PlanProps> = (props) => {
  const { closeModal, history, formController, store } = props;

  const onPressContinue = () => {
    if (formController.getField("paymentMethod").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "Selecione um meio de pagamento",
      });
      return;
    }

    if (formController.getField("plan_id").value === "") {
      Swal.fire({
        icon: "error",
        title: "Ops!",
        text: "Selecione um plano",
      });
      return;
    }

    if (formController.getField("paymentMethod").value === "credit_card") {
      store.plan = store.plans.find(function (planFilter) {
        return (
          planFilter.id === parseInt(formController.getField("plan_id").value)
        );
      });
      store.setCurrentScreen("credit_card");
      return;
    }

    store.updateSubscriptionPayment();
  };

  const goToInitialForm = () => {
    store.setCurrentScreen("initial");
  };

  const updateSubscriptionPayment = (onSuccess: () => void) => {
    store.updateSubscriptionPayment(onSuccess);
  };

  return (
    <>
      {store.currentScreen === "initial" && (
        <InitialForm
          formController={formController}
          onGoBack={closeModal}
          onPressContinue={() => onPressContinue()}
          plansP={store.plans}
        />
      )}
      {store.currentScreen === "credit_card" && (
        <CardForm
          formController={formController}
          onGoBack={goToInitialForm}
          closeModal={closeModal}
          history={history}
          createOrder={updateSubscriptionPayment}
          plan={store.plan}
        />
      )}
      {store.currentScreen === "pix" && (
        <Pix
          formController={formController}
          closeModal={closeModal}
          order={store.order}
          onGoBack={goToInitialForm}
          history={history}
        />
      )}
      {store.currentScreen === "bank_slip" && (
        <BankSlip
          formController={formController}
          order={store.order}
          onGoBack={goToInitialForm}
          closeModal={closeModal}
          history={history}
        />
      )}
    </>
  );
};

export default observer(Plan);
