/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { observer, useLocalStore } from "mobx-react";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BsFillReplyAllFill } from "react-icons/bs";

import arrowLeft from "src/assets/svgs/arrowLeft.svg";
import { useGlobalStore } from "src/context/global";
import { IUser } from "src/http/api";
import { IDaysAndTimes } from "src/http/interfaces/IDaysAndTimes";
import theme from "src/theme";
import { InputCheckBox, Button } from "src/ui/components";

import Store from "./store";
import { Container, Header, Body } from "./styles";

interface IDaysAndTimeScreen {
  closeModal: () => void;
  onList: (user_id: string) => Promise<void>;
  daysAndTimes?: IDaysAndTimes[];
  user_id: string;
}

interface IWeekDay {
  weekday?: string;
  start_time?: string;
  end_time?: string;
  start_lunch_time?: string;
  end_lunch_time?: string;
  user_id?: string;
}
interface ILunchTime {
  start_lunch_time?: string;
  end_lunch_time?: string;
}

const DaysAndTimeScreen: React.FC<IDaysAndTimeScreen> = (props) => {
  const { closeModal, onList, daysAndTimes, user_id } = props;
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  const [monday, setMonday] = useState<IWeekDay>({
    weekday: "",
    start_time: "",
    end_time: "",
  });
  const [tuesday, setTuesday] = useState<IWeekDay>({
    weekday: "",
    start_time: "",
    end_time: "",
  });
  const [wednesday, setWednesday] = useState<IWeekDay>({
    weekday: "",
    start_time: "",
    end_time: "",
  });
  const [thursday, setThursday] = useState<IWeekDay>({
    weekday: "",
    start_time: "",
    end_time: "",
  });
  const [friday, setFriday] = useState<IWeekDay>({
    weekday: "",
    start_time: "",
    end_time: "",
  });
  const [saturday, setSaturday] = useState<IWeekDay>({
    weekday: "",
    start_time: "",
    end_time: "",
  });
  const [sunday, setSunday] = useState<IWeekDay>({
    weekday: "",
    start_time: "",
    end_time: "",
  });
  const [lunchTime, setLunchTime] = useState<ILunchTime>({
    start_lunch_time: "",
    end_lunch_time: "",
  });

  useEffect(() => {
    daysAndTimes?.forEach((dayAndTime) => {
      const { weekday, start_time, end_time } = dayAndTime;

      const startTime = moment(start_time).zone("-0300").format("HH:mm");
      const endTime = moment(end_time).zone("-0300").format("HH:mm");

      if (weekday === "segunda") {
        setMonday({ weekday, start_time: startTime, end_time: endTime });
        setLunchTime({});
      }

      if (weekday === "terça") {
        setTuesday({ weekday, start_time: startTime, end_time: endTime });
      }

      if (weekday === "quarta") {
        setWednesday({ weekday, start_time: startTime, end_time: endTime });
      }

      if (weekday === "quinta") {
        setThursday({ weekday, start_time: startTime, end_time: endTime });
      }

      if (weekday === "sexta") {
        setFriday({ weekday, start_time: startTime, end_time: endTime });
      }

      if (weekday === "sábado") {
        setSaturday({ weekday, start_time: startTime, end_time: endTime });
      }

      if (weekday === "domingo") {
        setSunday({ weekday, start_time: startTime, end_time: endTime });
      }
    });

    if (daysAndTimes?.length) {
      const lunch = daysAndTimes[0];
      const startLunchTime = moment(lunch.start_lunch_time).zone("-0300").format("HH:mm");
      const endLunchTime = moment(lunch.end_lunch_time).zone("-0300").format("HH:mm");

      setLunchTime({
        start_lunch_time: startLunchTime,
        end_lunch_time: endLunchTime,
      });
    }
  }, []);

  /* @TODO = REFATORAR ESSE HANDLE PELO AMOR DE DEUS */
  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const weekDayList: IWeekDay[] = [];
    const lunch = {
      start_lunch_time: lunchTime.start_lunch_time,
      end_lunch_time: lunchTime.end_lunch_time,
    };

    if (monday.weekday) {
      weekDayList.push({
        ...monday,
        ...lunch,
        start_time: monday.start_time,
        end_time: monday.end_time,
        user_id: user.profile === "hall" ? user_id : String(user.id),
      });
    }

    if (tuesday.weekday) {
      weekDayList.push({
        ...tuesday,
        ...lunch,
        start_time: tuesday.start_time,
        end_time: tuesday.end_time,
        user_id: user.profile === "hall" ? user_id : String(user.id),
      });
    }

    if (wednesday.weekday) {
      weekDayList.push({
        ...wednesday,
        ...lunch,
        start_time: wednesday.start_time,
        end_time: wednesday.end_time,
        user_id: user.profile === "hall" ? user_id : String(user.id),
      });
    }

    if (thursday.weekday) {
      weekDayList.push({
        ...thursday,
        ...lunch,
        start_time: thursday.start_time,
        end_time: thursday.end_time,
        user_id: user.profile === "hall" ? user_id : String(user.id),
      });
    }

    if (friday.weekday) {
      weekDayList.push({
        ...friday,
        ...lunch,
        start_time: friday.start_time,
        end_time: friday.end_time,
        user_id: user.profile === "hall" ? user_id : String(user.id),
      });
    }

    if (saturday.weekday) {
      weekDayList.push({
        ...saturday,
        ...lunch,
        start_time: saturday.start_time,
        end_time: saturday.end_time,
        user_id: user.profile === "hall" ? user_id : String(user.id),
      });
    }

    if (sunday.weekday) {
      weekDayList.push({
        ...sunday,
        ...lunch,
        start_time: sunday.start_time,
        end_time: sunday.end_time,
        user_id: user.profile === "hall" ? user_id : String(user.id),
      });
    }

    await store.createDaysAndTimes(closeModal, { working_days: weekDayList });
    onList(user_id);
  }

  function duplicateTime(startTime, endTime) {
    if (monday.weekday) {
      setMonday({
        weekday: "segunda",
        start_time: startTime,
        end_time: endTime,
      });
    }

    if (tuesday.weekday) {
      setTuesday({
        weekday: "terça",
        start_time: startTime,
        end_time: endTime,
      });
    }

    if (wednesday.weekday) {
      setWednesday({
        weekday: "quarta",
        start_time: startTime,
        end_time: endTime,
      });
    }

    if (thursday.weekday) {
      setThursday({
        weekday: "quinta",
        start_time: startTime,
        end_time: endTime,
      });
    }

    if (friday.weekday) {
      setFriday({ weekday: "sexta", start_time: startTime, end_time: endTime });
    }

    if (saturday.weekday) {
      setSaturday({
        weekday: "sábado",
        start_time: startTime,
        end_time: endTime,
      });
    }

    if (sunday.weekday) {
      setSunday({
        weekday: "domingo",
        start_time: startTime,
        end_time: endTime,
      });
    }
  }

  return (
    <Container>
      <Header>
        <button className="go-back" onClick={closeModal}>
          <img src={arrowLeft} />
        </button>

        <div>
          <h1>Dias e horários</h1>
          <span>Marque os dias e horários que deseja atender</span>
        </div>
      </Header>

      <Body>
        <span>Dias e horários de atendimento</span>
        <form onSubmit={handleOnSubmit}>
          <div className="daysAndTime">
            <div className="daysAndTime__item">
              <InputCheckBox
                label="Segunda"
                checked={!!monday.weekday}
                onChange={(event) => {
                  if (event.target.checked) {
                    setMonday({ weekday: "segunda" });
                  } else {
                    setMonday({ weekday: "", start_time: "", end_time: "" });
                  }
                }}
              />

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={monday.start_time}
                  onChange={(event) =>
                    setMonday({ ...monday, start_time: event.target.value })
                  }
                  disabled={!monday.weekday}
                  required
                />
              </div>
              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={monday.end_time}
                  onChange={(event) =>
                    setMonday({ ...monday, end_time: event.target.value })
                  }
                  disabled={!monday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__button_duplicate tooltip">
                <button
                  type="button"
                  disabled={!monday.weekday}
                  onClick={() =>
                    duplicateTime(monday.start_time, monday.end_time)
                  }
                >
                  <BsFillReplyAllFill />
                </button>

                <span className="tooltiptext">
                  Replicar para os dias habilitados
                </span>
              </div>
            </div>

            <div className="daysAndTime__item">
              <InputCheckBox
                label="Terça"
                checked={!!tuesday.weekday}
                onChange={(event) => {
                  if (event.target.checked) {
                    setTuesday({ weekday: "terça" });
                  } else {
                    setTuesday({ weekday: "", start_time: "", end_time: "" });
                  }
                }}
              />

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={tuesday.start_time}
                  onChange={(event) =>
                    setTuesday({ ...tuesday, start_time: event.target.value })
                  }
                  disabled={!tuesday.weekday}
                  required
                />
              </div>
              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={tuesday.end_time}
                  onChange={(event) =>
                    setTuesday({ ...tuesday, end_time: event.target.value })
                  }
                  disabled={!tuesday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__button_duplicate tooltip">
                <button
                  type="button"
                  disabled={!tuesday.weekday}
                  onClick={() =>
                    duplicateTime(tuesday.start_time, tuesday.end_time)
                  }
                >
                  <BsFillReplyAllFill />
                </button>

                <span className="tooltiptext">
                  Replicar para os dias habilitados
                </span>
              </div>
            </div>

            <div className="daysAndTime__item">
              <InputCheckBox
                label="Quarta"
                checked={!!wednesday.weekday}
                onChange={(event) => {
                  if (event.target.checked) {
                    setWednesday({ weekday: "quarta" });
                  } else {
                    setWednesday({ weekday: "", start_time: "", end_time: "" });
                  }
                }}
              />

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={wednesday.start_time}
                  onChange={(event) =>
                    setWednesday({
                      ...wednesday,
                      start_time: event.target.value,
                    })
                  }
                  disabled={!wednesday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={wednesday.end_time}
                  onChange={(event) =>
                    setWednesday({ ...wednesday, end_time: event.target.value })
                  }
                  disabled={!wednesday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__button_duplicate tooltip">
                <button
                  type="button"
                  disabled={!wednesday.weekday}
                  onClick={() =>
                    duplicateTime(wednesday.start_time, wednesday.end_time)
                  }
                >
                  <BsFillReplyAllFill />
                </button>

                <span className="tooltiptext">
                  Replicar para os dias habilitados
                </span>
              </div>
            </div>

            <div className="daysAndTime__item">
              <InputCheckBox
                label="Quinta"
                checked={!!thursday.weekday}
                onChange={(event) => {
                  if (event.target.checked) {
                    setThursday({ weekday: "quinta" });
                  } else {
                    setThursday({ weekday: "", start_time: "", end_time: "" });
                  }
                }}
              />

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={thursday.start_time}
                  onChange={(event) =>
                    setThursday({
                      ...thursday,
                      start_time: event.target.value,
                    })
                  }
                  disabled={!thursday.weekday}
                  required
                />
              </div>
              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={thursday.end_time}
                  onChange={(event) =>
                    setThursday({ ...thursday, end_time: event.target.value })
                  }
                  disabled={!thursday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__button_duplicate tooltip">
                <button
                  type="button"
                  disabled={!thursday.weekday}
                  onClick={() =>
                    duplicateTime(thursday.start_time, thursday.end_time)
                  }
                >
                  <BsFillReplyAllFill />
                </button>

                <span className="tooltiptext">
                  Replicar para os dias habilitados
                </span>
              </div>
            </div>

            <div className="daysAndTime__item">
              <InputCheckBox
                label="Sexta"
                checked={!!friday.weekday}
                onChange={(event) => {
                  if (event.target.checked) {
                    setFriday({ weekday: "sexta" });
                  } else {
                    setFriday({ weekday: "", start_time: "", end_time: "" });
                  }
                }}
              />

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={friday.start_time}
                  onChange={(event) =>
                    setFriday({
                      ...friday,
                      start_time: event.target.value,
                    })
                  }
                  disabled={!friday.weekday}
                  required
                />
              </div>
              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={friday.end_time}
                  onChange={(event) =>
                    setFriday({ ...friday, end_time: event.target.value })
                  }
                  disabled={!friday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__button_duplicate tooltip">
                <button
                  type="button"
                  disabled={!friday.weekday}
                  onClick={() =>
                    duplicateTime(friday.start_time, friday.end_time)
                  }
                >
                  <BsFillReplyAllFill />
                </button>

                <span className="tooltiptext">
                  Replicar para os dias habilitados
                </span>
              </div>
            </div>

            <div className="daysAndTime__item">
              <InputCheckBox
                label="Sábado"
                checked={!!saturday.weekday}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSaturday({ weekday: "sábado" });
                  } else {
                    setSaturday({ weekday: "", start_time: "", end_time: "" });
                  }
                }}
              />

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={saturday.start_time}
                  onChange={(event) =>
                    setSaturday({
                      ...saturday,
                      start_time: event.target.value,
                    })
                  }
                  disabled={!saturday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={saturday.end_time}
                  onChange={(event) =>
                    setSaturday({ ...saturday, end_time: event.target.value })
                  }
                  disabled={!saturday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__button_duplicate tooltip">
                <button
                  type="button"
                  disabled={!saturday.weekday}
                  onClick={() =>
                    duplicateTime(saturday.start_time, saturday.end_time)
                  }
                >
                  <BsFillReplyAllFill />
                </button>

                <span className="tooltiptext">
                  Replicar para os dias habilitados
                </span>
              </div>
            </div>

            <div className="daysAndTime__item">
              <InputCheckBox
                label="Domingo"
                checked={!!sunday.weekday}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSunday({ weekday: "domingo" });
                  } else {
                    setSunday({ weekday: "", start_time: "", end_time: "" });
                  }
                }}
              />

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={sunday.start_time}
                  onChange={(event) =>
                    setSunday({
                      ...sunday,
                      start_time: event.target.value,
                    })
                  }
                  disabled={!sunday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={sunday.end_time}
                  onChange={(event) =>
                    setSunday({ ...sunday, end_time: event.target.value })
                  }
                  disabled={!sunday.weekday}
                  required
                />
              </div>

              <div className="daysAndTime__button_duplicate tooltip">
                <button
                  type="button"
                  disabled={!sunday.weekday}
                  onClick={() =>
                    duplicateTime(sunday.start_time, sunday.end_time)
                  }
                >
                  <BsFillReplyAllFill />
                </button>

                <span className="tooltiptext">
                  Replicar para os dias habilitados
                </span>
              </div>
            </div>

            <div className="daysAndTime__item">
              <span className="daysAndTime__launch">Horário de almoço:</span>

              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={lunchTime.start_lunch_time}
                  required
                  onChange={(event) =>
                    setLunchTime({
                      ...lunchTime,
                      start_lunch_time: event.target.value,
                    })
                  }
                />
              </div>
              <div className="daysAndTime__hour">
                <input
                  type="time"
                  value={lunchTime.end_lunch_time}
                  required
                  onChange={(event) =>
                    setLunchTime({
                      ...lunchTime,
                      end_lunch_time: event.target.value,
                    })
                  }
                />
              </div>

              <div className="daysAndTime__button_duplicate" />
            </div>
          </div>
          <Button
            type="submit"
            title="Salvar"
            outlined
            color={theme.colors.orangeDark}
          />
        </form>
      </Body>
    </Container>
  );
};

export default observer(DaysAndTimeScreen);
