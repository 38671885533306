import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { Content, Description, Image, Information } from "./styles";
import { useClientScreenStore } from "src/ui/pages/ClientScreen/store";
import { Button } from "src/ui/pages/ClientScreen/styles";
import SuccessImage from "src/assets/svgs/appointment-success.svg";
import { MdOutlineDone } from "react-icons/md";
import moment from "moment";

const ClientAppointmentCompleted = () => {
  const store = useLocalObservable(useClientScreenStore);
  const { company, appointment } = store;

  function handleSubmit() {
    window.location.reload();
  }

  return (
    <Content>
      <Description>
        <MdOutlineDone size={24} />
        <h1>Serviço agendado com sucesso!</h1>
      </Description>

      <Image>
        <img src={SuccessImage} alt="Agendamento realizado com sucesso" />
      </Image>

      <Information>
        <p>
          Serviço:{" "}
          <span>
            {appointment?.services?.map((service) => service.name).join(", ")}
          </span>
        </p>
        <p>
          Dia: <span>{moment(appointment?.date).format("DD/MM")}</span>
        </p>
        <p>
          Horário: <span>{appointment?.hour}</span>
        </p>

        {appointment?.employee && (
          <p>
            Profissional: <span>{appointment?.employee?.name}</span>
          </p>
        )}
      </Information>

      <Button
        primary={company?.primary_color}
        textColor={company?.font_color}
        onClick={handleSubmit}
      >
        Novo agendamento
      </Button>
    </Content>
  );
};

export default observer(ClientAppointmentCompleted);
