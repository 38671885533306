import client from "src/http/client";

export interface ICity {
  id: number;
  name: string;
  state_id: number;
}

export default class CitiesService {
  public async getCitiesByState(stateId: number): Promise<ICity[]> {
    const response = await client.get<ICity[]>(
      `/storefront/v1/cities?state_id=${stateId}`,
    );
    return response.data;
  }
}
