import styled from "styled-components";

export const IphoneX = styled.div`
  position: relative;
  margin: 20px 20px;
  @media (max-width: 400px) {
    margin: 5px 5px;
    box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 7px #191919,
      0px 0px 0px 5px #111;
  }
  width: 100%;
  background-color: #7371ee;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919,
    0px 0px 0px 20px #111;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
  }
  &:before {
    top: 0px;
    width: 56%;
    @media (max-width: 400px) {
      height: 15px;
    }
    height: 25px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
  }
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  @media (max-width: 400px) {
    width: 45px;
    height: 45px;
    margin: 5px;
  }
  border-radius: 8px;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.s14};
  color: ${(props) => props.color};
  text-align: center;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ContainerTitle = styled.p`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  margin-top: 8px;
  @media (max-width: 600px) {
    margin-top: 15px;
  }
  display: flex;
  align-items: center;
`;

export const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 35px;
  background: ${({ theme }) => theme.colors.greyLight};
`;

export const Icon = styled.img`
  width: 15px;
  height: 15px;
`;

export const Header = styled.header`
  width: 100%;
  height: 100px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    padding: 0 5px;
  }
  padding: 0 15px;
`;

export const Wrapper = styled.div`
  padding: 0 15px;
  width: 100%;
`;

export const CardLabel = styled.div`
  color: ${(props) => props.color};
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-bottom: 8px;
  margin-top: 15px;
`;

export const ServiceList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const SocialNetworkingList = styled.div`
  margin-top: 2em;
  margin-bottom: 0.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const SocialNetworking = styled.a`
  width: 38px;
  height: 38px;
  border-radius: 36px;
  background-color: ${(props) => props.color};
  margin: 7px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const Service = styled.button`
  flex: 1;
  height: 38px;
  cursor: pointer;
  text-align: center;
  border: 1px solid ${(props) => props.color};
  border-radius: 5px;
  padding: 8px 15px;
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  background-color: ${(props) => props.color};
  color: ${(props) => props.defaultValue};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin: 7px;
`;

export const TextFooter = styled.p`
  color: ${(props) => props.color};
  text-align: center;
  @media (max-width: 400px) {
    margin-bottom: 8px;
  }
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Footer = styled.footer`
  width: 100%;
  @media (max-width: 400px) {
    height: 65px;
  }
  height: 45px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  background: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;
