import React from "react";

import { LinkTag } from "./styles";

interface ILink {
  label: string;
  url: string;
  target?: string;
  rel?: string;
  download?: string;
  hreflang?: string;
  media?: string;
  ping?: any;
  referrerpolicy?: string;
  type?: string;
}

export const Link: React.FC<ILink> = (props) => {
  const { label, url, target, rel, download, media, ping, type } = props;
  return (
    <LinkTag
      target={target}
      href={url}
      rel={rel}
      download={download}
      media={media}
      ping={ping}
      type={type}
    >
      {label}
    </LinkTag>
  );
};
