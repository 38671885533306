import * as H from "history";
import { useLocalStore } from "mobx-react";
import React, { useEffect } from "react";

import { useGlobalStore } from "src/context/global";
import { IOrder } from "src/http/api";
import theme from "src/theme";
import { ModalHeader, ShareButton } from "src/ui/components";

import Store from "../../store";
import { Container } from "./styles";

interface IProps {
  onGoBack: () => void;
  order?: IOrder;
  closeModal: () => void;
  history: H.History<unknown>;
}

const Pix: React.FC<IProps> = (props) => {
  const { order, closeModal, history } = props;

  const { loadingStore, authStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore, authStore));

  const onPressConfirm = () => {
    closeModal();
    history.push("/");
  };

  const clearReleasePayment = () => {
    clearInterval(releasePayment);
  };

  let releasePayment;

  useEffect(() => {
    releasePayment = setInterval(() => {
      store.releaseAfterPayment(order).then((res) => {
        if (res.invoice_status === "paid") {
          clearReleasePayment();
          onPressConfirm();
        }
      });
    }, 30000);
  }, []);

  return (
    <Container>
      <ModalHeader title="Pagar com PIX" onGoBack={closeModal} noBack />
      {order && (
        <>
          <div className="code-wrapper">
            <img src={order.qrcode} />
            <div className="share-button-wrapper">
              <ShareButton
                title="Copiar código do QR Code"
                copiedAlertText="O código do QR Code foi copiado!"
                textToCopy={order.qrcode_text}
                color={theme.colors.purpleDark}
              />
            </div>
          </div>
          {/* <Button
            title="Finalizar"
            color={theme.colors.purpleDark}
            onClick={onPressConfirm}
          /> */}
        </>
      )}
    </Container>
  );
};

export default Pix;
