import { action, makeObservable, observable } from "mobx";
import Swal from "sweetalert2";

import CouponsService, { ICoupon, newCoupon } from "src/services/coupons";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private couponsService = new CouponsService();
  public loader: LoadingStore;
  public formController = new FormControllerStore({
    name: "",
    date_start: "",
    date_end: "",
    type_coupon: "",
    reuse: "",
    value: "",
  });

  public couponList: ICoupon[] = [];
  public coupon: ICoupon | null = null;

  constructor(loader: LoadingStore) {
    super();
    makeObservable(this, {
      couponList: observable,
      coupon: observable,
      getCoupons: action,
      getCouponById: action,
      updateCoupon: action,
      createCoupon: action,
    });
    this.loader = loader;
  }

  public async getCouponById(coupon: number) {
    try {
      this.loader.start();
      this.coupon = await this.couponsService.getCouponById(coupon);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getCoupons() {
    try {
      this.loader.start();
      this.couponList = await this.couponsService.getCoupons();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async updateCoupon(
    onSuccess: CallableFunction,
    couponId: number,
    oldCoupon: ICoupon | null,
  ) {
    if (!oldCoupon) {
      return;
    }

    try {
      this.loader.start();

      const { name, date_start, date_end, reuse, value, type_coupon } =
        this.formController.getFields();
      const coupon: ICoupon = {
        name,
        starts_at: date_start,
        ends_at: date_end,
        reuse: reuse === "1",
        value,
        type_coupon: parseInt(type_coupon),
        id: oldCoupon?.id,
        user_id: oldCoupon?.user_id,
        created_at: oldCoupon?.created_at,
        updated_at: oldCoupon?.updated_at,
      };

      await this.couponsService.updateCoupon(coupon);

      Swal.fire({
        icon: "success",
        title: "Muito Bem!",
        text: "Cupom de desconto atualizado com sucesso!",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        onSuccess();
        this.loader.end();
        window.location.reload();
      });
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public createCoupon(onSuccess: CallableFunction) {
    try {
      this.loader.start();
      const { name, date_start, date_end, reuse, value, type_coupon } =
        this.formController.getFields();

      const coupon: newCoupon = {
        name,
        starts_at: date_start,
        ends_at: date_end,
        reuse: reuse === "1",
        value,
        type_coupon: parseInt(type_coupon),
      };

      this.couponsService.createCoupon(coupon);

      Swal.fire({
        icon: "success",
        title: "Muito Bem!",
        text: "Cupom de desconto cadastrado com sucesso!",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        onSuccess();
        this.loader.end();
        window.location.reload();
      });
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async removeCoupon(coupon: number) {
    try {
      this.loader.start();
      const result = await this.couponsService.deleteCoupon(coupon);

      if (result) {
        Swal.fire({
          icon: "success",
          title: "Muito bem!",
          text: "Cupom excluido com sucesso",
        }).then(() => {
          this.loader.start();
          window.location.reload();
        });
      }
    } catch (e) {
      this.showError(
        "Houve um erro ao tentar excluir. Por favor entre em contato com suporte!",
      );
    } finally {
      this.loader.end();
    }
  }
}
