import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";

import theme from "../../../theme";
import { Container } from "./styles";

interface ModalHeaderProps {
  onGoBack: () => void;
  title: string;
  backButtonColor?: string;
  noBack?: boolean;
}

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { onGoBack, title, backButtonColor, noBack } = props;
  return (
    <Container>
      <button>
        {!noBack && (
          <AiOutlineArrowLeft
            color={backButtonColor ? backButtonColor : theme.colors.purpleDark}
            size={27}
            onClick={onGoBack}
          />
        )}
      </button>
      <h1>{title}</h1>
      <button />
    </Container>
  );
};
