import styled from "styled-components";

export const Container = styled.div`
  > div {
    > img {
      margin: auto;
      display: block;
    }
    > h1 {
      font-size: ${({ theme }) => theme.fontSizes.s32}px;
      color: ${({ theme }) => theme.colors.greyDarker};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      text-align: center;
      margin-bottom: 16px;
    }
    > span {
      font-size: ${({ theme }) => theme.fontSizes.s16}px;
      color: ${({ theme }) => theme.colors.greyDarker};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      text-align: center;
      margin-bottom: 16px;
      display: block;
    }
  }

  @media (min-width: 992px) {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 40px;
    max-width: 550px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    > div {
      > img {
        height: 126px;
        margin: 0 auto 47px auto;
      }
      > h1 {
        margin-bottom: 34px;
      }
      > span {
        font-size: ${({ theme }) => theme.fontSizes.s24}px;
        margin-bottom: 38px;
      }
    }
  }
`;
