import React from "react";
import { BsInfo, BsCheck, BsExclamation } from "react-icons/bs";

import { Container } from "./styles";

export enum AlertType {
  Success = "success",
  Info = "info",
  Warning = "warning",
}

interface AlertProps {
  type: AlertType;
  title?: string;
  message?: string;
}

export const Alert: React.FC<AlertProps> = (props) => {
  const { type, title, message, children } = props;

  const alerts = {
    success: {
      icon: <BsCheck />,
      color: "#8ac926",
    },
    info: {
      icon: <BsInfo />,
      color: "#3a86ff",
    },
    warning: {
      icon: <BsExclamation />,
      color: "#ffb703",
    },
  };

  return (
    <Container color={alerts[type].color}>
      <div className="icon-wrapper">{alerts[type].icon}</div>
      <div className="message-wrapper">
        {children ? (
          children
        ) : (
          <div>
            <b>{title}</b>
            <p>{message}</p>
          </div>
        )}
      </div>
    </Container>
  );
};
