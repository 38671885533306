import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";

import arrowLeft from "src/assets/svgs/arrowLeft.svg";
import { useGlobalStore } from "src/context/global";
import masks from "src/resources/masks";
import theme from "src/theme";
import { Input, InputDate, Select, Button } from "src/ui/components";

import Store from "../../store";
import { Container, Header } from "./styles";

interface CreateCouponScreen {
  closeModal: () => void;
}

const CreateCouponScreen: React.FC<CreateCouponScreen> = (props) => {
  const { closeModal } = props;
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  const CouponTypes = [
    { value: 1, name: "Valor Real" },
    { value: 2, name: "Porcentagem" },
  ];

  const CouponUses = [
    { value: 1, name: "Sim" },
    { value: 0, name: "Não" },
  ];

  const onSuccess = () => {
    closeModal();
  };

  const changeCase = (e) => {
    let upperCoupon = store.formController.getField("name").value;
    upperCoupon = upperCoupon.toUpperCase();
    upperCoupon = upperCoupon.replace(/\s/g, "");
    upperCoupon = upperCoupon.replace(/[^a-zA-Z0-9]/g, "");

    store.formController.setField("name", upperCoupon);
  };

  useEffect(() => {}, []);

  return (
    <Container>
      <Header>
        <button className="go-back" onClick={closeModal}>
          <img src={arrowLeft} />
        </button>
        <h1>Criar novo cupom</h1>
      </Header>
      <Input
        label="Cupom"
        required
        {...store.formController.getField("name")}
        onKeyUp={changeCase}
        onBlur={changeCase}
      />
      <InputDate
        label="Data de Inicio"
        required
        {...store.formController.getField("date_start")}
      />
      <InputDate
        label="Data de Fim"
        required
        {...store.formController.getField("date_end")}
      />
      <Select
        label="Tipo"
        required
        options={CouponTypes}
        {...store.formController.getField("type_coupon")}
      />
      <Input
        label="Valor"
        required
        {...store.formController.getField("value")}
        onChange={(e) =>
          store.formController
            .getField("value")
            .onChange(e, masks.percentageOrCurrency)
        }
        maxLength={5}
        placeholder="00.00"
      />
      <Select
        label="Pode ser usado mais de uma vez?"
        options={CouponUses}
        {...store.formController.getField("reuse")}
      />
      <Button
        title="Criar Cupom"
        color={theme.colors.orangeDark}
        onClick={() => store.createCoupon(onSuccess)}
      />
    </Container>
  );
};

export default observer(CreateCouponScreen);
