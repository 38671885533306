import styled from "styled-components";

export const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  > thead {
    > tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.greyLighter};
      > td {
        font-size: ${({ theme }) => theme.fontSizes.s18}px;
        color: ${({ theme }) => theme.colors.greyDarker};
        font-weight: bold;
        padding: 16px 0;
      }
    }
  }
  > tbody {
    > tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.greyLighter};
      > td {
        padding: 16px 0;
        font-size: ${({ theme }) => theme.fontSizes.s18}px;
        color: ${({ theme }) => theme.colors.greyDarker};
        > label {
          display: inline !important;
        }
      }
    }
  }
`;
