import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";

import { useGlobalStore } from "src/context/global";
import { Card, CardInner } from "src/ui/components/index";

import Store from "./store";
import { Container } from "./styles";

const UserProfile: React.FC = () => {
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  useEffect(() => {
    store.getUserProfile();
  }, []);

  return (
    <Container>
      <Card>
        <CardInner>
          <div className="resume">
            <span className="resume__header">Resumo</span>

            <div className="resume__item">
              <span className="resume__title">Data de cadastro</span>
              <span className="resume__value">
                {new Date(
                  store.userProfile.created_at || "",
                ).toLocaleDateString("pt-br")}
              </span>
            </div>

            <div className="resume__item">
              <span className="resume__title">Email</span>
              <span className="resume__value">{store.userProfile.email}</span>
            </div>

            <div className="resume__item">
              <span className="resume__title">Telefone</span>
              <span className="resume__value">{store.userProfile.phone}</span>
            </div>
          </div>
        </CardInner>
      </Card>

      <Card>
        <CardInner>
          <div className="financial">
            <span className="financial__header">Financeiro</span>

            <div className="financial__item">
              <span className="financial__title">Plano contratado</span>
              <span className="financial__value">
                {store.userProfile.interval_type === "months" ? "Mês" : "Anual"}
              </span>
            </div>

            <div className="financial__item">
              <span className="financial__title">Forma de pagamento</span>
              <span className="financial__value">
                {store.userProfile.payable_with}
              </span>
            </div>

            <div className="financial__item">
              <span className="financial__title">Data de expiração</span>
              <span className="financial__value">
                {new Date(
                  store.userProfile.expiration_date || "",
                ).toLocaleString("pt-br")}
              </span>
            </div>

            <div className="financial__item">
              <span className="financial__title">Cobrança recorrente</span>
              <span className="financial__value">
                {store.userProfile.recurring_charge ? "Sim" : "Não"}
              </span>
            </div>
          </div>
        </CardInner>
      </Card>
    </Container>
  );
};

export default observer(UserProfile);
