/* eslint-disable @typescript-eslint/no-unsafe-return */
import { action, makeObservable, observable } from "mobx";

import api, { IUser } from "src/http/api";
import client from "src/http/client";

import FormControllerStore from "./FormControllerStore";
import LoadingStore from "./LoadingStore";
import UIStore from "./UIStore";

export default class AuthStore extends UIStore {
  private loader: LoadingStore;

  public formController = new FormControllerStore({
    email: "",
    password: "",
  });

  public currentUser: IUser | null = null;

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      currentUser: observable,
      login: action,
      setCurrentUser: action,
      logout: action,
      getCurrentUser: action,
    });
  }

  public login = async (onSuccess: () => void) => {
    try {
      this.loader.start();
      const { email, password } = this.formController.getFields();
      const currentUser = await api.login(email.trim(), password.trim());
      this.setCurrentUser(currentUser);
      this.showSuccess("Seja bem-vind@!");
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public loginRegister = async (email, password) => {
    try {
      this.loader.start();
      const currentUser = await api.login(email.trim(), password.trim());
      this.setCurrentUser(currentUser);
      this.showSuccess("Seja bem-vind@!");
      window.location.replace("/");
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public getCurrentUser = (): any => {
    const currentUserString = localStorage.getItem("user");

    const currentUser = currentUserString
      ? JSON.parse(currentUserString)
      : null;
    if (currentUser) {
      this.setCurrentUser(currentUser);
    }

    return currentUser;
  };

  public setAxiosHeadersForAuthenticatedUsers = (user: IUser): void => {
    client.defaults.headers["access-token"] = user.auth_params.access_token;
    client.defaults.headers.client = user.auth_params.client;
    client.defaults.headers.uid = user.auth_params.uid;
  };

  public removeAxiosAuthenticationHeaders = () => {
    delete client.defaults.headers["access-token"];
    delete client.defaults.headers.client;
    delete client.defaults.headers.uid;
  };

  public setCurrentUser = (user: IUser) => {
    this.currentUser = user;
    localStorage.setItem("user", JSON.stringify(this.currentUser));
    this.setAxiosHeadersForAuthenticatedUsers(user);
  };

  public logout = (onLogout: CallableFunction) => {
    this.currentUser = null;
    localStorage.removeItem("user");
    localStorage.removeItem("loggedInAs");
    this.removeAxiosAuthenticationHeaders();
    onLogout();
  };
}

export const authStore = new AuthStore(new LoadingStore());
