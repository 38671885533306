import styled from "styled-components";

interface ThemeProps {
  active?: boolean;
  primary?: string;
  textColor?: string;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong,
  b,
  i,
  a,
  button,
  input,
  label,
  select,
  option,
  textarea {
    font-family: "Inter", sans-serif !important;
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  height: 56px;
  width: 100%;
  padding: 8px 16px;
  background-color: #343a40;
  margin-bottom: 24px;

  img {
    width: 145px;
  }
`;

export const Wrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 0 16px 32px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 500;
`;

export const Button = styled.button<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 42px;
  border-radius: 8px;
  background-color: ${({ primary }) => primary};
  color: ${({ textColor }) => textColor};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin: 32px 0 0;

  &:disabled {
    background-color: #ccc; // change to the color you want for disabled buttons
    color: #888; // change to the color you want for the text of disabled buttons
    cursor: not-allowed;
  }
`;
