import React from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import ServicesService, { IService } from "src/services/services";
import { CompanyTheme } from "src/types/company.interface";
import { Title } from "src/ui/pages/ClientScreen/styles";
import {
  ServiceItem,
  ServiceList,
  Services,
} from "src/ui/pages/ClientScreen/templates/ClientAppointment/styles";

interface Props {
  companyId: string;
  refId?: number;
  servicesSelecteds: IService[];
  theme: CompanyTheme;
  onSelectService: (service: IService) => void;
}

const AppointmentServices: React.FC<Props> = ({
  companyId,
  refId,
  servicesSelecteds,
  theme,
  onSelectService,
}) => {
  const { data: services, isLoading } = useQuery(
    ["services", companyId, refId],
    () => new ServicesService().getCompanyServices(companyId, refId),
  );

  return (
    <Services>
      <Title>Selecione um serviço para agendar:</Title>

      <ServiceList>
        {isLoading && (
          <>
            <Skeleton width={134} />
            <Skeleton width={134} />
          </>
        )}

        {!isLoading &&
          services?.map((service) => (
            <ServiceItem
              key={service.id}
              active={servicesSelecteds
                ?.map((item) => item.id)
                .includes(service.id)}
              primary={theme?.primary}
              textColor={theme?.color}
              onClick={() => onSelectService(service)}
            >
              {service.name}
            </ServiceItem>
          ))}
      </ServiceList>
    </Services>
  );
};

export default AppointmentServices;
