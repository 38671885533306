/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from "react";
import { useLocalStore } from "mobx-react";
import { FiChevronRight, FiSettings } from "react-icons/fi";
import { HiChevronLeft, HiOutlineCalendar } from "react-icons/hi";
import { MdPlayCircleFilled, MdPauseCircleFilled } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { useGlobalStore } from "src/context/global";
import { Link, useHistory } from "react-router-dom";
import { useModal } from "src/context/modal";
import { IEmployees } from "src/http/interfaces/IEmployees";
import ScreenContainer from "src/layout/ScreenContainer";
import theme from "src/theme";
import { Button, Card, CardLabel, Table } from "src/ui/components";
import api, { IUser } from "../../../http/api";
import { CardProfileForm } from "../CustomizeScreen/styles";
import RegisterEmployee from "../RegisterEmployee";
import { CardsRow } from "../ScheduleScreen/styles";
import DaysAndTime from "./components/DaysAndTime";
import Preferences from "./components/Preferences";
import ServicesProvided from "./components/ServicesProvided";
// import Users from "./components/Users";
import { Container, MenuItem } from "./styles";
import client from "src/http/client";
import Store from "./store";

const ConfigurationScreen: React.FC = () => {
  const [activatedMenu, setActivatedMenu] = useState("Dias e horarios");
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));
  const [employees, setEmployees] = useState<IEmployees[]>([]);
  const [reload, setReload] = useState(0);
  const [loggedInAs, setLoggedInAs] = useState(
    JSON.parse(localStorage.getItem("loggedInAs")!),
  );
  const { showModal, closeModal } = useModal();
  const history = useHistory();
  const [plansButton, setPlansButton] = useState(false);

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;
  const aux = JSON.parse(localStorage.getItem("loggedInAs")!) as string;

  function handleActiveMenu(menu) {
    setActivatedMenu(menu);
  }

  async function changeStatusEmployee(employeeId) {
    store.loader.start();
    try {
      const result1 = await api.changeStatusEmployee(employeeId);
    } catch (error) {
      if (error.response.status === 422) {
        store.showError(
          "Você não pode alterar o status de seus funcionários, por favor mude seu plano.",
        );
        history.push("/planos");
        console.log(error);
      }
    }
    store.loader.end();
    setReload(Math.random());
  }

  const handleLogIn = (userId?: number) => {
    if (userId) {
      localStorage.setItem("loggedInAs", JSON.stringify(userId));
      client.defaults.headers["IMPERSONATE_USER"] = userId;
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (user.profile === "hall" && !loggedInAs) {
      store.loader.start();
      api
        .newGetEmployees(`${user.id}`)
        .then((response) => {
          setEmployees(response);
          store.loader.end();
        })
        .catch((e) => {
          store.loader.end();
        });
    }
  }, [reload]);

  return (
    <ScreenContainer>
      <Container>
        {user.profile === "hall" && !aux && loggedInAs && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.2rem",
              color: theme.colors.purpleDark,
              cursor: "pointer",
            }}
            onClick={() => {
              setLoggedInAs("");
            }}
          >
            <HiChevronLeft size={20} />
            Voltar
          </div>
        )}
        <CardLabel>Cadastrar e configurar</CardLabel>
        {user.profile === "hall" && !loggedInAs ? (
          <>
            <Card>
              <CardProfileForm>
                <Table>
                  <tr>
                    <th align="left">Profissional</th>
                    <th align="left">Status</th>
                    <th />
                  </tr>
                  {employees.map((employee) => (
                    <tr key={employee.id}>
                      <td style={{ paddingTop: "20px" }}>{employee.name}</td>
                      <td style={{ paddingTop: "20px" }}>
                        {employee.status === "active" ? "Ativo" : "Inativo"}
                        {employee.status !== "active" ? (
                          <MdPlayCircleFilled
                            title="Ativar"
                            onClick={() => {
                              changeStatusEmployee(employee.id);
                            }}
                            style={{
                              fontSize: 24,
                              position: "relative",
                              top: 7,
                              left: 3,
                              color: "green",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <MdPauseCircleFilled
                            title="Inativar"
                            onClick={() => {
                              changeStatusEmployee(employee.id);
                            }}
                            style={{
                              fontSize: 24,
                              position: "relative",
                              top: 7,
                              left: 3,
                              color: "red",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        <button
                          onClick={() => {
                            setLoggedInAs(String(employee.id));
                          }}
                        >
                          <FiChevronRight color={theme.colors.orangeDark} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </Table>
              </CardProfileForm>
            </Card>

            <Button
              title="Adicionar novo"
              color={theme.colors.orangeDark}
              style={{ margin: "60px auto 0" }}
              onClick={() =>
                showModal({
                  content: (
                    <RegisterEmployee
                      closeModal={closeModal}
                      reloadHomeData={() => setReload(Math.random())}
                      onFailure={() => setPlansButton(true)}
                    />
                  ),
                  onClickOut: closeModal,
                })
              }
            />

            {plansButton && (
              <Link
                color={theme.colors.orangeDark}
                style={{
                  margin: "20px 0",
                  padding: "15px",
                  backgroundColor: theme.colors.purpleDark,
                  width: "100%",
                  textAlign: "center",
                  color: "#Fff",
                  fontWeight: "bold",
                  borderRadius: "8px",
                }}
                to="/planos"
              >
                Alterar plano
              </Link>
            )}
          </>
        ) : (
          <>
            <CardsRow style={{ gap: 15, marginBottom: 40 }}>
              <Card>
                <MenuItem
                  isActive={activatedMenu === "Dias e horarios"}
                  onClick={() => handleActiveMenu("Dias e horarios")}
                >
                  <span className="title">Dias e horários</span>
                  <div className="icon">
                    <HiOutlineCalendar />
                  </div>
                </MenuItem>
              </Card>

              <Card>
                <MenuItem
                  isActive={activatedMenu === "Serviços prestados"}
                  onClick={() => handleActiveMenu("Serviços prestados")}
                >
                  <span className="title">Serviços prestados</span>
                  <div className="icon">
                    <RiUserSettingsLine />
                  </div>
                </MenuItem>
              </Card>

              <Card>
                <MenuItem
                  isActive={activatedMenu === "Preferências"}
                  onClick={() => handleActiveMenu("Preferências")}
                >
                  <span className="title">Preferências</span>
                  <div className="icon">
                    <FiSettings />
                  </div>
                </MenuItem>
              </Card>
              {user.profile === "hall" && (
                <Card>
                  <MenuItem
                    isActive={activatedMenu === "Acessar"}
                    onClick={() => handleLogIn(Number(loggedInAs))}
                  >
                    <span className="title">Acessar</span>
                    <div className="icon">
                      <FiChevronRight />
                    </div>
                  </MenuItem>
                </Card>
              )}
            </CardsRow>
            {(function () {
              if (activatedMenu === "Dias e horarios") {
                return (
                  <DaysAndTime
                    employee={
                      user.profile === "hall" ? loggedInAs : String(user.id)
                    }
                  />
                );
              } else if (activatedMenu === "Serviços prestados") {
                return (
                  <ServicesProvided
                    employee={
                      user.profile === "hall" ? loggedInAs : String(user.id)
                    }
                  />
                );
              } else if (activatedMenu === "Preferências") {
                return (
                  <Preferences
                    loggedInAs={
                      user.profile === "hall" ? loggedInAs : String(user.id)
                    }
                  />
                );
              } else {
                return null;
              }
            })()}
          </>
        )}
      </Container>
    </ScreenContainer>
  );
};
export default ConfigurationScreen;
