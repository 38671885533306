import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 34px;
  > button {
    width: 50px;
    text-align: left;
    background: transparent;
    border: none;
  }
  > h1 {
    flex: 1;
    font-size: ${({ theme }) => theme.fontSizes.s24}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.greyDarker};
    text-align: center;
  }
`;
