import styled, { css } from "styled-components";

interface ThemeProps {
  active?: boolean;
  primary?: string;
  textColor?: string;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 32px;
`;

export const HoursList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px 12px;
`;

export const WithoutHour = styled.p`
  font-size: 12px;
`;

export const HourItem = styled.p<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  margin-top: 8px;
  cursor: pointer;
  width: 100%;

  line-height: 150%;
  font-size: 12px;

  ${({ active, primary, textColor }) =>
    active
      ? css`
          background-color: ${primary};
          color: ${textColor};
        `
      : css`
          border: solid 1px ${primary};
          color: ${primary};
        `}
`;
