import { observer, useLocalObservable } from "mobx-react";
import React, { useState } from "react";
import { IEmployees } from "src/http/interfaces/IEmployees";
import { IService } from "src/services/services";
import { useClientScreenStore } from "src/ui/pages/ClientScreen/store";
import { Content } from "./styles";

import { HiArrowRight } from "react-icons/hi2";
import { Appointment } from "src/types/appointment.interface";
import { Button } from "src/ui/pages/ClientScreen/styles";
import AppointmentServices from "src/ui/pages/ClientScreen/components/AppointmentServices";
import AppointmentEmployees from "src/ui/pages/ClientScreen/components/AppointmentEmployees";
import { CompanyTheme } from "src/types/company.interface";
import AppointmentCalendar from "src/ui/pages/ClientScreen/components/AppointmentCalendar";
import AppointmentHour from "src/ui/pages/ClientScreen/components/AppointmentHour";

interface Props {
  onSubmit: (a: Appointment) => void;
}

const ClientAppointment: React.FC<Props> = ({ onSubmit }) => {
  const store = useLocalObservable(useClientScreenStore);
  const { company, appointmentsOfMonth } = store;

  const theme: CompanyTheme = {
    primary: company?.primary_color,
    secondary: company?.secondary_color,
    color: company?.font_color,
  };

  const [employeeSelected, setEmployeeSelected] = useState<IEmployees>();
  const [serviceSelected, setServiceSelected] = useState<IService[]>([]);
  const [daySelected, setDaySelected] = useState<Date>();
  const [hourSelected, setHourSelected] = useState("");

  function handleSubmit() {
    if (!daySelected || !hourSelected || !serviceSelected) return;

    onSubmit({
      date: daySelected,
      hour: hourSelected,
      services: serviceSelected,
      employee: employeeSelected,
    });
  }

  const handleSelectEmployee = (employee: IEmployees) => {
    setServiceSelected([]);
    setEmployeeSelected(employee);
  };

  function handleSelectService(service: IService) {
    if (serviceSelected.includes(service)) {
      setServiceSelected(
        serviceSelected.filter((item) => item.id !== service.id),
      );
    } else {
      setServiceSelected([...serviceSelected, service]);
    }
  }

  if (!company) return <div>Carregando...</div>;

  const showServices = Boolean(
    (company.profile === "hall" && employeeSelected) ||
      company.profile === "provider",
  );

  const hasEmployee = company.profile === "hall";

  return (
    <>
      <Content>
        {hasEmployee && (
          <AppointmentEmployees
            companyId={company.user_id}
            theme={theme}
            employeeSelected={employeeSelected}
            onSelectEmployee={handleSelectEmployee}
          />
        )}

        {showServices && (
          <AppointmentServices
            companyId={company.user_id}
            refId={employeeSelected?.id}
            theme={theme}
            servicesSelecteds={serviceSelected}
            onSelectService={handleSelectService}
          />
        )}

        {serviceSelected && serviceSelected.length > 0 && (
          <AppointmentCalendar
            daySelected={daySelected}
            employeeId={
              hasEmployee ? String(employeeSelected?.id) : company?.user_id
            }
            appointmentsOfMonth={appointmentsOfMonth}
            theme={theme}
            onSelectDay={setDaySelected}
          />
        )}

        {daySelected && serviceSelected.length > 0 && (
          <AppointmentHour
            daySelected={daySelected}
            servicesIds={serviceSelected.map((service) => service.id)}
            refId={
              hasEmployee ? String(employeeSelected?.id) : company?.user_id
            }
            hourSelected={hourSelected}
            theme={theme}
            onSelectHour={setHourSelected}
          />
        )}

        <Button
          disabled={!hourSelected}
          primary={company.primary_color}
          textColor={company.font_color}
          onClick={handleSubmit}
        >
          <p>Próximo</p>
          <HiArrowRight />
        </Button>
      </Content>
    </>
  );
};

export default observer(ClientAppointment);
