import styled from "styled-components";

export const Container = styled.div`
  > span.card-label {
    color: ${(props) => props.color};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    margin-bottom: 8px;
    margin-top: 42px;
    display: block;
  }

  > div.card {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
