import styled from "styled-components";

export const ScreenContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.form`
  width: 100vw;
  height: 100%;
  flex: 1;
  background: #f2f2f2;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 992px) {
    flex-direction: row;
    padding: 0;
  }
  > .logo-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    > img {
      height: 80px;
    }
    @media (min-width: 992px) {
      order: 1;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      min-width: 50vw;
      > img {
        height: 118px;
      }
    }
  }
  > .form-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: #fff;
    padding: 20px;
    @media (min-width: 992px) {
      order: 0;
      border-radius: 0;
      justify-content: center;
      padding: 0 60px;
      min-width: 50vw;
    }
    > h1 {
      font-size: ${({ theme }) => theme.fontSizes.s24}px;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.greyDarker};
      margin-bottom: 22px;
    }
    > .forgot-password {
      font-size: ${({ theme }) => theme.fontSizes.s16}px;
      color: ${({ theme }) => theme.colors.orange};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      text-align: right;
      display: block;
      margin-bottom: 30px;
    }
  }
`;
