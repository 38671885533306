import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    margin-bottom: 5px;
  }
`;

interface MenuItemProps {
  isActive: boolean;
}

export const MenuItem = styled.button<MenuItemProps>`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme, isActive }) => isActive && theme.colors.orangeDark};
  > .title {
    display: flex;
    font-size: ${({ theme }) => `${theme.fontSizes.s12}px`};
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.white : theme.colors.greyDarker};
    margin-top: 5px;
    justify-content: center;
    @media (min-width: 900px) {
      font-size: ${({ theme }) => `${theme.fontSizes.s18}px`};
    }
  }

  > .icon {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    > svg {
      color: ${({ theme, isActive }) =>
        isActive ? theme.colors.white : theme.colors.orangeDark};
      stroke: ${({ theme, isActive }) =>
        isActive ? theme.colors.white : theme.colors.orangeDark};
      width: 50px;
      height: 52px;

      @media (min-width: 900px) {
        width: 102px;
        height: 111px;
      }
    }
  }
`;
