import styled from "styled-components";

export const Icons = styled.div`
  float: right;
  width: 100px;
  display: flex;
  justify-content: space-evenly;
`;

export const EditableButton = styled.button`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.orangeDark};

  svg {
    font-size: 25px;
  }
`;
export const DeleteButton = styled.button`
  color: ${({ theme }) => theme.colors.redDark};

  svg {
    font-size: 25px;
  }
`;

export const WhatsAppIcon = styled.a`
  color: green;
  margin-left: 15px;
  border-bottom: 1px solid gray;
`;
