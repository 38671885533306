import React from "react";

import { Container } from "./styles";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  outlined?: boolean;
  color: string;
  mb?: number;
  style?: React.CSSProperties;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { title, outlined, color, mb, style } = props;
  return (
    <Container
      {...props}
      color={color}
      outlined={outlined}
      mb={mb}
      style={style}
    >
      {title}
    </Container>
  );
};
