export const categoryEnum = {
  Despesas: "expenses",
  Impostos: "taxes",
  Receita: "revenue",
  Salário: "salary",
  Outros: "others",
};

export const categoryList = (finacialType) => {
  if (finacialType === "incoming") {
    return [
      { value: "revenue", name: "Receita" },
      { value: "others", name: "Outros" },
    ];
  }

  return [
    { value: "taxes", name: "Impostos" },
    { value: "expenses", name: "Despesas" },
    { value: "salary", name: "Salário" },
    { value: "others", name: "Outros" },
  ];
};
