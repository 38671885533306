import styled from "styled-components";

export const Container = styled.div`
  color: ${(props) => props.color};
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-bottom: 8px;
  margin-top: 42px;
  display: block;
`;
