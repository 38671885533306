import { action, makeObservable, observable } from "mobx";

export default class LoadingStore {
  public isLoading = false;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      start: action,
      end: action,
    });
  }

  public start = () => {
    this.isLoading = true;
  };

  public end = () => {
    this.isLoading = false;
  };
}
