import React from "react";
import Lottie from "react-lottie";

import ErrorAnimation from "src/assets/lotties/error.json";

import { Container } from "./styles";

interface IError {
  title: string;
  description?: string;
}
export const Error: React.FC<IError> = (props) => {
  const { title, description } = props;
  return (
    <Container>
      <Lottie
        options={{
          animationData: ErrorAnimation,
          autoplay: true,
          loop: true,
        }}
        width={200}
      />
      <h1>{title}</h1>
      <p>{description}</p>
    </Container>
  );
};
