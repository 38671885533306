import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import Carousel from "react-elastic-carousel";
import { useHistory, useLocation } from "react-router";

import ScreenContainer from "src/layout/ScreenContainer";

import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { IPlan, IUser } from "src/http/api";
import { Alert, AlertType } from "src/ui/components";

import PaymentForm from "../PaymentForm";
import PlanUpdate from "../PlanUpdate";
import Plan from "./components/Plan";
import Store from "./store";
import { Container } from "./styles";

const PlansScreen: React.FC = () => {
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  const location = useLocation<{ isAccountBlocked?: boolean }>();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { showModal, closeModal } = useModal();

  useEffect(() => {
    store.getPlans(String(user.id));
  }, []);

  const onPressSubscribe = (plan: IPlan) => {
    showModal({
      content: (
        <PaymentForm plan={plan} closeModal={closeModal} history={history} />
      ),
      onClickOut: closeModal,
      position: "right",
    });
  };

  const onPressUpdate = (plan: IPlan) => {
    showModal({
      content: (
        <PlanUpdate
          plan={plan}
          closeModal={closeModal}
          history={history}
          subscription={store.invoicesAndSubscriptions?.subscription}
        />
      ),
      onClickOut: closeModal,
      position: "right",
    });
  };

  const PlansWrapper = ({ children }) =>
    width < 670 ? (
      <Carousel isRTL={false} showArrows={false}>
        {children}
      </Carousel>
    ) : (
      <Carousel isRTL={false} showArrows>
        {children}
      </Carousel>
    );

  return (
    <ScreenContainer accountBlocked={location.state?.isAccountBlocked}>
      <Container>
        {store.freeTrial &&
          store.freeTrialDays === 0 &&
          user.profile !== "employee" && (
            <Alert
              type={AlertType.Warning}
              title="Ops!"
              message="O seu periodo de teste acabou, contrate um de nossos planos para poder continuar utilizando o Facilite Link!"
            />
          )}
        <div className="plans-container">
          <PlansWrapper>
            {store.plans[0] &&
              store.plans.map((plan) => (
                <Plan
                  key={plan.id}
                  title={plan.name}
                  description={plan.description}
                  valueByMonthWitDiscount={plan.value_by_month_with_discount || plan.value_cents}
                  valueByMonthWithoutDiscount={
                    plan.value_by_month_without_discount || plan.value_cents
                  }
                  image={plan.image}
                  chargeType={plan.billing_explanation}
                  highlight
                  identifier={
                    store.invoicesAndSubscriptions?.plan_identifier ===
                    plan.identifier
                      ? true
                      : false
                  }
                  invoice={store.invoicesAndSubscriptions?.recent_invoice}
                  onPressSubscribe={() => onPressSubscribe(plan)}
                  onPressUpdate={() => onPressUpdate(plan)}
                />
              ))}
          </PlansWrapper>
        </div>
      </Container>
    </ScreenContainer>
  );
};

export default observer(PlansScreen);
