import { observer, useLocalStore } from "mobx-react";
import React from "react";

import SendIcon from "src/assets/svgs/send.svg";
import { useGlobalStore } from "src/context/global";
import theme from "src/theme";

import { Input, Button } from "../../components";
import Store from "./store";
import { Container } from "./styles";

interface ResetPasswordScreenProps {
  resetPasswordToken: string;
  closeModal: CallableFunction;
}

const ResetPasswordScreen: React.FC<ResetPasswordScreenProps> = (props) => {
  const { resetPasswordToken, closeModal } = props;

  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  return (
    <Container>
      <img src={SendIcon} />
      <h1>Cadastre sua nova senha</h1>
      <Input
        label="Crie uma nova senha"
        style={{ marginBottom: 40 }}
        type="password"
        {...store.formController.getField("password")}
      />
      <Input
        label="Repita a senha que você criou"
        style={{ marginBottom: 40 }}
        type="password"
        {...store.formController.getField("confirmPassword")}
      />
      <Button
        title="Confirmar alteração"
        color={theme.colors.purpleDark}
        style={{ marginBottom: 50 }}
        onClick={() => store.changePassword(resetPasswordToken, closeModal)}
      />
    </Container>
  );
};

export default observer(ResetPasswordScreen);
