import React, { createContext, useContext, useState } from "react";

import { Modal, ModalProps } from "src/ui/components";

interface ModalContextProps {
  showModal: (params: Partial<ModalProps>) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextProps>({
  showModal: () => {},
  closeModal: () => {},
});

export const ModalProvider = (props) => {
  const { children } = props;

  const [modalParams, setModalParams] = useState<ModalProps>({
    visible: false,
  });

  const showModal = (params: Partial<ModalProps>) => {
    setModalParams({ ...params, visible: true });
  };

  const closeModal = () => {
    setModalParams({ visible: false });
  };

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      <Modal {...modalParams} closeModal={closeModal} />
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  const { showModal, closeModal } = context;
  return { showModal, closeModal };
};
