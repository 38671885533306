/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { action, makeObservable, observable } from "mobx";

import api from "src/http/api";
import { IEmployees } from "src/http/interfaces/IEmployees";
import { IFinancials } from "src/http/interfaces/IFinancials";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public financials;
  public financialsReport: IFinancials[] = [];
  public loader: LoadingStore;
  public employees: IEmployees[] = [];

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      financialsReport: observable,
      financials: observable,
      employees: observable,
      getFinancials: action,
      getEmployees: action,
    });
  }

  private formatterDate = (completedDate) => {
    const date = new Date(completedDate);

    const day = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ][date.getDay()];
    const month = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ][date.getMonth()];

    return `${day}, ${date.getDate()} de ${month}`;
  };

  private groupBy = (array, key) =>
    array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue,
      );

      return result;
    }, {});

  public getFinancials = async (filters?, userId?: string) => {
    try {
      this.loader.start();
      this.financials = await api.getFinancials(filters, userId);

      const financialsFormatted = this.financials.data.map((financial) => ({
        ...financial,
        due_date_formatted: this.formatterDate(financial.due_date),
      }));

      this.financialsReport = this.groupBy(
        financialsFormatted,
        "due_date_formatted",
      );
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public async getEmployees(idUser: string) {
    try {
      this.employees = await api.newGetEmployees(idUser);
    } catch (e) {
      // this.showError(this.handleError(e));
    } finally {
      // this.loader.end();
    }
  }
}
