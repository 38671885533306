export default {
  colors: {
    dark: "#212529",
    purpleDark: "#B111BB",
    purple: "#C96BCF",
    purpleLight: "#E0A4E3",
    pinkDark: "#D93E9D",
    pink: "#E671B8",
    pinkLight: "#F48BCC",
    orangeDark: "#F8834B",
    orange: "#FA9A6C",
    orangeLight: "#FFB491",
    redDark: "#EE5463",
    red: "#FD7986",
    redLight: "#FFA4AD",
    greyLighter: "#F2F2F2",
    greyLight: "#E9E9E9",
    grey: "#D0D0D0",
    greyDark: "#C2C2C2",
    greyDarker: "#4F4F4F",
    white: "#FFFFFF",
    green: "#41CE88",
    whatsapp: "#25D366",
    yellow: "#F2E029",
  },
  fontSizes: {
    s8: 8,
    s10: 10,
    s12: 12,
    s14: 14,
    s16: 16,
    s18: 18,
    s24: 24,
    s32: 32,
    s48: 48,
    s50: 50,
    s64: 64,
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  dimentions: {
    mobile: 600,
    tablet: 900,
  },
  shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadiusDefault: "5px",
};
