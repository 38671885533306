import styled from "styled-components";

export const Container = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.orangeDark};
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  position: relative;
  z-index: 99;
  &:before {
    background-color: #10b418;
    width: calc(100% + 40px);
    height: 75px;
    border-radius: 10px;
    content: " ";
    z-index: -1;
    position: absolute;
    top: -10px;
    left: -20px;
    background: ${({ theme }) => theme.colors.orangeDark};
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    opacity: 0.6;
    -webkit-animation: pulse 1s ease-out;
    animation: pulse 1.8s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(0);
      opacity: 0;
    }
    25% {
      -webkit-transform: scale(0.3);
      opacity: 1;
    }
    50% {
      -webkit-transform: scale(0.6);
      opacity: 0.6;
    }
    75% {
      -webkit-transform: scale(0.9);
      opacity: 0.3;
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    25% {
      transform: scale(0.3);
      opacity: 1;
    }
    50% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    75% {
      transform: scale(0.9);
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
`;
