/* eslint-disable prettier/prettier */
import { observer, useLocalStore } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Switch from "react-switch";

import { useGlobalStore } from "src/context/global";
import { IUser } from "src/http/api";
import { authStore } from "src/stores/AuthStore";
import theme from "src/theme";
import { Button } from "src/ui/components";

import Store from "./store";
import {
  Container,
  ContainerLabel,
  ContainerButtons,
  Hour,
  ButtonScheduling,
} from "./styles";

const UserPreferences = ({ loggedInAs }) => {
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore, authStore));
  const [cashierAuto, setCashierAuto] = useState(false);
  const [autoScheduling, setAutoScheduling] = useState(false);
  const [schedulingForHour, setSchedulingForHour] = useState(
    store.schedulingForHour,
  );
  const [overdueBillsReminder, setOverdueBillsReminder] = useState(false);

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  useEffect(() => {
    async function sideEffects() {
      if (user.profile !== "provider") {
        await store.getPreferences(loggedInAs ? Number(loggedInAs) : user.id);
      } else {
        await store.getPreferencesIndividual(user.my_company.id);
      }

      setCashierAuto(store.cashierAuto);
      setSchedulingForHour(store.schedulingForHour);
      setAutoScheduling(store.autoScheduling);
      setOverdueBillsReminder(store.overdueBillsReminder);
    }

    sideEffects();
  }, []);

  async function handleSave() {
    if (user.profile !== "provider") {
      await store.updatePreferences(
        {
          cashier_auto: cashierAuto,
          auto_scheduling: autoScheduling,
          scheduling_for_hour: schedulingForHour,
          overdue_bills_reminder: overdueBillsReminder,
        },
        loggedInAs ? Number(loggedInAs) : user.id,
      );
    } else {
      await store.updatePreferencesIndividual(
        {
          cashier_auto: cashierAuto,
          auto_scheduling: autoScheduling,
          scheduling_for_hour: schedulingForHour,
          overdue_bills_reminder: overdueBillsReminder,
        },
        user.my_company.id,
      );
    }
  }

  return (
    <>
      <Container>
        <ContainerLabel>
          <span>Agendamento vai para caixa automático:</span>
          <Switch
            onColor={theme.colors.purpleDark}
            onChange={(checked) => setCashierAuto(checked)}
            checked={cashierAuto}
          />
        </ContainerLabel>
        <ContainerLabel>
          <span>Aceitar agendamento automático:</span>
          <Switch
            onColor={theme.colors.purpleDark}
            onChange={(checked) => setAutoScheduling(checked)}
            checked={autoScheduling}
          />
        </ContainerLabel>

        <ContainerLabel>
          <span>Agendamento por horário:</span>
          <ContainerButtons>
            <ButtonScheduling
              disabled={schedulingForHour <= 1}
              onClick={() => setSchedulingForHour(schedulingForHour - 1)}
            >
              {schedulingForHour > 1 ? "-" : ""}
            </ButtonScheduling>
            <Hour>{schedulingForHour}</Hour>
            <ButtonScheduling
              disabled={schedulingForHour >= 24}
              onClick={() => setSchedulingForHour(schedulingForHour + 1)}
            >
              +
            </ButtonScheduling>
          </ContainerButtons>
        </ContainerLabel>
        <ContainerLabel>
          <span>Lembrete de contas atrasadas:</span>
          <Switch
            onColor={theme.colors.purpleDark}
            onChange={(checked) => setOverdueBillsReminder(checked)}
            checked={overdueBillsReminder}
          />
        </ContainerLabel>

        <Button
          title="Salvar"
          color={theme.colors.orangeDark}
          onClick={() => handleSave()}
        />
      </Container>
    </>
  );
};

export default observer(UserPreferences);
