import React from "react";
import CalendarComponent, { CalendarProps } from "react-calendar";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

import { Container } from "./styles";
import moment from "moment";

interface IProps extends CalendarProps {
  color?: string;
}

export const Calendar: React.FC<IProps> = (props) => {
  const { color, tileContent, onChange, value, ...rest } = props;
  return (
    <Container color={color}>
      <CalendarComponent
        tileContent={tileContent}
        prevLabel={<HiChevronLeft size={16} color={"#212529"} />}
        nextLabel={<HiChevronRight size={16} color={"#212529"} />}
        formatShortWeekday={(locale, date) => moment(date).format("ddd")}
        formatMonthYear={(locale, date) => moment(date).format("MMMM YYYY")}
        prev2Label={null}
        next2Label={null}
        onChange={onChange}
        value={value}
        {...rest}
      />
    </Container>
  );
};
