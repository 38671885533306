import React from "react";

import theme from "src/theme";

import { Container } from "./styles";

interface ICard extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  children: React.ReactElement | React.ReactElement[];
  fontColor?: string;
}

export const Card: React.FC<ICard> = (props) => {
  const { children, label, fontColor } = props;

  return (
    <Container color={fontColor || theme.colors.greyDarker} {...props}>
      {label && <span className="card-label">{label}</span>}
      <div className="card">{children}</div>
    </Container>
  );
};
