import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import { FaAngleRight } from "react-icons/fa";
import moment from "moment";
import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import formatters from "src/resources/formatters";
import theme from "src/theme";
import { Card, CardInner, Table } from "src/ui/components";
import { Button } from "src/ui/components/Button";
import ServicesProvidedScreen from "src/ui/pages/ServicesProvidedScreen";

import Store from "./store";
import { Container } from "./styles";

const ServicesProvided: React.FC<{ employee: string }> = (props) => {
  const { employee } = props;
  const { showModal, closeModal } = useModal();
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  useEffect(() => {
    store.getServicesProvided(employee);
  }, []);

  return (
    <Container>
      <Card>
        <CardInner>
          <Table>
            <thead>
              <tr>
                <td>Serviço</td>
                <td>Valor</td>
                <td>Horas</td>
                <td width={16} />
              </tr>
            </thead>
            <tbody>
              {store.servicesProvided.map(
                ({ name, price, duration_time, id }) => (
                  <tr key={name}>
                    <td>{name}</td>
                    <td>R$ {formatters.toCurrency(Number(price || 0))}</td>
                    <td>{moment(duration_time).zone("-0300").format("HH:mm")}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        showModal({
                          content: (
                            <ServicesProvidedScreen
                              closeModal={closeModal}
                              id={id}
                              onList={store.getServicesProvided}
                              userId={employee}
                            />
                          ),
                          onClickOut: closeModal,
                        })
                      }
                    >
                      <FaAngleRight className="icon-open-details" />
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>
        </CardInner>
      </Card>

      <Button
        title="Adicionar novo"
        color={theme.colors.orangeDark}
        style={{ margin: "60px auto 0" }}
        onClick={() =>
          showModal({
            content: (
              <ServicesProvidedScreen
                closeModal={closeModal}
                onList={store.getServicesProvided}
                userId={employee}
              />
            ),
            onClickOut: closeModal,
          })
        }
      />
    </Container>
  );
};

export default observer(ServicesProvided);
