import { action, makeObservable } from "mobx";

import api from "src/http/api";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private loader: LoadingStore;
  public formController = new FormControllerStore({
    email: "",
  });

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      recoveryPassword: action,
    });
  }

  public recoveryPassword = async (onSuccess: CallableFunction) => {
    try {
      this.loader.start();
      const { email } = this.formController.getFields();
      await api.recoveryPassword(email);
      onSuccess();
      this.showSuccess(
        "Você recebeu um e-mail com as instruções para alterar sua senha!",
      );
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
