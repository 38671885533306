import { makeObservable, observable } from "mobx";

import api from "src/http/api";
import AuthStore from "src/stores/AuthStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private loader: LoadingStore;
  private authStore: AuthStore;

  public plans;
  public plan;

  constructor(loader: LoadingStore, authStore: AuthStore) {
    super();
    this.loader = loader;
    this.authStore = authStore;

    makeObservable(this, {
      plan: observable,
      plans: observable,
    });
  }

  public getPlans = async () => {
    try {
      this.loader.start();
      this.plans = await api.getPlans();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
