import { observer, useLocalStore } from "mobx-react";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineRight, AiOutlineLike, AiOutlineClose } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiOutlineCog, HiOutlineCash } from "react-icons/hi";
import { IoMdCalendar } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";

import "react-loading-skeleton/dist/skeleton.css";
import ScreenContainer from "src/layout/ScreenContainer";

import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import { IUser } from "src/http/api";
import { Appointments } from "src/services/appointments";
import theme from "src/theme";
import {
  Button,
  Card,
  Table,
  Calendar,
  Empty,
  Error,
  FetchData,
  DayBadge,
  CardLabel,
  Alert,
  AlertType,
  DayBlocked,
  Select,
} from "src/ui/components";

import ScheduleCreateScreen from "../ScheduleCreateScreen";
import ServiceDetails from "./components/ServiceDetails";
import ServiceList from "./components/ServiceList";
import Store from "./store";
import {
  CardInner,
  ScheduleWaiting,
  BodyModalFirstLogin,
  HeaderModalFirstLogin,
  Container,
  CardsRow,
  ScheduledClients,
  AlertMessageContainer,
} from "./styles";

const ScheduleScreen: React.FC = (props) => {
  const {} = props;

  const { showModal, closeModal } = useModal();
  const { loadingStore, authStore } = useGlobalStore();
  const history = useHistory();
  const store = useLocalStore(
    () => new Store(loadingStore, authStore, history),
  );

  const [employee, setEmployee] = useState("");

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  useEffect(() => {
    store.getHomeData();
    store.getEmployees(String(user.id));

    const showModalFirstLogin = store.showModalFistLogin();

    if (showModalFirstLogin) {
      openModalFirstLogin();
    }
  }, []);

  useEffect(() => {
    store.getHomeData(employee);
  }, [employee]);

  function handleInsertManual(currentAppointment?) {
    showModal({
      content: (
        <ScheduleCreateScreen
          closeModal={closeModal}
          reloadHomeData={() => store.getHomeData(loggedInAs ? loggedInAs : String(user.id))}
          currentAppointment={currentAppointment}
          employees={store.employees}
        />
      ),
      onClickOut: closeModal,
    });
  }

  const onClickDay = (
    date: Date,
    appointments: Appointments,
    editable: boolean,
  ) => {
    showModal({
      content: (
        <ServiceList
          appointments={appointments}
          date={date}
          editable={editable}
          insertAppointment={handleInsertManual}
          deleteAppointment={(id) => store.deleteAppointment(id)}
        />
      ),
      onClickOut: closeModal,
      showCloseButton: true,
    });
  };

  const [modalFirsLoginIsOpen, setIsOpenModalFirstLogin] =
    React.useState(false);

  function openModalFirstLogin() {
    setIsOpenModalFirstLogin(true);
  }

  function closeModalFirstLogin() {
    setIsOpenModalFirstLogin(false);
  }

  function onAfterOpenModalFirstLogin() {
    store.alterLastLoginInLocalStorage();
  }

  const loggedInAs = JSON.parse(localStorage.getItem("loggedInAs")!) as string;

  return (
    <ScreenContainer>
      <Container>
        {store.daysToEndTestPeriod > 0 && user.profile !== "employee" && (
          <Alert type={AlertType.Info}>
            <AlertMessageContainer>
              <div style={{ marginRight: 15 }}>
                <b>Periodo de avaliação</b>
                <p>
                  Você ainda tem <b>{store.daysToEndTestPeriod}</b> dia(s) para
                  aproveitar o Facilite Link sem precisar pagar nada por isso!
                </p>
              </div>
              <Button
                title="Conheça nossos planos"
                outlined
                color={theme.colors.orangeDark}
                style={{ width: 200 }}
                onClick={() =>
                  history.push({
                    pathname: "planos",
                    state: { isAccountBlocked: false },
                  })
                }
              />
            </AlertMessageContainer>
          </Alert>
        )}
        {store.daysPlanExpired < 0 && (
          <Alert type={AlertType.Warning}>
            <AlertMessageContainer>
              <div style={{ marginRight: 15 }}>
                <b>Plano vencido</b>
                <p>
                  Sua assinatura está com pagamento pendente a{" "}
                  {store.daysPlanExpired * -1} dias, evite bloqueio.
                </p>
              </div>
              <Button
                title="Ir para a fatura!"
                outlined
                color={theme.colors.orangeDark}
                style={{ width: 200 }}
                onClick={() => window.open(store.invoicesAndSubscriptions?.recent_invoice, "_blank")}
              />
            </AlertMessageContainer>
          </Alert>
        )}
        {store.daysPlanExpired >= 1 && store.daysPlanExpired <= 5 && (
          <Alert type={AlertType.Warning}>
            <AlertMessageContainer>
              <div style={{ marginRight: 15 }}>
                <b>Vencimento da Fatura</b>
                <p>Sua assinatura vence em {store.countDays}.</p>
              </div>
              <Button
                title="Imprimir Fatura!"
                outlined
                color={theme.colors.orangeDark}
                style={{ width: 200 }}
                onClick={() =>
                  window.open(
                    store.invoicesAndSubscriptions?.recent_invoice,
                    "_blank",
                    "noopener, noreferrer",
                  )
                }
              />
            </AlertMessageContainer>
          </Alert>
        )}
        <CardLabel>Agendamentos</CardLabel>
        <CardsRow>
          <Card>
            <ScheduledClients scheduleType="day">
              <span>Clientes agendados hoje</span>
              <h1>{store.appointmentsOfMonth ? store.appointmentsOfMonth?.total_day : <Skeleton width={100} />}</h1>
            </ScheduledClients>
          </Card>
          <Card>
            <ScheduledClients scheduleType="week">
              <span>Clientes agendados essa semana</span>
              <h1>{store.appointmentsOfMonth ? store.appointmentsOfMonth?.total_week : <Skeleton width={100} />}</h1>
            </ScheduledClients>
          </Card>
        </CardsRow>

        <Card label="Próximos clientes">
          <CardInner>
            {store.dayCustomers ? (
              <FetchData
              items={store.dayCustomers?.data}
              hasError={!store.dayCustomers}
              emptyComponent={
                <Empty
                  title="Opssss...."
                  description="Você ainda não tem clientes agendados para hoje!"
                />
              }
              errorComponent={
                <Error
                  title="Opssss...."
                  description="Houve um erro ao listar os próximos clientes, tente novamente!"
                />
              }
            >
              <Table>
                <thead>
                  <tr>
                    <th align="left">Hrs</th>
                    <th align="left">Cliente</th>
                    <th align="left">Serviço</th>
                    <th align="left">Atendente</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {store.dayCustomers?.data.map((dayCustomer) => (
                    <tr key={dayCustomer.id}>
                      <td>{moment(dayCustomer.start_time).zone("-0300").format("HH:mm")}</td>
                      <td>{dayCustomer.customer.name}</td>
                      <td>{dayCustomer.service_with_provider.name}</td>
                      <td>
                        {
                          dayCustomer.service_with_provider.user_name.split(
                            " ",
                          )[0]
                        }
                      </td>
                      <td width={16}>
                        <AiOutlineRight
                          size={16}
                          color={theme.colors.orangeDark}
                          onClick={() =>
                            showModal({
                              content: (
                                <ServiceDetails
                                  dayCustomer={dayCustomer}
                                  onGoBack={closeModal}
                                />
                              ),
                              onClickOut: closeModal,
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </FetchData>
            ) : (
            <Skeleton count={3} height={50}/>
            )}
          </CardInner>
        </Card>

        <Card label="Calendário - quant. serviços no dia">
          <CardInner>
            {store.appointmentsOfMonth ? (
              <>
                {user.profile === "hall" && !loggedInAs && (
                  <Select
                    label="Escolha um profissional"
                    options={store ? store.employees.map((e) => ({
                      value: String(e.id),
                      name: e.name,
                    })) : []}
                    fixed="Todos"
                    onChange={(e) => setEmployee(e.target.value)}
                    value={employee}
                  />
                )}

                <Calendar
                  onActiveStartDateChange={({ activeStartDate }) =>
                    store.getMonthAppointments(activeStartDate)
                  }
                  tileContent={({ date, view }) => {
                    const { appointmentsOfMonth } = store;
                    if (!appointmentsOfMonth) {
                      return null;
                    }
                    const currentTileDate = moment(date).format("YYYY-MM-DD");

                    let amountOfDayAppointments = 0;
                    for (const appointment of appointmentsOfMonth.data) {
                      if (view === "month") {
                        const currentAppointmentFormattedDate = moment(
                          appointment.date,
                        ).format("YYYY-MM-DD");
                        if (
                          currentAppointmentFormattedDate === currentTileDate
                        ) {
                          amountOfDayAppointments += appointment.quantity;
                        }
                      }
                    }
                    const currentEmployee = (loggedInAs ? Number(loggedInAs) : (employee ? Number(employee) : Number(user.id)));
                    let itsBlocked = false;
                    for (const appointmentBlocked of appointmentsOfMonth.appointment_blocks) {
                      if (view === "month") {
                        const currentAppointmentBlocked = moment(
                          appointmentBlocked.starts_at,
                        ).format("YYYY-MM-DD");
                        if (currentAppointmentBlocked === currentTileDate && (appointmentBlocked.type === "company" || (appointmentBlocked.user_id && appointmentBlocked.user_id === currentEmployee))) {
                          itsBlocked = true;
                        }
                      }
                    }

                    if (itsBlocked) {
                      return <DayBlocked />;
                    }

                    if (amountOfDayAppointments > 0) {
                      return <DayBadge content={amountOfDayAppointments} />;
                    }

                    return null;
                  }}
                  onClickDay={(day) => store.onClickDay(day, onClickDay, (loggedInAs ? Number(loggedInAs) : (employee ? Number(employee) : Number(user.id))))}
                />
              </>
            ) : (
              <Skeleton height={350}/>
            )}
          </CardInner>
        </Card>

        <Card label="Agendamentos aguardando aprovação">
          <CardInner>
            {store.appointmentsWaitingApproval ? (
            <FetchData
              items={store.appointmentsWaitingApproval?.data}
              hasError={!store.appointmentsWaitingApproval}
              emptyComponent={
                <Empty
                  title="Opssss...."
                  description="Não existem agendamentos aguardando aprovação!"
                />
              }
              errorComponent={
                <Error
                  title="Opssss...."
                  description="Houve um erro ao listar os agendamentos aguardando aprovação, tente novamente!"
                />
              }
            >
              <Table>
                <tbody>
                  {store.appointmentsWaitingApproval?.data.map(
                    (appointment) => (
                      <tr key={appointment.id}>
                        <td>
                          <ScheduleWaiting>
                            <span className="title">
                              {appointment.customer.name} -{" "}
                              {appointment.service_with_provider.name} -{" "}
                              {appointment.customer.phone}
                              {appointment.coupon && " - "}
                              {appointment.coupon.name}
                            </span>
                            <span className="date">
                              {moment(appointment.start_time).zone("-0300").format(
                                "DD/MM/YY - HH:mm",
                              )}
                              h - Atendente:{" "}
                              {appointment.service_with_provider.user_name}
                            </span>
                          </ScheduleWaiting>
                        </td>
                        <td width="40">
                          <AiOutlineClose
                            size={27}
                            color={theme.colors.red}
                            onClick={() =>
                              store.declineAppointment(appointment.id)
                            }
                          />
                        </td>
                        <td width="27">
                          <AiOutlineLike
                            size={27}
                            color={theme.colors.green}
                            onClick={() =>
                              store.acceptAppointment(appointment.id)
                            }
                          />
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </FetchData>) : (
              <Skeleton count={3} height={50}/>
            )}
            <Button
              style={{ marginTop: 30 }}
              color={theme.colors.orangeDark}
              title="Inserir manual"
              outlined
              onClick={() => handleInsertManual()}
            />
          </CardInner>
        </Card>

        <Modal
          overlayClassName="overlay-modal"
          isOpen={modalFirsLoginIsOpen}
          onAfterOpen={onAfterOpenModalFirstLogin}
          style={{
            content: {
              width: "342px",
              height: "500px",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              background: "#FFFFFF",
              borderRadius: "10px",
              borderColor: "white",
            },
          }}
        >
          <HeaderModalFirstLogin>Por onde quer começar?</HeaderModalFirstLogin>
          <BodyModalFirstLogin>
            <div className="firstLogin__row">
              <div
                className="firstLogin__item"
                onClick={() => closeModalFirstLogin()}
              >
                <IoMdCalendar />
                Agenda
              </div>
              <Link to={"/financeiro"} className="firstLogin__item">
                <HiOutlineCash />
                Financeiro
              </Link>
            </div>
            <div className="firstLogin__row">
              {user.profile === "hall" && (
                <Link to={"/configuracoes"} className="firstLogin__item">
                  <HiOutlineCog />
                  Configurações
                </Link>
              )}

              <Link to={"/customizar"} className="firstLogin__item">
                <FiEdit />
                Customizar
              </Link>
            </div>

            <Button
              color={theme.colors.orangeDark}
              title="Assistir tutoriais"
              onClick={() => {
                window.open(
                  "https://www.youtube.com/watch?v=vZqK6U7vtkA&list=PLfUmdWH_IlUHs76kVIe9PGm3WcaVRv_6g", // eslint-disable-line
                );
              }}
              outlined
              mb={15}
            />

            <Button
              color={theme.colors.orangeDark}
              title="Falar com o suporte"
              onClick={() => {
                window.location.href =
                  "https://api.whatsapp.com/send?phone=5547992230429&text=Preciso%20de%20suporte";
              }}
              mb={15}
            />

            <Button
              color={theme.colors.orangeDark}
              title="Fechar"
              onClick={() => closeModalFirstLogin()}
              outlined
            />
          </BodyModalFirstLogin>
        </Modal>
      </Container>
    </ScreenContainer>
  );
};

export default observer(ScheduleScreen);
