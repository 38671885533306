import { action, makeObservable, observable } from "mobx";

// import api from "src/http/api";
import AuthStore from "src/stores/AuthStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";
import FormControllerStore from "src/stores/FormControllerStore";
import api from "src/http/api";

export default class Store extends UIStore {
  public employee;
  public loader: LoadingStore;
  public employeeProfileImageUrl = "";
  public employeeProfileFile: File | null = null;
  public formController = new FormControllerStore({
    name: "",
    email: "",
    password: "",
  });

  constructor(loader: LoadingStore, authStore: AuthStore) {
    super();
    this.loader = loader;

    makeObservable(this, {
      employee: observable,
      getEmployee: action,
      employeeProfileImageUrl: observable,
      setEmployeeProfileImage: action,
      employeeProfileFile: observable,
    });
  }

  public getEmployee = (id) => {
    try {
      this.loader.start();
      //  this.employee = await api.getEmployee(id);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public setEmployeeProfileImage = (value: string, file: File) => {
    this.employeeProfileImageUrl = value;
    this.employeeProfileFile = file;
  };

  public createOrUpdateEmployee = async (
    onSuccess: () => void,
    userId?: string,
    onFail?: () => void,
  ) => {
    try {
      this.loader.start();
      await api.createOrUpdateUser({
        name: this.formController.getField("name").value,
        email: this.formController.getField("email").value,
        password: this.formController.getField("password").value,
        password_confirmation: this.formController.getField("password").value,
        image: this.employeeProfileFile,
        profile: "employee",
        user_provider_id: userId,
        status: "active",
      });

      this.showSuccess("Professional cadastrado com sucesso!");
      onSuccess();
    } catch (e) {
      if (e.response.data.error === "You cannot create employees anymore, please change your plan." && onFail) {
        this.showError("Você não pode adicionar novos funcionários, por favor mude seu plano.");
        onFail();
      } else {
        this.showError(this.handleError(e));
      }
    } finally {
      this.loader.end();
    }
  };
}
