import { observer, useLocalStore } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import arrowLeft from "src/assets/svgs/arrowLeft.svg";
import { useGlobalStore } from "src/context/global";
import theme from "src/theme";
import { Button, Card, Input } from "src/ui/components";
import ProfilePicture from "./components/ProfilePicture";
import EmployeeProfileModal from "./components/employeeProfileModal";

import Store from "./store";
import { Container, Header, CardInner } from "./styles";

interface IUsersScreen {
  closeModal: () => void;
  id?: number;
}

const EmployeeProfileScreen: React.FC<IUsersScreen> = (props) => {
  const { id, closeModal } = props;
  const { loadingStore, authStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore, authStore));
  const [employeeProfileModalVisible, setEmployeeProfileModalVisible] =
    React.useState<boolean>(false);
  const [name, setName] = useState("");
  const history = useHistory();

  function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  // useEffect(() => {
  //   function sideEffect() {
  //     //  await store.getEmployee(id);

  //     setName(store.employee.name);
  //   }

  //   if (id) {
  //     sideEffect();
  //   }
  // }, []);

  return (
    <Container>
      <Header>
        <button className="go-back" onClick={() => history.goBack()}>
          <img src={arrowLeft} />
        </button>
        <div>
          <h1>Dados do profissional</h1>
        </div>
      </Header>

      <Card label="Dados do profissional">
        <CardInner>
          <ProfilePicture
            avatar={store?.employeeProfileImageUrl}
            onSetImageUrl={(value, file) =>
              store.setEmployeeProfileImage(value, file)
            }
          />
          <>
            <Input
              label="Nome"
              disabled
              {...store.formController.getField("employee_name")}
            />
          </>
          <Button
            title="Alterar senha"
            onClick={() => setEmployeeProfileModalVisible(true)}
            outlined
            color={theme.colors.orangeDark}
            style={{ marginTop: 20, marginBottom: 15 }}
          />
        </CardInner>
      </Card>
      <EmployeeProfileModal
        setVisible={() => setEmployeeProfileModalVisible(false)}
        visible={employeeProfileModalVisible}
      />
    </Container>
  );
};

export default observer(EmployeeProfileScreen);
