import { useLocalStore } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useState } from "react";

import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import { IUser } from "src/http/api";
import { DayCustomer } from "src/services/appointments";
import ScheduleCreateScreen from "src/ui/pages/ScheduleCreateScreen";

import { Table, ModalHeader } from "../../../../components";
import Store from "../../store";
import { Icons, DeleteButton, WhatsappButton, EditableButton } from "./styles";

interface IServiceDetails {
  onGoBack: () => void;
  dayCustomer: DayCustomer;
}

const ServiceDetails: React.FC<IServiceDetails> = (props) => {
  const { onGoBack, dayCustomer } = props;
  const { loadingStore, authStore } = useGlobalStore();
  const { showModal, closeModal } = useModal();
  const [enableModal, setEnableModal] = useState(false);
  const history = useHistory();
  const store = useLocalStore(
    () => new Store(loadingStore, authStore, history),
  );

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  function handleInsertManual(currentAppointment?) {
    showModal({
      content: (
        <ScheduleCreateScreen
          closeModal={closeModal}
          reloadHomeData={() => store.getHomeData()}
          currentAppointment={currentAppointment}
          employees={store.employees}
        />
      ),
      onClickOut: closeModal,
    });
  }

  const updateEmployees = async () => {
    if (user.profile === "hall") {
      await store.getEmployees(String(user.id));
      setEnableModal(true);
    } else {
      setEnableModal(true);
    }
  };

  useEffect(() => {
    updateEmployees();
  }, []);

  const editable = () => {
    const today = moment(new Date());
    const startTime = moment(dayCustomer.start_time);

    if (startTime.diff(today, "minutes") >= 0) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <ModalHeader onGoBack={onGoBack} title="Detalhes" />
      {editable() && (
        <Icons>
          {enableModal && (
            <EditableButton
              onClick={() => {
                handleInsertManual(dayCustomer);
              }}
            >
              <FiEdit2 />
            </EditableButton>
          )}
          {!enableModal && (
            <EditableButton style={{color: "gray"}}>
              <FiEdit2 />
            </EditableButton>
          )}
          <DeleteButton
            onClick={() => {
              store.deleteAppointment(dayCustomer.id);
            }}
          >
            <AiFillDelete />
          </DeleteButton>
        </Icons>
      )}
      <Table>
        <thead>
          <tr>
            <td>Horário</td>
            <td>Cliente</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{moment(dayCustomer.start_time).zone("-0300").format("HH:mm")}</td>
            <td>{dayCustomer.customer.name}</td>
          </tr>
        </tbody>
      </Table>

      <Table>
        <thead>
          <tr>
            <td>Telefone</td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dayCustomer.customer.phone}</td>
            <td style={{ textAlign: "right" }}>
              <WhatsappButton
                href={`https://api.whatsapp.com/send?phone=55${dayCustomer.customer.phone.replace(
                  /[^0-9]/g,
                  "",
                )}&text=Olá, ${dayCustomer.customer.name}`}
                className="btn-whatsapp"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp />
              </WhatsappButton>
            </td>
          </tr>
        </tbody>
      </Table>

      <Table>
        <thead>
          <tr>
            <td>Serviço</td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {dayCustomer.service_with_provider.name}
              <br />
              {/*
              @TODO: Exibir o metodo de pagamento escolhido
              */}
            </td>
            <td>{dayCustomer.service_with_provider.duration_time}</td>
          </tr>
        </tbody>
      </Table>
      <Table>
        <thead>
          <tr>
            <td>Atendente</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dayCustomer.service_with_provider.user_name}</td>
          </tr>
        </tbody>
      </Table>
      {dayCustomer.coupon.name && (
        <Table>
          <thead>
            <tr>
              <th>Cupom</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }}>{dayCustomer.coupon.name}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ServiceDetails;
