import React, { InputHTMLAttributes } from "react";
import { FiChevronDown } from "react-icons/fi";

import theme from "src/theme";

import { Container } from "./styles";

interface ColorPickerProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const { label, value, name, onChange } = props;

  return (
    <Container value={value}>
      {label && <label>{label}</label>}
      <div className="input-container">
        <input
          type="color"
          className="color-input"
          name={name}
          onBlur={onChange}
        />
        <div className="color-preview" />
        <span className="value">{value}</span>
        <FiChevronDown color={theme.colors.orangeDark} size={20} />
      </div>
    </Container>
  );
};

export default ColorPicker;
