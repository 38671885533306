import React from "react";

import { Container, SwitchInput } from "./styles";

interface ISwitch extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Switch: React.FC<ISwitch> = (props) => {
  const { label, ...rest } = props;

  return (
    <Container>
      {label ? <label>{label}</label> : null}

      <SwitchInput>
        <input type="checkbox" {...rest} />
        <span className="slider round" />
      </SwitchInput>
    </Container>
  );
};
