import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  > .form-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;
