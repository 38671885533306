import React from "react";

import { Container } from "./styles";

interface InputProps extends React.InputHTMLAttributes<InputProps> {
  label?: string;
  required?: boolean;
  style?: React.CSSProperties;
  options: OptionProps[];
  onChange?: (value) => void;
  value?: string | number;
  disabled?: boolean;
  placeholder?: string;
  fixed?: string;
}

interface OptionProps {
  value: string | number;
  name: string;
}

export const Select: React.FC<InputProps> = (props) => {
  const {
    label,
    required,
    style,
    options,
    onChange,
    value,
    disabled,
    placeholder,
    fixed,
  } = props;

  return (
    <Container>
      {label && (
        <label>
          {label} {required && <span>*</span>}
        </label>
      )}
      <div className="select-container">
        <select
          style={style}
          onChange={onChange}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
        >
          <option value="">
            {fixed ? fixed : "Selecione..."}
          </option>
          {options.map(({ value: valueOption, name }) => (
            <option key={valueOption} value={valueOption}>
              {name}
            </option>
          ))}
        </select>
      </div>
    </Container>
  );
};
