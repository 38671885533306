import { observer, useLocalStore } from "mobx-react";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
// import SelectMultiple from "react-select";
import swal from "sweetalert";

import arrowLeft from "src/assets/svgs/arrowLeft.svg";
import { useGlobalStore } from "src/context/global";
import masks from "src/resources/masks";
import theme from "src/theme";
import {
  Button,
  Card,
  Input,
  Calendar,
  Horary,
  Select,
  CardLabel,
} from "src/ui/components";

import Store from "./store";
import { CardInner, Container, Header, Loading } from "./styles";
import { IEmployees } from "src/http/interfaces/IEmployees";
import { IUser } from "src/http/api";

interface IAppointment {
  id: number;
  start_time: Date;
  end_time: Date;
  status: string;
  payment_method: string;
  created_at: Date;
  customer: { id: number; name: string; phone: string; cpf: string };
  service_with_provider: {
    id: number;
    duration_time: string;
    fixed_price: boolean;
    name: string;
    observation: string;
    price: number;
    user_id: number;
  };
}

interface selectionOptions {
  value: number;
  label: string;
}

type selectOptionType = selectionOptions[] | any;

interface IScheduleCreateScreen {
  closeModal: () => void;
  reloadHomeData: CallableFunction;
  currentAppointment?: IAppointment;
  employees: IEmployees[];
}

const ScheduleCreateScreen: React.FC<IScheduleCreateScreen> = (props) => {
  const { closeModal, reloadHomeData, currentAppointment, employees } = props;
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));
  const [currentForm, setCurrentForm] = useState("register");
  const [oldScheduled, setoldScheduled] = useState<Date | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  const blockWeek = (numWeek: number) => {
    switch (numWeek) {
      case 1:
        return store.week.some((week) => week === "segunda") ? true : false;
      case 2:
        return store.week.some((week) => week === "terça") ? true : false;
      case 3:
        return store.week.some((week) => week === "quarta") ? true : false;
      case 4:
        return store.week.some((week) => week === "quinta") ? true : false;
      case 5:
        return store.week.some((week) => week === "sexta") ? true : false;
      case 6:
        return store.week.some((week) => week === "sábado") ? true : false;
      default:
        return store.week.some((week) => week === "domingo") ? true : false;
    }
  };

  useEffect(() => {
    if (currentAppointment?.id) {
      store.formController.setField(
        "client_name",
        currentAppointment?.customer.name,
      );

      store.formController.setField(
        "client_cpf",
        currentAppointment?.customer.cpf,
      );

      store.formController.setField(
        "client_phone",
        currentAppointment?.customer.phone,
      );

      store.formController.setField(
        "employee",
        String(currentAppointment?.service_with_provider.user_id),
      );
      let paymentMethod = 0;

      if (currentAppointment?.payment_method === "pix") {
        paymentMethod = 1;
      } else if (currentAppointment?.payment_method === "dinheiro") {
        paymentMethod = 2;
      }

      store.formController.setField("payment_method", paymentMethod);

      store.getServices(store.formController.getField("employee").value);
      setTimeout(() => {
        setServiceId(
          [
            {
              label: currentAppointment?.service_with_provider.name,
              value: currentAppointment?.service_with_provider.id,
            },
          ],
          true,
        );
      }, 1000);
      setoldScheduled(currentAppointment?.start_time);
      store.selectedDay = currentAppointment?.start_time;
      store.selectedHour = moment(currentAppointment?.start_time)
        .zone("-0300")
        .format("HH:mm");
    } else {
      setoldScheduled(null);
      store.selectedDay = null;
      store.selectedHour = "";
    }
  }, []);

  useEffect(() => {
    setSelectedOptions([]);
    if (user.profile !== "hall") {
      store.getServices(String(user.id));
      store.getDaysAndTimes(String(user.id));
      store.formController.setField("employee", String(user.id));
    } else if (user.profile === "hall" && loggedInAs) {
      store.getServices(String(loggedInAs));
      store.getDaysAndTimes(String(loggedInAs));
      store.formController.setField("employee", String(loggedInAs));
    } else {
      store.getServices(store.formController.getField("employee").value);
    }
    if (store.formController.getField("employee").value) {
      store.getDaysAndTimes(store.formController.getField("employee").value);
    }
    store.getMonthAppointments(
      loggedInAs ? loggedInAs : store.formController.getField("employee").value,
      new Date(),
    );
  }, [store.formController.getField("employee").value]);

  const onSuccess = () => {
    reloadHomeData();
    closeModal();
    location.reload();
  };

  const validateScheduling = (e) => {
    const { client_name, client_cpf, client_phone, payment_method } =
      store.formController.getFields();
    if (loggedInAs) {
      store.formController.setField("employee", loggedInAs);
    }

    if (client_name === "") {
      swal("Ops!", "Por favor, preencha o Nome do cliente", "error");
      return;
    }

    return store.createAppointment(onSuccess, currentAppointment?.id);
  };

  const setServiceId = (selectedOption: selectOptionType, initial: boolean) => {
    if (!initial) {
      setoldScheduled(null);
      store.selectedDay = null;
      store.selectedHour = "";
    }
    const containId = selectedOptions.indexOf(selectedOption[0].value);
    selectedOption.map((option: selectOptionType) => {
      if (containId === -1) {
        selectedOptions.push(option.value);
      } else {
        selectedOptions.splice(containId, 1);
      }
    });
    setSelectedOptions(selectedOptions);
    store.formController.setField("service_id", selectedOptions.toString());
  };

  const loggedInAs = JSON.parse(localStorage.getItem("loggedInAs")!) as string;

  return (
    <Container>
      <Header>
        <button
          className="go-back"
          onClick={
            currentForm === "register"
              ? closeModal
              : () => setCurrentForm("register")
          }
        >
          <img src={arrowLeft} />
        </button>
        <h1>Novo agendamento</h1>
      </Header>

      {user.profile === "hall" && !loggedInAs && (
        <Select
          label="Profissional"
          options={
            employees
              ? employees.map((e) => ({
                  value: String(e.id),
                  name: e.name,
                }))
              : []
          }
          {...store.formController.getField("employee")}
        />
      )}

      <div className="container-list-service">
        {store.services.map((service, index) => (
          <Button
            className="button-service"
            onClick={() => {
              setServiceId([{ label: service.name, value: service.id }], false);
            }}
            key={service.id}
            title={service.name}
            color={
              selectedOptions.indexOf(service.id) === -1
                ? theme.colors.grey
                : theme.colors.orangeDark
            }
          />
        ))}
      </div>
      {oldScheduled && (
        <div style={{ paddingBottom: "30px", paddingTop: "30px" }}>
          Agendado para dia:{" "}
          <b>
            {moment(oldScheduled).zone("-0300").format("DD-MM-YYYY HH:mm:SS")}
          </b>
          <br />
          <br />
          <Button
            className="button-service"
            onClick={() => {
              setoldScheduled(null);
              store.selectedDay = null;
              store.selectedHour = "";
            }}
            title="Trocar Data e hora"
            color={theme.colors.orangeDark}
          />
        </div>
      )}
      {!oldScheduled && (
        <>
          {store.appointmentsOfMonth !== null ? (
            <Card
              style={{ marginBottom: 27 }}
              label="Selecione o dia que o serviço sera realizado"
            >
              <CardInner>
                <Calendar
                  onChange={(day) =>
                    store.setSelectedDay(
                      day,
                      loggedInAs
                        ? loggedInAs
                        : store.formController.getField("employee").value,
                    )
                  }
                  value={store.selectedDay}
                  tileDisabled={({ date, view }) => {
                    if (moment(date).isBefore(moment(), "day")) {
                      return true;
                    }

                    const { appointmentsOfMonth } = store;
                    if (!appointmentsOfMonth) {
                      return false;
                    }
                    const currentTileDate = moment(date).format("YYYY-MM-DD");

                    let itsBlocked = false;
                    const currentEmployee = loggedInAs
                      ? Number(loggedInAs)
                      : Number(store.formController.getField("employee").value);
                    for (const appointmentBlocked of appointmentsOfMonth.appointment_blocks) {
                      if (view === "month") {
                        const currentAppointmentBlocked = moment(
                          appointmentBlocked.starts_at,
                        ).format("YYYY-MM-DD");
                        if (
                          currentAppointmentBlocked === currentTileDate &&
                          (appointmentBlocked.type === "company" ||
                            (appointmentBlocked.user_id &&
                              appointmentBlocked.user_id === currentEmployee))
                        ) {
                          itsBlocked = true;
                        }
                      }
                    }

                    if (itsBlocked) {
                      return true;
                    }

                    if (!blockWeek(date.getDay())) {
                      return true;
                    }

                    return false;
                  }}
                  onActiveStartDateChange={({ activeStartDate }) => {
                    store.getMonthAppointments(
                      loggedInAs
                        ? loggedInAs
                        : store.formController.getField("employee").value,
                      activeStartDate,
                    );
                  }}
                />
              </CardInner>
            </Card>
          ) : (
            <Loading>
              <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
              </div>
            </Loading>
          )}

          {store.availableHours?.length > 0 && (
            <>
              <CardLabel>Selecione o horário</CardLabel>
              <div className="horary-list">
                {store.availableHours.map((availableHour) => (
                  <Horary
                    key={availableHour.time}
                    hour={availableHour.time}
                    selected={availableHour.time === store.selectedHour}
                    onClick={() => store.setSelectedHour(availableHour.time)}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}

      <Input
        label="Nome do cliente"
        required
        {...store.formController.getField("client_name")}
      />
      <Input
        label="CPF/CNPJ"
        {...store.formController.getField("client_cpf")}
        onChange={(e) =>
          store.formController
            .getField("client_cpf")
            .onChange(e, masks.CPFAndCNPJ)
        }
        maxLength={18}
        placeholder="999.999.999-99"
      />
      <Input
        label="Telefone"
        {...store.formController.getField("client_phone")}
        onChange={(e) =>
          store.formController.getField("client_phone").onChange(e, masks.phone)
        }
        placeholder="(99) 99999-9999"
        maxLength={15}
      />
      <Select
        label="Forma de pagamento"
        options={[
          { name: "Cartão", value: 0 },
          { name: "PIX", value: 1 },
          { name: "Dinheiro", value: 2 },
        ]}
        {...store.formController.getField("payment_method")}
      />
      <Button
        title="Confirmar agendamento"
        color={theme.colors.orangeDark}
        outlined
        onClick={(e) => validateScheduling(e)}
      />
    </Container>
  );
};

export default observer(ScheduleCreateScreen);
