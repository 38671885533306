const masks = {
  phone: (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    return value.replace(/(\d)(\d{4})$/, "$1-$2");
  },

  CPFAndCNPJ: (value: string) => {
    value = value.replace(/\D/g, "");
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})/, "$1-$2");
      return value;
    } else {
      value = value.replace(/(\d{2})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1/$2");
      value = value.replace(/(\d{4})(\d{1,2})/, "$1-$2");
      return value;
    }
  },

  cardNumber: (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{4})(\d)/, "$1 $2");
    value = value.replace(/(\d{4})(\d)/, "$1 $2");
    value = value.replace(/(\d{4})(\d{1,4})/, "$1 $2");
    return value;
  },

  cardExpirationMonth: (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d{1,4})/, "$1/$2");
    return value;
  },

  zipCode: (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,3})/, "$1-$2");
    return value;
  },

  percentageOrCurrency: (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    return value;
  },
};

export default masks;
