import styled from "styled-components";

import ChevronBottom from "src/assets/svgs/chevronBottom.svg";

export const Container = styled.div`
  > label {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    margin-bottom: 8px;
    display: block;
    > span {
      color: #f00;
    }
  }
  > .select-container {
    display: flex;
    width: 100%;
    height: 55px;
    margin-bottom: 27px;
    border-radius: 5px;
    flex-direction: row;
    background: ${({ theme }) => theme.colors.white};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      pointer-events: none;
      background: url(${ChevronBottom}) center no-repeat #fff;
      border-radius: 5px;
    }
    > select {
      flex: 1;
      height: 100%;
      border: none;
      background: ${({ theme }) => theme.colors.white};
      border-radius: 5px;
      padding: 0 15px;
      font-size: ${({ theme }) => theme.fontSizes.s18}px;
      color: ${({ theme }) => theme.colors.greyDarker};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
`;
