import styled from "styled-components";

interface IContainer {
  selected?: boolean;
  color?: string;
}

export const Container = styled.button<IContainer>`
  background: ${({ theme, selected, color }) =>
    selected ? (color ? color : theme.colors.orangeDark) : theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 24px 0;
  width: calc((100% - 56px) / 4);
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.greyDarker};
  display: block;
  margin: 7px;
  cursor: pointer;
`;
