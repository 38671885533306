import * as H from "history";
import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";

import { useGlobalStore } from "src/context/global";
import { IPlan } from "src/http/api";

import BankSlip from "./components/BankSlip";
import CardForm from "./components/CardForm";
import InitialForm from "./components/InitialForm";
import Pix from "./components/Pix";
import Store from "./store";

interface PaymentFormProps {
  plan: IPlan;
  formController?: any;
  subscription?: string;
  closeModal: () => void;
  history: H.History<unknown>;
}

const PlanUpdate: React.FC<PaymentFormProps> = (props) => {
  const { closeModal, subscription, history, plan } = props;

  const { loadingStore, authStore } = useGlobalStore();

  const store = useLocalStore(() => new Store(loadingStore, authStore));

  const onPressContinue = () => {
    if (
      store.formController.getField("paymentMethod").value === "credit_card"
    ) {
      store.setCurrentScreen("credit_card");
      return;
    }
    store.updateSubscriptionPayment();
  };

  const updateSubscriptionPayment = (onSuccess: () => void) => {
    store.updateSubscriptionPayment(onSuccess);
  };

  const goToInitialForm = () => {
    store.setCurrentScreen("initial");
  };

  useEffect(() => {
    store.formController.setField("subscription", subscription);
    store.formController.setField("plan", plan.id);
  }, []);

  return (
    <>
      {store.currentScreen === "initial" && (
        <InitialForm
          formController={store.formController}
          onGoBack={closeModal}
          onPressContinue={() => onPressContinue()}
        />
      )}
      {store.currentScreen === "credit_card" && (
        <CardForm
          formController={store.formController}
          onGoBack={goToInitialForm}
          closeModal={closeModal}
          history={history}
          createOrder={updateSubscriptionPayment}
        />
      )}
      {store.currentScreen === "pix" && (
        <Pix
          closeModal={closeModal}
          order={store.updatePayment}
          onGoBack={goToInitialForm}
          history={history}
        />
      )}
      {store.currentScreen === "bank_slip" && (
        <BankSlip
          order={store.updatePayment}
          onGoBack={goToInitialForm}
          closeModal={closeModal}
          history={history}
        />
      )}
    </>
  );
};

export default observer(PlanUpdate);
