import client from "src/http/client";

export interface IContactUs {
  id: number;
  title: string;
  phone: string;
}

export interface ITutorials {
  id: number;
  title: string;
  url: string;
}

export interface IUserProfile {
  created_at: Date | null;
  email: string;
  phone: string;
  phone_prefix: string;
  interval_type: string;
  payable_with: string;
  expiration_date: string;
  recurring_charge: boolean;
}

export default class SupportService {
  public async getContactUs(): Promise<IContactUs[]> {
    const response = await client.get<{ data: IContactUs[] }>(
      "/storefront/v1/support_items?kind=contact",
    );
    return response.data.data;
  }

  public async getTutorials(): Promise<ITutorials[]> {
    const response = await client.get<{ data: ITutorials[] }>(
      "/storefront/v1/support_items?kind=video",
    );
    return response.data.data;
  }

  public async getUserProfile(): Promise<IUserProfile> {
    const response = await client.get<IUserProfile>(
      "/storefront/v1/support_items/user_profile",
    );
    return response.data;
  }
}
