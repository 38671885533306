import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
`;

export const ContainerLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.greyDarker};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const ButtonScheduling = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.purpleDark};
  font-size: ${({ theme }) => theme.fontSizes.s32}px;
`;

export const ContainerButtons = styled.div`
  display: flex;
`;

export const Hour = styled.div`
  color: ${({ theme }) => theme.colors.greyDarker};
  font-size: ${({ theme }) => theme.fontSizes.s32}px;
  margin-left: ${({ theme }) => theme.fontSizes.s8}px;
  margin-right: ${({ theme }) => theme.fontSizes.s8}px;
`;
