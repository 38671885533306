import moment from "moment";
import "moment/locale/pt-br";
import React from "react";
import ReactDOM from "react-dom";

import App from "./main";

moment.locale("pt-br");

ReactDOM.render(<App />, document.getElementById("root"));
