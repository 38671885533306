import { observer, useLocalObservable } from "mobx-react";
import React, { useState } from "react";
import { ClientForm, Content, Description } from "./styles";
import { useClientScreenStore } from "src/ui/pages/ClientScreen/store";
import { Input, Select } from "src/ui/components";
import masks from "src/resources/masks";
import moment from "moment";
import { Button, Title } from "src/ui/pages/ClientScreen/styles";

interface Props {
  onSubmit: () => void;
}

const ClientPersonData: React.FC<Props> = ({ onSubmit }) => {
  const store = useLocalObservable(useClientScreenStore);
  const { company, appointment } = store;
  const [loading, setLoading] = useState(false);

  const servicesPrice =
    appointment?.services
      ?.map((service) => service.price)
      .reduce((a, b) => a + b, 0) ?? 0;

  const handleSubmit = async () => {
    setLoading(true);
    await store.createAppointment();
    onSubmit();
    setLoading(false);
  };

  return (
    <Content>
      <Description>
        <h1>
          {appointment?.services?.map((service) => service.name).join(", ")}
        </h1>
        <p>
          {(servicesPrice / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </p>
        <p>
          {moment(appointment?.date).format("DD/MM")} às {appointment?.hour}
        </p>
      </Description>

      <Title>Preencha seus dados:</Title>

      <ClientForm>
        <Input
          label="Nome completo"
          required
          {...store.formController.getField("name")}
        />

        <Input
          label="CPF/CNPJ"
          placeholder="Opcional"
          maxLength={18}
          {...store.formController.getField("cpf")}
          onChange={(e) =>
            store.formController.getField("cpf").onChange(e, masks.CPFAndCNPJ)
          }
        />

        <Input
          label="Telefone"
          placeholder="(00) 00000-0000"
          required
          {...store.formController.getField("phone")}
          onChange={(e) =>
            store.formController.getField("phone").onChange(e, masks.phone)
          }
          maxLength={15}
        />

        <Select
          label="Método de pagamento"
          required
          options={[
            { name: "Cartão", value: 0 },
            { name: "PIX", value: 1 },
            { name: "Dinheiro", value: 2 },
          ]}
          {...store.formController.getField("payment_method")}
        />
      </ClientForm>

      <Button
        primary={company?.primary_color}
        textColor={company?.font_color}
        onClick={handleSubmit}
        disabled={loading}
      >
        Agendar
      </Button>
    </Content>
  );
};

export default observer(ClientPersonData);
