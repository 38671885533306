/* eslint-disable @typescript-eslint/no-unused-expressions */
import { action, makeObservable, observable, runInAction } from "mobx";
import Swal from "sweetalert2";

import api from "src/http/api";
import { ICompany } from "src/http/interfaces/ICompany";
import { INewSocialNetworking } from "src/http/interfaces/INewSocialNetworking";
import { ISocialNetworkingCompany } from "src/http/interfaces/ISocialNetworkingCompany";
import ServicesService, { IService } from "src/services/services";
import { authStore } from "src/stores/AuthStore";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";
import theme from "src/theme";

export default class Store extends UIStore {
  private loader: LoadingStore;
  public company: ICompany | null = null;
  public socialsMediaCompany: ISocialNetworkingCompany[] = [];
  public socialsMedia: INewSocialNetworking[] = [];
  public cashierAuto = false;
  public schedulingForHour = 1;
  private servicesService: ServicesService;
  public avatarUrl = "";
  public file: File | null = null;
  public slug = "";
  public companyOriginalName = "";
  public services: IService[] = [];
  public service: IService | null = null;
  public overdueBillsReminder = false;
  public autoScheduling = false;
  public formController = new FormControllerStore({
    name: "",
    address: "",
    primary_color: "",
    secondary_color: "",
    font_color: "",
  });

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    authStore.getCurrentUser();
    this.servicesService = new ServicesService();
    makeObservable(this, {
      onChangeOverdueBillsReminder: action,
      editCompany: action,
      onChangeHours: action,
      onChangeAutoScheduling: action,
      onChangeHoursForLess: action,
      onChangeCashierAuto: action,
      setSelectedService: action,
      getCompany: action,
      getServices: action,
      setFieldsValues: action,
      hasColors: action,
      editSocialNetworking: action,
      fetch: action,
      setImage: action,
      getCompanySocialNetworking: action,
      getSocialNetworking: action,
      setLinkingCompany: action,
      setLinkingForNewCompany: action,
      onRemoveOneSocialMedia: action,
      company: observable,
      avatarUrl: observable,
      file: observable,
      service: observable,
      socialsMediaCompany: observable,
      services: observable,
      slug: observable,
      socialsMedia: observable,
      overdueBillsReminder: observable,
      schedulingForHour: observable,
      autoScheduling: observable,
      cashierAuto: observable,
      companyOriginalName: observable,
    });
  }

  public hasColors = (onSucess: () => void) => {
    const data = this.formController.getFields();
    if (
      data.font_color === "" ||
      data.name === "" ||
      data.primary_color === "" ||
      data.secondary_color === "" ||
      this.avatarUrl === ""
    ) {
      this.showError("Preencha todos os dados para visualizar a prévia");
    } else {
      onSucess();
    }
  };

  public setImage = (value: string, file: File) => {
    this.avatarUrl = value;
    this.file = file;
  };

  public setLinkingCompany = (
    socialMedia: INewSocialNetworking,
    prefix: string | null,
    index: number,
  ) => {
    if (prefix) {
      prefix = socialMedia.link;
    }
    this.socialsMediaCompany[index].link = socialMedia.link;
  };

  public setLinkingForNewCompany = (
    socialMedia: INewSocialNetworking,
    index: number,
    value: string,
    prefix?: string,
  ) => {
    socialMedia.link = value;
    if (prefix) {
      socialMedia.link = `${prefix}${value}`;
    }
    this.socialsMedia[index] = socialMedia;
  };

  public onRemoveOneSocialMedia = (id: number | undefined) => {
    const socialMediaFilted = this.socialsMedia.filter(
      (social) => social.id !== id,
    );
    this.socialsMedia = socialMediaFilted;
    this.editSocialNetworking();
  };

  public onChangeCashierAuto = () => {
    this.cashierAuto = !this.cashierAuto;
  };

  public onChangeOverdueBillsReminder = () => {
    this.overdueBillsReminder = !this.overdueBillsReminder;
  };

  public onChangeAutoScheduling = () => {
    this.autoScheduling = !this.autoScheduling;
  };

  public onChangeHours = () => {
    this.schedulingForHour++;
  };

  public onChangeHoursForLess = () => {
    this.schedulingForHour--;
  };

  public setFieldsValues = (company: ICompany | null) => {
    if (company) {
      runInAction(() => {
        this.formController = new FormControllerStore({
          address: company.address || "",
          name: company.name || "",
          font_color: company.font_color || theme.colors.white,
          primary_color: company.primary_color || theme.colors.purpleDark,
          secondary_color: company.secondary_color || theme.colors.orangeDark,
        });
        this.companyOriginalName = company.name;
        this.avatarUrl = company.avatar_url || "";
        this.slug = company.slug || "";
        this.cashierAuto = company.cashier_auto;
        this.autoScheduling = company.auto_scheduling;
        this.schedulingForHour = company.scheduling_for_hour;
        this.overdueBillsReminder = company.overdue_bills_reminder;
      });
    }
  };

  public async getServices() {
    try {
      this.loader.start();
      this.services = await this.servicesService.getServices();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getCompany(user_id?: string) {
    try {
      this.loader.start();
      this.company = await api.getUserCompany();
      if (this.company) {
        this.fetch(user_id);
      } else {
        this.fetch(user_id);
      }
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public editSocialNetworking = async () => {
    try {
      this.loader.start();
      const response = await api.editSocialNetworking(
        this.socialsMedia,
        this.company?.id,
      );
      runInAction(() => (this.socialsMedia = response));
      this.getCompanySocialNetworking();
    } catch (e) {
      throw this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public fetch = (user_id) => {
    runInAction(() => this.getCompanySocialNetworking());
    runInAction(() => this.getServices());
    if (user_id) {
      runInAction(() => this.getUser(user_id));
    } else {
      runInAction(() => this.setFieldsValues(this.company));
    }
    runInAction(() => this.getSocialNetworking());
  };

  public getCompanySocialNetworking = async () => {
    try {
      this.loader.start();
      if (this.company && this.company.id) {
        this.socialsMediaCompany = await api.getCompanySocialNetworking(
          this.company.id,
        );
      }
    } catch (e) {
      throw this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public getSocialNetworking = async () => {
    try {
      this.loader.start();
      this.socialsMedia = await api.getSocialNetworking();
    } catch (e) {
      throw this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public editEmployee = (
    user_id: number,
    employee: any,
    selectedImage: File | null,
  ) => {
    try {
      this.loader.start();
      const user = {
        image: selectedImage,
        name: employee.name,
        id: user_id,
      };
      api.createOrUpdateUser(user, true);
    } catch (e) {
      throw this.showError(this.handleError(e));
    } finally {
      this.showSuccess("Editado com sucesso!");
      this.loader.end();
    }
  };

  public editCompany = async () => {
    try {
      this.loader.start();
      const data = this.formController.getFields();
      if (this.company && this.company.id) {
        if (this.companyOriginalName !== data.name.trim()) {
          const click = await Swal.fire({
            icon: "info",
            title: "Alterar o nome da empresa também irá alterar o seu link.",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => (result.value ? true : false));

          if (!click) {
            return;
          }
        }

        this.company = await api.editCompany(this.company.id.toString(), {
          address: data.address,
          name: data.name.trim(),
          font_color: data.font_color,
          primary_color: data.primary_color,
          secondary_color: data.secondary_color,
          auto_scheduling: this.autoScheduling,
          avatar_url: this.avatarUrl,
          avatar: this.file,
          cashier_auto: this.cashierAuto,
          scheduling_for_hour: this.schedulingForHour,
          overdue_bills_reminder: this.overdueBillsReminder,
          slug: this.slug,
        });
      }
      this.editSocialNetworking();
      // location.reload();
    } catch (e) {
      throw this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public setSelectedService(service: IService) {
    runInAction(() => (this.service = service));
  }

  public async getUser(user_id: string) {
    try {
      const response = await api.getUser(user_id);
      return response;
    } catch (e) {
      throw this.showError(this.handleError(e));
    }
  }

  public async changePassword(newPassword: string) {
    try {
      this.loader.start();
      await api.changeEmployeePassword(newPassword);
    } catch (e) {
      throw this.showError(this.handleError(e));
    } finally {
      this.showSuccess("Senha alterada com sucesso!");
      this.loader.end();
    }
  }
}
