import styled from "styled-components";

export const Container = styled.nav`
  width: 100%;
  height: 80px;
  background: ${({ theme }) => theme.colors.purpleDark};
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  @media (min-width: ${({ theme }) => theme.dimentions.tablet}px) {
    display: none;
  }
  > button.mobile-button {
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
  }
  > img.logo {
    width: 74px;
    height: 42px;
    object-fit: contain;
  }
  > .mobile-menu-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    > .mobile-menu {
      width: 250px;
      height: 100%;
      background: ${({ theme }) => theme.colors.purpleDark};
      padding: 20px 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > button.mobile-button-close {
        width: 100%;
        height: 50px;
        background: transparent;
        border: none;
        text-align: right;
        cursor: pointer;
      }
      > ul {
        list-style: none;
        > li {
          > a {
            display: block;
            line-height: 60px;
            color: ${({ theme }) => theme.colors.white};
            font-size: ${({ theme }) => theme.fontSizes.s18}px;
            font-weight: ${({ theme }) => theme.fontWeights.bold};
            > svg {
              margin-right: 25px;
            }
          }
        }
      }
    }
    > .mobile-menu-out {
      flex: 1;
      height: 100%;
    }
  }
`;
