import styled from "styled-components";

export const Container = styled.span`
  background: ${({ theme }) => theme.colors.orangeDark};
  width: 18px;
  height: 18px;
  border-radius: 18px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.s10}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  position: absolute;
  text-align: center;
  line-height: 19px;
  margin-top: -8px;
`;
