import styled from "styled-components";

interface ContainerProps {
  highlight?: boolean;
}

export const Container = styled.div<ContainerProps>`
  min-width: 300px;
  padding: 20px;
  flex: 1;
  background: #fff;
  border-radius: 6px;
  > h1.title {
    font-size: ${({ theme }) => theme.fontSizes.s24}px;
    color: ${({ theme, highlight }) =>
      highlight ? theme.colors.orangeDark : theme.colors.purpleDark};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: center;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  > span.description {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    text-align: center;
    margin-bottom: 8px;
    display: block;
  }
  > span.old-price {
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme, highlight }) =>
      highlight ? theme.colors.orangeDark : theme.colors.purpleDark};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: center;
    margin-bottom: 24px;
    display: block;
    text-decoration: line-through;
  }
  > img {
    margin: 20px auto;
    display: block;
    object-fit: contain;
    height: 100px;
  }
  > span.price-with-discount {
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    text-align: center;
    margin-bottom: 16px;
    display: block;
    > b {
      font-size: ${({ theme }) => theme.fontSizes.s32}px;
      color: ${({ theme, highlight }) =>
        highlight ? theme.colors.orangeDark : theme.colors.purpleDark};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }
  > span.charge-type {
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
    color: ${({ theme }) => theme.colors.greyDark};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    text-align: center;
    margin-bottom: 16px;
    display: block;
  }
`;
