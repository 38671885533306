import React from "react";
import { IoIosClose } from "react-icons/io";

import { Container } from "./styles";

export const DayBlocked: React.FC = () => (
  <Container>
    <IoIosClose />
  </Container>
);
