import styled from "styled-components";

interface ContainerProps {
  outlined?: boolean;
  color: string;
  mb?: number;
}

export const Container = styled.button<ContainerProps>`
  width: 100%;
  height: 55px;
  margin-bottom: ${({ mb }) => (mb ? mb : 0)}px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: ${({ outlined, color, theme }) =>
    outlined ? "transparent" : color ? color : theme.colors.orangeDark};
  border: 2px solid ${({ color }) => color};
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  color: ${({ theme, color, outlined }) =>
    outlined ? (color ? color : theme.colors.orangeDark) : theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
