import moment from "moment";
import React from "react";
import { AiOutlineRight } from "react-icons/ai";

import { useModal } from "src/context/modal";
import { Appointments } from "src/services/appointments";
import theme from "src/theme";

// import { Table } from "../../../../components";
import ServiceDetailsOptions from "../ServiceDetailsOptions";
import { Container, Table } from "./styles";

interface IProps {
  appointments: Appointments;
  date: Date;
  insertAppointment: CallableFunction;
  deleteAppointment: CallableFunction;
  editable: boolean;
}

const ServiceList: React.FC<IProps> = (props) => {
  const { showModal, closeModal } = useModal();

  const detailService = (dayCustomer) => {
    showModal({
      content: (
        <ServiceDetailsOptions
          dayCustomer={dayCustomer}
          onGoBack={closeModal}
        />
      ),
      onClickOut: closeModal,
    });
  };

  const { appointments, date, insertAppointment, deleteAppointment, editable } =
    props;

  return (
    <Container>
      <div className="page-title">
        <span className="title">Serviços do dia</span>
        <span className="date">
          {moment(date).zone("-0300").format("D [de] MMMM [de] YYYY")}
        </span>
      </div>
      <div>
        <Table>
          <thead>
            <tr>
              <th style={{ width: "30%" }} scope="col">
                Horário
              </th>
              <th style={{ width: "100%" }} scope="col">
                Cliente
              </th>
              <th style={{ width: "50%" }} scope="col">
                Serviço
              </th>
              <th style={{ width: "50%" }} scope="col">
                Atendente
              </th>
              <th style={{ width: "10%" }} scope="col">
                {" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {appointments?.data
              ?.sort(
                (a, b) => +new Date(a.start_time) - +new Date(b.start_time),
              )
              .map((appointment) => (
                <tr key={appointment.id}>
                  <td scope="row" data-label="Horário">
                    {moment(appointment.start_time).zone("-0300").format("HH:mm")}
                  </td>
                  <td
                    data-label="Cliente"
                    style={{
                      display: "inline-block",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    {appointment.customer.name}
                  </td>
                  <td data-label="Serviço">
                    {appointment.service_with_provider.name}
                  </td>
                  <td data-label="Atendente">
                    {appointment.service_with_provider.user_name}
                  </td>
                  <td>
                    <AiOutlineRight
                      size={16}
                      color={theme.colors.orangeDark}
                      onClick={() => detailService(appointment)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};
export default ServiceList;
