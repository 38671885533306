import { makeObservable, observable } from "mobx";

import api, {
  IInvoiceSubscriptions,
  IQuantityEmployees,
} from "src/http/api";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public plans: any[] = [];
  public loader: LoadingStore;
  public freeTrial: boolean;
  public freeTrialDays: number;
  public invoicesAndSubscriptions: IInvoiceSubscriptions | null = null;
  public quantityEmployees: IQuantityEmployees[];

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      plans: observable,
      freeTrial: observable,
      freeTrialDays: observable,
      invoicesAndSubscriptions: observable,
    });
  }

  public getPlans = async (userId: string) => {
    try {
      this.loader.start();
      this.plans = await api.getUpgradePlans(userId);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public async getDaysToEndTestPeriod() {
    try {
      this.loader.start();
      const daysToEnd = await api.getDaysToEndTestPeriod();
      this.freeTrial = daysToEnd.free_trial;
      this.freeTrialDays = daysToEnd.days;
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  }

  public async getUserStatus() {
    try {
      this.loader.start();
      this.invoicesAndSubscriptions = await api.getUserStatus();
    } catch (e) {
    } finally {
      this.loader.end();
    }
  }
}
