import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useGlobalStore } from "src/context/global";

import api, { IPlan, IQuantityEmployees } from "src/http/api";
import formatters from "src/resources/formatters";
import theme from "src/theme";
import { Select, Button, ModalHeader } from "src/ui/components";

interface IProps {
  onPressContinue: () => void;
  formController: any;
  onGoBack: () => void;
  plansP: IPlan[];
}

const InitialForm: React.FC<IProps> = (props) => {
  const { onPressContinue, formController, onGoBack, plansP } = props;
  const [plans, setPlans] = useState(plansP);
  const [selectedQuantityEmployees, setSelectedQuantityEmployees] =
    useState("1");
  const [quantityEmployees, setQuantityEmployees] = useState<
    IQuantityEmployees[]
  >([]);

  const { loadingStore } = useGlobalStore();

  const paymentMethods = [
    { name: "Cartão", value: "credit_card" },
    { name: "Boleto", value: "bank_slip" },
    { name: "Pix", value: "pix" },
  ];

  useEffect(() => {
    getQuantityEmployees();
  }, []);

  useEffect(() => {
    const getPlans = async () => {
      loadingStore.start();
      const response = await api.getPlans(selectedQuantityEmployees);
      setPlans(response);
      loadingStore.end();
    };

    getPlans();
  }, [selectedQuantityEmployees]);

  const getQuantityEmployees = async () => {
    const response = await api.getQuantityEmployees();
    setQuantityEmployees(response.data);
  };

  return (
    <div className="payment-form">
      <ModalHeader onGoBack={onGoBack} title="Selecione a forma de pagamento" />
      {quantityEmployees && quantityEmployees.length >= 0 && (
        <Select
          label="Mude sua faixa de profissionais aqui :)"
          options={quantityEmployees.map((quantityEmployee) => ({
            value: quantityEmployee.id,
            name: quantityEmployee.description,
          }))}
          onChange={(e) => setSelectedQuantityEmployees(e.target.value)}
          value={selectedQuantityEmployees}
        />
      )}
      <Select
        label="Planos Ativos"
        required
        options={plans.map((plan) => ({
          name: `${plan.name.toUpperCase()} - R$ ${formatters.toCurrency(
            plan.value_cents_with_discount,
          )}`,
          value: plan.id,
        }))}
        {...formController.getField("plan_id")}
      />
      <Select
        label="Escolha a forma de pagamento"
        required
        options={paymentMethods}
        {...formController.getField("paymentMethod")}
      />
      <Button
        title="Próxima etapa >>"
        color={theme.colors.purpleDark}
        onClick={() => onPressContinue()}
      />
    </div>
  );
};

export default observer(InitialForm);
