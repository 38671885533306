/* eslint-disable @typescript-eslint/ban-ts-comment */
import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import { useState } from "react";

import arrowLeft from "src/assets/svgs/arrowLeft.svg";
import { useGlobalStore } from "src/context/global";
import formatters from "src/resources/formatters";
import theme from "src/theme";
import { Button, Input, Textarea, Switch, InputMoney } from "src/ui/components";

import Store from "./store";
import { Container, Header, Body } from "./styles";

interface IDaysAndTimeScreen {
  closeModal: () => void;
  onList: (userId: string) => Promise<void>;
  id?: number;
  userId: string;
}

interface IService {
  id?: number;
  name: string;
  price?: number | string;
  fixed_price: boolean;
  observation: string;
  duration: string;
  duration_time: string;
  user_id: number;
}

const ServicesProvidedScreen: React.FC<IDaysAndTimeScreen> = (props) => {
  const { id, closeModal, onList, userId } = props;
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [fixed_price, setFixedPrice] = useState(false);
  const [duration, setDuration] = useState("");
  const [observation, setObservation] = useState("");

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const service: IService = {
      id,
      name,
      fixed_price,
      duration,
      duration_time: duration,
      observation,
      user_id: Number(userId),
    };

    if (fixed_price) {
      service.price = parseInt(price.replace(/\D/g, ""));
    }

    await store.createdOrUpdateServiceProvided(closeModal, service);

    await onList(userId);
  }

  useEffect(() => {
    async function sideEffect() {
      await store.getOneServiceProvided(id);

      setName(store.serviceProvided.name);
      setPrice(formatters.toCurrency(store.serviceProvided.price || 0));
      setFixedPrice(store.serviceProvided.fixed_price);
      setDuration(
        new Date(store.serviceProvided.duration).toLocaleTimeString(),
      );
      setObservation(store.serviceProvided.observation);
    }

    if (id) {
      sideEffect();
    }
  }, []);

  async function handleInactivateService() {
    await store.inactivateService(closeModal, id);
    onList(userId);
  }

  return (
    <Container>
      <Header>
        <button className="go-back" onClick={closeModal}>
          <img src={arrowLeft} />
        </button>

        <div>
          <h1>{!id ? "Serviços prestados" : "Editar serviço"}</h1>
          <span>{!id ? "Descreva os serviços prestados." : ""}</span>
        </div>
      </Header>

      <Body>
        <form onSubmit={handleOnSubmit}>
          <Input
            label="Serviço"
            onChange={(event) => setName(event.target.value)}
            value={name}
            required
          />
          <Textarea
            label="Observação"
            onChange={(event) => setObservation(event.target.value)}
            value={observation}
          />
          <Input
            label="Tempo de duração"
            type="time"
            onChange={(event) => setDuration(event.target.value)}
            value={duration}
            required
          />

          <div className="value-service">
            <Switch
              label="Valor fixo"
              onChange={(event) => setFixedPrice(event.target.checked)}
              checked={fixed_price}
            />

            {fixed_price && (
              <InputMoney
                value={price}
                onChange={(event) => setPrice(event.target.value)}
              />
            )}
          </div>

          {id ? (
            <Button
              title="Inativar Serviço"
              color={theme.colors.purpleDark}
              outlined
              mb={10}
              type="button"
              onClick={() => handleInactivateService()}
            />
          ) : null}

          <Button title="Confirmar" color={theme.colors.orangeDark} outlined />
        </form>
      </Body>
    </Container>
  );
};

export default observer(ServicesProvidedScreen);
