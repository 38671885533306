import swal from "sweetalert";

export default class UIStore {
  public handleError(e): string {
    if (e.response?.data?.errors) {
      if (Array.isArray(e.response.data.errors)) {
        const errors = e.response.data.errors as string[];
        let formattedErrors = "";
        for (const error of errors) {
          formattedErrors += `${error}\n`;
        }
        return formattedErrors;
      }
      if (!!e.response.data.errors) {
        if (e.response.data.errors.full_messages) {
          if (Array.isArray(e.response.data.errors.full_messages)) {
            const errors = e.response.data.errors.full_messages as string[];
            let formattedErrors = "";
            for (const error of errors) {
              formattedErrors += `${error}\n`;
            }
            return formattedErrors;
          }
        }
      }
    }
    if (e.response?.data?.message) {
      return "Você deve selecionar um serviço primeiro" as string;
    }
    return "Houve um erro, tente novamente mais tarde!";
  }

  public showError = (message: string) => {
    swal("Ops!", message, "error");
  };

  public showSuccess = (message: string) => {
    swal("Muito bem!", message, "success");
  };
}
