import { useLocalStore } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { AiFillDelete, AiOutlineWhatsApp } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import { IUser } from "src/http/api";
import { DayCustomer } from "src/services/appointments";
import ScheduleCreateScreen from "src/ui/pages/ScheduleCreateScreen";

import { Table, ModalHeader } from "../../../../components";
import Store from "../../store";
import { Icons, DeleteButton, EditableButton, WhatsAppIcon } from "./styles";

interface IServiceDetails {
  onGoBack: () => void;
  dayCustomer: DayCustomer;
}

const ServiceDetails: React.FC<IServiceDetails> = (props) => {
  const { onGoBack, dayCustomer } = props;
  const { loadingStore, authStore } = useGlobalStore();
  const { showModal, closeModal } = useModal();
  const [enableModal, setEnableModal] = useState(false);
  const history = useHistory();
  const store = useLocalStore(
    () => new Store(loadingStore, authStore, history),
  );

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  function handleInsertManual(currentAppointment?) {
    showModal({
      content: (
        <ScheduleCreateScreen
          closeModal={closeModal}
          reloadHomeData={() => store.getHomeData()}
          currentAppointment={currentAppointment}
          employees={store.employees}
        />
      ),
      onClickOut: closeModal,
    });
  }
  const updateEmployees = async () => {
    if (user.profile === "hall") {
      await store.getEmployees(String(user.id));
      setEnableModal(true);
    } else {
      setEnableModal(true);
    }
  };
  useEffect(() => {
    updateEmployees();
  }, []);

  return (
    <div>
      <ModalHeader onGoBack={onGoBack} title="Detalhes" />
      {moment().format("HH:mm") <=
        moment(dayCustomer.start_time).zone("-0300").format("HH:mm") && (
          <Icons>
            {enableModal && (
              <EditableButton
                onClick={() => {
                  handleInsertManual(dayCustomer);
                }}
              >
                <FiEdit2 />
              </EditableButton>
            )}
            <DeleteButton
              onClick={() => {
                store.deleteAppointment(dayCustomer.id);
              }}
            >
              <AiFillDelete />
            </DeleteButton>
          </Icons>
        )}
      <Table>
        <thead>
          <tr>
            <td>Horário</td>
            <td>Cliente</td>
            <td>Telefone</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{moment(dayCustomer.start_time).zone("-0300").format("HH:mm")}</td>
            <td>{dayCustomer.customer.name}</td>
            <td>
              {dayCustomer.customer.phone ?
                <>
                  {dayCustomer.customer.phone}
                  <WhatsAppIcon className="btn-whatsapp" title="Ir para o whats" href={"https://wa.me/" + dayCustomer.customer.phone.replace(/[^0-9]/g, "")} target="_blank" rel="noreferrer">
                    <AiOutlineWhatsApp color="green" />
                  </WhatsAppIcon>
                </>
                : "Não informado"
              }
            </td>
          </tr>
        </tbody>
      </Table>

      <Table>
        <thead>
          <tr>
            <td>Serviço</td>
            <td>Cupom</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {dayCustomer.service_with_provider.name}
              <br />
              {/*
              @TODO: Exibir o metodo de pagamento escolhido
              */}
            </td>
            <td>{dayCustomer.coupon.name}</td>
          </tr>
        </tbody>
      </Table>

      <Table>
        <thead>
          <tr>
            <td>Atendente</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dayCustomer.service_with_provider.user_name}</td>
            <td>{dayCustomer.coupon.name}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ServiceDetails;
