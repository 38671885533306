import { makeObservable, action } from "mobx";

import api from "src/http/api";
import { IServicesProvided } from "src/http/interfaces/IServicesProvided";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public servicesProvided: IServicesProvided[] = [];
  public serviceProvided;
  public loader: LoadingStore;

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      createdOrUpdateServiceProvided: action,
    });
  }

  public createdOrUpdateServiceProvided = async (
    onSuccess: () => void,
    serviceProvided,
  ) => {
    try {
      this.loader.start();

      await api.createdOrUpdateServiceProvided(serviceProvided);

      if (serviceProvided.id) {
        this.showSuccess("Serviço editado com sucesso");
      } else {
        this.showSuccess("Serviço cadastrado com sucesso");
      }
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public getOneServiceProvided = async (id) => {
    try {
      this.loader.start();
      this.serviceProvided = await api.getOneServiceProvided(id);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public inactivateService = async (onSuccess: () => void, id) => {
    try {
      this.loader.start();

      await api.inactivateService(id);
      this.showSuccess("Serviço inativado com sucesso!");
      onSuccess();
    } catch (e) {
      this.showError(e.message);
    } finally {
      this.loader.end();
    }
  };
}
