import { action, makeObservable } from "mobx";

import api from "src/http/api";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private loader: LoadingStore;
  public formController = new FormControllerStore({
    password: "",
    confirmPassword: "",
  });

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      changePassword: action,
    });
  }

  public changePassword = async (
    resetPasswordToken: string,
    onSuccess: CallableFunction,
  ) => {
    try {
      this.loader.start();
      const { password, confirmPassword } = this.formController.getFields();
      await api.changePassword(resetPasswordToken, password, confirmPassword);
      onSuccess();
      this.showSuccess(
        "Senha alterada com sucesso! Você já pode entrar com sua nova senha.",
      );
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
