/* eslint-disable @typescript-eslint/no-unsafe-call */
import { observer } from "mobx-react";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import useQuery from "src/hooks/useQuery";
import theme from "src/theme";
import { Logo, Button, Input, Footer } from "src/ui/components";
import NewPlansScreen from "../NewPlansScreen";

import RecoveryPasswordScreen from "../RecoveryPasswordScreen";
import RegisterBuyPlanScreen from "../RegisterBuyPlanScreen";
import ResetPasswordScreen from "../ResetPasswordScreen";
import { Container, ScreenContainer } from "./styles";

interface IProps {
  currentAction?: "reset-password" | "recovery-password" | "register" | "build";
}

const LoginScreen: React.FC<IProps> = observer((props) => {
  const { currentAction } = props;
  const { authStore, uiStore } = useGlobalStore();
  const { showModal, closeModal } = useModal();
  const history = useHistory();
  const queryParams = useQuery();

  const onPressLogin = (event: any) => {
    event.preventDefault();
    authStore.login(() => history.replace("/"));
  };

  useEffect(() => {
    if (currentAction) {
      if (currentAction === "reset-password") {
        const resetPasswordToken = queryParams.get("reset_password_token");

        if (!resetPasswordToken) {
          return uiStore.showError(
            "Você precisa de um token valido para poder trocar sua senha, verifique seu e-mail!",
          );
        }
        showModal({
          content: (
            <ResetPasswordScreen
              resetPasswordToken={resetPasswordToken}
              closeModal={closeModal}
            />
          ),
          showCloseButton: true,
          onClickOut: closeModal,
        });
      } else if (currentAction === "register") {
        showModal({
          content: <NewPlansScreen closeModal={closeModal} />,
          onClickOut: closeModal,
          size: "small",
        });
      } else if (currentAction === "build") {
        showModal({
          content: <RegisterBuyPlanScreen closeModal={closeModal} />,
          position: "left",
        });
      }
    }
  }, []);

  return (
    <ScreenContainer>
      <Container onSubmit={onPressLogin}>
        <div className="logo-wrapper">
          <Logo />
        </div>
        <div className="form-wrapper">
          <h1>Entrar</h1>
          <Input
            label="E-mail"
            {...authStore.formController.getField("email")}
            type="email"
          />
          <Input
            label="Senha"
            {...authStore.formController.getField("password")}
            type="password"
          />
          <a
            className="forgot-password"
            href="#"
            onClick={() =>
              showModal({
                content: <RecoveryPasswordScreen closeModal={closeModal} />,
                showCloseButton: true,
                onClickOut: closeModal,
                size: "small",
              })
            }
          >
            Esqueci minha senha
          </a>
          <Button
            title="Entrar"
            color={theme.colors.purpleDark}
            mb={15}
            type="submit"
          />
          {/* <Button
            title="Conhecer planos"
            color={theme.colors.orangeDark}
            mb={15}
            outlined
            onClick={(e) => {
              e.preventDefault();
              showModal({
                content: <NewPlansScreen closeModal={closeModal} />,
                onClickOut: closeModal,
                size: "small",
              });
            }}
          />
          <CallToActionButton
            title="Experimente grátis"
            onClick={() =>
              showModal({
                content: <RegisterScreen closeModal={closeModal} />,
                onClickOut: closeModal,
                position: "left",
              })
            }
          /> */}
        </div>
      </Container>
      <Footer />
    </ScreenContainer>
  );
});

export default LoginScreen;
