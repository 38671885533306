import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  > .message {
    display: flex;
    flex: 1;
    flex-direction: column;
    > h1 {
      font-size: ${({ theme }) => theme.fontSizes.s24}px;
      color: ${({ theme }) => theme.colors.greyDarker};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
    > p {
      font-size: ${({ theme }) => theme.fontSizes.s14}px;
      color: ${({ theme }) => theme.colors.greyDarker};
    }
  }
`;
