import styled, { css } from "styled-components";

interface ContainerProps {
  rowStyle?: boolean;
  color?: string;
}

export const Container = styled.div<ContainerProps>`
  margin-bottom: 27px;
  ${({ rowStyle }) =>
    rowStyle &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `}
  > label {
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    display: block;
    ${({ rowStyle }) =>
      rowStyle
        ? css`
            margin-right: 15px;
          `
        : css`
            margin-bottom: 8px;
          `}
  }
  > input {
    ${({ rowStyle }) =>
      rowStyle
        ? css`
            display: flex;
            flex: 1;
          `
        : css`
            width: 100%;
          `}
    height: 55px;
    border-radius: 5px;
    border: 2px solid
      ${({ theme, color }) => (color ? color : theme.colors.purple)};
    padding: 0 15px;
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.greyDarker};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    cursor: pointer;
    background-color: white;
  }

  > input::-webkit-calendar-picker-indicator,
  > input::-webkit-datetime-edit {
    font-size: 18px;
    cursor: pointer;
  }
`;
