import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { IPlan } from "src/http/api";
import theme from "src/theme";
import { Select, Input, Button, ModalHeader } from "src/ui/components";

interface IProps {
  onPressContinue: () => void;
  plan: IPlan;
  formController: any;
  states: any[];
  cities: any[];
  onChangeZipCode: (e: any) => void;
  onGoBack: () => void;
  getCitiesByState: (stateId: number) => Promise<void>;
  getStates: () => Promise<void>;
}

const InitialForm: React.FC<IProps> = (props) => {
  const {
    onPressContinue,
    plan,
    formController,
    states,
    cities,
    onChangeZipCode,
    onGoBack,
    getCitiesByState,
    getStates,
  } = props;

  const paymentMethods = [
    { name: "Cartão", value: "credit_card" },
    { name: "Boleto", value: "bank_slip" },
    { name: "Pix", value: "pix" },
  ];

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (formController.getField("state_id").value) {
      getCitiesByState(parseInt(formController.getField("state_id").value));
    }
  }, [formController.getField("state_id").value]);

  return (
    <div className="payment-form">
      <ModalHeader onGoBack={onGoBack} title="Preencha seus dados" />
      <Input
        label="Plano selecionado"
        value={plan.name}
        disabled
        style={{ textTransform: "capitalize" }}
      />
      <Select
        label="Escolha a forma de pagamento"
        options={paymentMethods}
        {...formController.getField("paymentMethod")}
      />
      <Input
        label="CEP"
        maxLength={10}
        {...formController.getField("zip_code")}
        onChange={(e) => onChangeZipCode(e)}
      />
      <Input label="Rua" {...formController.getField("street")} />
      <Input label="Número" {...formController.getField("number")} />
      <Input label="Bairro" {...formController.getField("neighborhood")} />
      <Input label="Complemento" {...formController.getField("complement")} />
      <Select
        label="Cidade"
        options={cities.map((city) => ({
          name: city.name,
          value: city.id,
        }))}
        {...formController.getField("city_id")}
      />
      <Select
        label="Estado"
        options={states.map((state) => ({
          name: state.name,
          value: state.id,
        }))}
        {...formController.getField("state_id")}
      />
      <Button
        title="Continuar"
        color={theme.colors.purpleDark}
        onClick={() => onPressContinue()}
      />
    </div>
  );
};
export default observer(InitialForm);
