import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin-bottom: 30px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  table {
    border-collapse: collapse;
    width: 100%;

    thead {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.greyLighter}`};
      color: ${({ theme }) => theme.colors.greyDark};
    }

    thead > tr > th {
      color: ${({ theme }) => theme.colors.greyDark};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      text-align: start;
      font-size: ${({ theme }) => `${theme.fontSizes.s18}px`};
    }

    tbody > tr {
      padding: 20px;
    }

    tbody > tr > td {
      font-size: ${({ theme }) => `${theme.fontSizes.s16}px`};
      color: ${({ theme }) => theme.colors.greyDarker};
    }

    th {
      padding: 10px;
    }

    td {
      padding: 5px;
    }
  }

  .icon-open-details {
    fill: ${({ theme }) => theme.colors.orangeDark};

    text-align: center;
    vertical-align: middle;
  }
`;
