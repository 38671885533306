/* eslint-disable @typescript-eslint/restrict-template-expressions */
import client from "./client";
import { ICompany } from "./interfaces/ICompany";
import { IDaysAndTimes } from "./interfaces/IDaysAndTimes";
import { IFinancials, IResumeFinancials } from "./interfaces/IFinancials";
import { IHours } from "./interfaces/IHours";
import { INewSocialNetworking } from "./interfaces/INewSocialNetworking";
import { IPreferences } from "./interfaces/IPreferences";
import { IServicesProvided } from "./interfaces/IServicesProvided";
import { ISocialNetworkingCompany } from "./interfaces/ISocialNetworkingCompany";
import { IUsers } from "./interfaces/IUsers";
import { IEmployees } from "./interfaces/IEmployees";

export interface IPlan {
  active: boolean;
  id: number;
  identifier: string;
  name: string;
  description: string;
  billing_explanation: string;
  value_cents_with_discount: number;
  value_cents_without_discount: number;
  value_by_month_without_discount: number;
  value_by_month_with_discount: number;
  image: string;
}

export interface IUser {
  id: number;
  provider: string;
  uid: string;
  allow_password_change: boolean;
  name: string;
  email: string;
  cpf_cnpj: string;
  phone: string;
  status: string;
  player_ids: string[];
  iugu_id?: string;
  phone_prefix: string;
  acting: string;
  profile: string;
  created_at: string;
  updated_at: string;
  user_provider_id?: number;
  auth_params: AuthParams;
  last_login?: Date;
  my_company: any;
}

export interface DaysToEndFreeTrial {
  days: number;
  free_trial: boolean;
}

export interface AuthParams {
  access_token: string;
  client: string;
  uid: string;
}

export interface INewUser {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  cpf_cnpj: string;
  phone: string;
  phone_prefix: string;
  acting: string;
  profile: string;
  player_ids: string;
  reference?: string;
  plan?: number;
  quantityEmployees?: string;
}

export interface CardParams {
  number: string;
  verification_value: string;
  first_name: string;
  last_name: string;
  month: string;
  year: string;
  installments: number;
}

export interface INewPayment {
  plan_id: number;
  payment_method: string;
  card_params?: CardParams;
  refer_key?: string;
  user_address: {
    zip_code: string;
    number: string;
    street: string;
    neighborhood: string;
    city: string;
    state: string;
    complement: string;
    address_charge: boolean;
  };
}

export interface IQuantityEmployees {
  id: number;
  description: string;
}

export interface IOrder {
  barcode: string;
  id: number;
  line: string;
  message: string;
  order_number: string;
  order_data: string;
  qrcode: string;
  qrcode_text: string;
  status_code: number;
  invoice?: string;
}

export interface IInvoiceSubscriptions {
  plan_expired: string;
  plan_identifier: string;
  recent_invoice: string;
  subscription: string;
  subscription_status: string;
}
export interface IUpdateSubscription {
  plan_id: string;
  subscription_id: string;
  payment_method: string;
  card_params?: CardParams;
}

export interface ICheckPayment {
  invoice_status: string;
}

export default {
  login: async (email: string, password: string): Promise<IUser> => {
    const response = await client.post<IUser>("v1/auth/user/sign_in", {
      email,
      password,
    });

    const user: IUser = {
      ...response.data,
      auth_params: {
        access_token: response.headers["access-token"],
        client: response.headers.client,
        uid: response.headers.uid,
      },
    };

    return user;
  },
  getPlans: async (quantityEmployees?: string): Promise<IPlan[]> => {
    let url = "storefront/v1/plans";
    if (quantityEmployees) {
      url += "?quantityEmployees=" + quantityEmployees;
    }
    const response = await client.get<{ data: IPlan[] }>(url);
    return response.data.data;
  },
  getUpgradePlans: async (userId: string): Promise<IPlan[]> => {
    const url = "storefront/v1/plans/options_to_upgrade?user_id=" + userId;
    const response = await client.get(url);
    return response.data.available_plans;
  },
  register: async (newUser: INewUser): Promise<IUser> => {
    const response = await client.post<IUser>("v1/auth/user", newUser);
    const user: IUser = {
      ...response.data,
      auth_params: {
        access_token: response.headers["access-token"],
        client: response.headers.client,
        uid: response.headers.uid,
      },
    };

    return user;
  },
  recoveryPassword: async (email: string): Promise<IUser> => {
    const response = await client.post<{ data: IUser }>(
      "v1/auth/user/password",
      { email, redirect_url: "https://app.facilitelink.com.br/alterar-senha" },
    );
    return response.data.data;
  },
  getDaysToEndTestPeriod: async (): Promise<DaysToEndFreeTrial> => {
    const response = await client.get<DaysToEndFreeTrial>(
      "v1/auth/users/days_end_free_trial",
    );
    return response.data;
  },
  getUserStatus: async (): Promise<IInvoiceSubscriptions> => {
    const response = await client.get<IInvoiceSubscriptions>(
      "v1/auth/users/status",
    );
    return response.data;
  },
  changePassword: async (
    resetPasswordToken: string,
    password: string,
    confirmPassword: string,
  ): Promise<IUser> => {
    const response = await client.patch<{ data: IUser }>(
      "v1/auth/user/password",
      {
        reset_password_token: resetPasswordToken,
        password,
        password_confirmation: confirmPassword,
      },
    );
    return response.data.data;
  },
  createPayment: async (newPayment: INewPayment): Promise<IOrder> => {
    const response = await client.post<IOrder>(
      "storefront/v1/orders",
      newPayment,
    );
    return response.data;
  },
  changeStatusEmployee: async (employeeId: string): Promise<any> => {
    const response = await client.post<IOrder>(
      `storefront/v1/employees/${employeeId}/status_toggle`,
      {},
    );
    return response.data;
  },
  getUserCompany: async (): Promise<ICompany> => {
    const response = await client.get<ICompany>(
      "/storefront/v1/companies/by_user",
    );
    return response.data;
  },
  searchCPFCNPJ: async (cpfCnpjTemp): Promise<any> => {
    let cpfCNPJ = cpfCnpjTemp.trim();
    cpfCNPJ = cpfCNPJ
      .split("/")
      .join("")
      .split("-")
      .join("")
      .split(".")
      .join("");
    const response = await client.get<any>(
      `/storefront/v1/search?cpf_cnpj=${cpfCNPJ}`,
    );
    return response;
  },
  getCompanySocialNetworking: async (
    companyId: number,
  ): Promise<ISocialNetworkingCompany[]> => {
    const response = await client.get<ISocialNetworkingCompany[]>(
      `/storefront/v1/social_networks?company_id=${companyId || ""}`,
    );
    return response.data;
  },
  getSocialNetworking: async (): Promise<INewSocialNetworking[]> => {
    const response = await client.get<INewSocialNetworking[]>(
      "/storefront/v1/company_social_networks",
    );
    return response.data;
  },
  editSocialNetworking: async (
    social_networks: INewSocialNetworking[],
    company_id?: number,
  ): Promise<INewSocialNetworking[]> => {
    const response = await client.post<INewSocialNetworking[]>(
      "/storefront/v1/company_social_networks",
      {
        company_id,
        social_networks,
      },
    );
    return response.data;
  },
  editCompany: async (id: string, company: ICompany): Promise<ICompany> => {
    const payload = new FormData();
    payload.append("company[name]", company.name);
    payload.append("company[address]", company.address);
    payload.append("company[font_color]", company.font_color);
    payload.append("company[primary_color]", company.primary_color);
    if (company.avatar) {
      payload.append("company[avatar]", company.avatar);
    }
    payload.append("company[secondary_color]", company.secondary_color);

    const response = await client.patch<ICompany>(
      `/storefront/v1/companies/${id}`,
      payload,
    );
    return response.data;
  },
  getDaysAndTimes: async (user_id?: string): Promise<IDaysAndTimes[]> => {
    const url = "storefront/v1/working_days";

    const response = await client.get<IDaysAndTimes[]>(url, {
      params: { user_id },
    });
    return response.data;
  },
  getServicesProvided: async (
    user_id?: string,
  ): Promise<IServicesProvided[]> => {
    let url = "storefront/v1/services";
    if (user_id) {
      url += "?user_id=" + user_id;
    }
    const response = await client.get<IServicesProvided[]>(url);
    return response.data;
  },
  getOneServiceProvided: async (
    id: number | string,
  ): Promise<IServicesProvided> => {
    const response = await client.get<IServicesProvided>(
      `/storefront/v1/services/${id}`,
    );
    return response.data;
  },
  getHoursAvailable: async (
    id: number | string,
    startTime: string,
  ): Promise<IHours[]> => {
    const response = await client.get<IHours[]>(
      `/storefront/v1/appointments/scheduler_link?start_time=${startTime}&service_id=${id}`,
    );
    return response.data;
  },
  getFinancials: async (
    filters,
    userId?: string,
  ): Promise<IResumeFinancials> => {
    let url = "storefront/v1/financials/resume";
    if (userId) {
      url += "?user_id=" + userId;
    }
    const response = await client.get<IResumeFinancials>(url, {
      params: filters,
    });
    return response.data;
  },
  getOneFinancial: async (id: string): Promise<IResumeFinancials> => {
    const response = await client.get<{ data: IResumeFinancials }>(
      `storefront/v1/financials/${id}`,
    );
    return response.data.data;
  },
  createFinancial: async (financial) => {
    const response = await client.post<{ data: IFinancials }>(
      "storefront/v1/financials",
      {
        financial,
      },
    );
    return response.data;
  },
  updateFinancial: async (
    id: string,
    paid: boolean,
    user_id: string,
    discount: number,
  ) => {
    const response = await client.patch<IFinancials>(
      `storefront/v1/financials/${id}`,
      {
        paid,
        user_id,
        discount,
      },
    );
    return response.data;
  },
  deleteFinancial: async (id: string) => {
    const response = await client.delete(`storefront/v1/financials/${id}`);
    return response;
  },
  getTodayExpenses: async (): Promise<IResumeFinancials> => {
    const response = await client.get<IResumeFinancials>(
      "storefront/v1/financials/outputs",
    );
    return response.data;
  },
  createdOrUpdateServiceProvided: async (
    serviceProvided: IServicesProvided,
  ): Promise<IServicesProvided> => {
    if (serviceProvided.id) {
      const response = await client.patch<IServicesProvided>(
        `storefront/v1/services/${serviceProvided.id}`,
        serviceProvided,
      );
      return response.data;
    } else {
      const response = await client.post<IServicesProvided>(
        "storefront/v1/services",
        serviceProvided,
      );
      return response.data;
    }
  },
  inactivateService: async (id: string) => {
    const response = await client.patch<IServicesProvided>(
      `storefront/v1/services/${id}`,
      {
        status: "inactive",
      },
    );
    return response.data;
  },
  getUsers: async (): Promise<IUsers[]> => {
    const response = await client.get<IUsers[]>("/v1/auth/users");
    return response.data;
  },
  getUser: async (id: number | string): Promise<IUsers> => {
    const response = await client.get<IUsers>(`/v1/auth/user/${id}`);
    return response.data;
  },
  createOrUpdateUser: async (user, helper?: boolean): Promise<IUsers> => {
    if (user.id) {
      if (helper) {
        const payload = new FormData();
        payload.append("users_registration[name]", user.name);
        if (user.image) {
          payload.append("users_registration[image]", user.image);
        }
        const response = await client.patch<IUsers>(
          `v1/auth/user/${user.id}`,
          payload,
        );
        return response.data;
      } else {
        const response = await client.patch<IUsers>(
          `v1/auth/user/${user.id}`,
          user,
        );
        return response.data;
      }
    } else {
      const payload = new FormData();
      payload.append("users_registration[name]", user.name);
      payload.append("users_registration[email]", user.email);
      payload.append("users_registration[password]", user.password);
      payload.append(
        "users_registration[password_confirmation]",
        user.password_confirmation,
      );
      payload.append("users_registration[profile]", user.profile);
      payload.append(
        "users_registration[user_provider_id]",
        user.user_provider_id,
      );
      payload.append("users_registration[status]", user.status);
      if (user.image) {
        payload.append("users_registration[image]", user.image);
      }
      const response = await client.post<IUsers>("v1/auth/user", payload);
      return response.data;
    }
  },
  inactivateUser: async (id: string) => {
    const response = await client.patch<IServicesProvided>(
      `storefront/v1/users/${id}`,
      {
        status: "inactive",
      },
    );
    return response.data;
  },
  createDaysAndTimes: async (
    daysAndTimes: IDaysAndTimes,
  ): Promise<IDaysAndTimes> => {
    const response = await client.post<IDaysAndTimes>(
      "storefront/v1/working_days",
      daysAndTimes,
    );
    return response.data;
  },
  updatePreferences: async (
    userId: number,
    preferences,
  ): Promise<IPreferences> => {
    const response = await client.patch<{ data: IPreferences }>(
      `/storefront/v1/user/${userId}/preferences`,
      preferences,
    );
    return response.data.data;
  },
  updateUserPreferencesIndividual: async (
    idCompany: number,
    preferences,
  ): Promise<IPreferences> => {
    const response = await client.patch<{ data: IPreferences }>(
      `/storefront/v1/companies/${idCompany}/preferences`,
      preferences,
    );
    return response.data.data;
  },
  getPreferences: async (idCompany: number): Promise<IPreferences> => {
    const response = await client.get<{ data: IPreferences }>(
      `/storefront/v1/companies/${idCompany}/preferences`,
    );
    return response.data.data;
  },
  getUserPreferences: async (idUser: number): Promise<IPreferences> => {
    const response = await client.get<IPreferences>(
      `/storefront/v1/users/${idUser}/preferences`,
    );
    return response.data;
  },
  updateUserPreferences: async (
    idUser: number,
    preferences,
  ): Promise<IPreferences> => {
    const response = await client.patch<{ data: IPreferences }>(
      `/storefront/v1/users/${idUser}/preferences`,
      preferences,
    );
    return response.data.data;
  },
  updateSubscription: async (subscription) => {
    const response = await client.patch(
      "/storefront/v1/subscriptions/update",
      subscription,
    );

    return response;
  },
  checkPayment: async (invoice: string): Promise<ICheckPayment> => {
    const response = await client.get<ICheckPayment>(
      `/storefront/v1/invoices/status?invoice=${invoice}`,
    );
    return response.data;
  },
  getEmployees: async (idUser: string): Promise<IEmployees> => {
    const response = await client.get<IEmployees>(
      `/storefront/v1/users/${idUser}/employees`,
    );
    return response.data;
  },
  newGetEmployees: async (idUser: string): Promise<IEmployees[]> => {
    const response = await client.get<IEmployees[]>(
      `/storefront/v1/users/${idUser}/employees`,
    );
    return response.data;
  },
  getQuantityEmployees: async (): Promise<any> => {
    const response = await client.get<any>(
      "/storefront/v1/employees_quantity_ranges",
    );
    return response.data;
  },
  changeEmployeePassword: async (password: string) => {
    await client.patch<any>("/v1/auth/user/password", {
      password,
      password_confirmation: password,
    });
  },
};
