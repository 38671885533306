import client from "src/http/client";

export interface ICoupon {
  created_at: string;
  ends_at: string;
  id: number;
  name: string;
  reuse: boolean;
  starts_at: string;
  type_coupon: number;
  updated_at: string;
  user_id: number;
  value: string;
}

export interface newCoupon {
  name: string;
  starts_at: string;
  ends_at: string;
  type_coupon: number;
  value: string;
  reuse: boolean;
}

export interface NumCoupon {
  qty: number;
}

export default class couponsService {
  public async getCoupons() {
    const response = await client.get<ICoupon[]>("/storefront/v1/coupons");
    return response.data;
  }

  public async getCouponById(id: number) {
    const response = await client.get<ICoupon>(`/storefront/v1/coupons/${id}`);
    return response.data;
  }

  public async createCoupon(newCoupon: newCoupon) {
    await client.post("storefront/v1/coupons", newCoupon);
  }

  public async updateCoupon(coupon: ICoupon) {
    await client.patch(`storefront/v1/coupons/${coupon.id}`, coupon);
  }

  public async deleteCoupon(id: number) {
    const response = await client.delete(`storefront/v1/coupons/${id}`);
    return response;
  }

  public async validateCoupon(companyId: string, coupon: string) {
    const response = await client.get<ICoupon[]>(
      `/storefront/v1/coupons/validate_coupon?coupon=${coupon}`,
      {
        headers: {
          user_id: companyId,
        },
      },
    );
    return response.data;
  }

  public async countCoupon(companyId: string) {
    const response = await client.get<NumCoupon>(
      "/storefront/v1/coupons/count_coupon",
      {
        headers: {
          user_id: companyId,
        },
      },
    );

    return response.data;
  }
}
