import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 0;
  > .horary-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -7px 40px -7px;
    > .complete {
      flex: 1;
    }
  }

  > .container-list-service {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    > .button-service {
      margin: 10px 5px 0;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;

      transition: 0.2s ease-out;

      &:hover {
        opacity: 0.6;
      }

      @media (max-width: 400px) {
        font-size: 12px;
        width: 70px;
      }
    }
  }
`;

export const CardInner = styled.div`
  padding: 16px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 45px;
  > button.go-back {
    border: none;
    background: transparent;
    margin-right: 26px;
  }
  > h1 {
    font-size: ${({ theme }) => theme.fontSizes.s24}px;
    color: ${({ theme }) => theme.colors.purpleDark};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const Loading = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  > .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    > div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #aaa;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    > div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    > div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    > div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    > div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
