import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import { FaPhone } from "react-icons/fa";

import { useGlobalStore } from "src/context/global";
import { Table, Card, CardInner } from "src/ui/components/index";

import Store from "./store";
import { Container } from "./styles";

const ContactUs: React.FC = () => {
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  useEffect(() => {
    store.getContactUs();
  }, []);

  return (
    <Container>
      <Card>
        <CardInner>
          <Table>
            <thead>
              <tr>
                <td colSpan={3}>Telefone</td>
              </tr>
            </thead>

            <tbody>
              {store.contactUs.map((contact) => (
                <tr key={contact.title}>
                  <td>{contact.title}</td>
                  <td>
                    <a href={`tel:${contact.phone}`}>
                      {contact.phone} <FaPhone />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardInner>
      </Card>
    </Container>
  );
};

export default observer(ContactUs);
