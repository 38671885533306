/* eslint-disable @typescript-eslint/no-unsafe-return */
import { observer } from "mobx-react";
import React from "react";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";

import { useGlobalStore } from "src/context/global";
import { routerUtils } from "src/http/client";
import CouponScreen from "src/ui/pages/CouponScreen";
import EmployeeProfileScreen from "src/ui/pages/EmployeeProfileScreen";
import PlansScreen from "src/ui/pages/PlansScreen";
import RegisterAndConfig from "src/ui/pages/RegisterAndConfig";
import RegisterEmployee from "src/ui/pages/RegisterEmployee";

import ClientScreen from "../ui/pages/ClientScreen";
import ConfigurationScreen from "../ui/pages/ConfigurationScreen";
import CustomizeScreen from "../ui/pages/CustomizeScreen";
import FinancialScreen from "../ui/pages/FinancialScreen";
import LoginScreen from "../ui/pages/LoginScreen";
import ScheduleScreen from "../ui/pages/ScheduleScreen";
import SupportScreen from "../ui/pages/SupportScreen";
// import EmployeeScreen from "src/ui/pages/EmployeeScreen";
// import DashboardScreen from "../ui/pages/DashboardScreen";
// import NotificationListScreen from "../ui/pages/NotificationListScreen";
// import AdminFinancialScreen from "../ui/pages/AdminFinancialScreen";
// import AdminListScreen from "../ui/pages/AdminListScreen";
// import ProviderScreen from "../ui/pages/ProviderScreen";

// const AdminRoutes = () => (
//   <Switch>
//     <Route path="/financeiro" component={AdminFinancialScreen} />
//     <Route path="/notificacoes" component={NotificationListScreen} />
//     <Route path="/usuarios" component={AdminListScreen} />
//     <Route path="/dashboard" component={DashboardScreen} />
//     <Route path="/prestadores" component={ProviderScreen} />
//   </Switch>
// );

const ServiceProviderRoutes = () => {
  const history = useHistory();
  routerUtils.setupRouterUtils(history);
  return (
    <Switch>
      <Route exact path="/" component={ScheduleScreen} />
      <Route exact path="/planos" component={PlansScreen} />
      <Route exact path="/financeiro" component={FinancialScreen} />
      <Route exact path="/configuracoes" component={ConfigurationScreen} />
      <Route exact path="/profissionais" component={ConfigurationScreen} />
      <Route exact path="/customizar" component={CustomizeScreen} />
      <Route exact path="/suporte" component={SupportScreen} />
      <Route exact path="/cupons" component={CouponScreen} />
      <Route exact path="/registarConfigurar" component={RegisterAndConfig} />
      <Route exact path="/cadastrarFuncionario" component={RegisterEmployee} />
      <Route
        exact
        path="/alterarConfiguracoesUsuario"
        component={EmployeeProfileScreen}
      />
    </Switch>
  );
};

const UnauthorizedRoutes = () => (
  <Switch>
    <Route exact path="/" component={LoginScreen} />
    <Route exact path="/alterar-senha">
      <LoginScreen currentAction="reset-password" />
    </Route>
    <Route exact path="/cadastro">
      <LoginScreen currentAction="build" />
    </Route>
    <Route exact path="/assine-agora">
      <LoginScreen currentAction="build" />
    </Route>
  </Switch>
);

const Routes = () => {
  const { authStore } = useGlobalStore();

  useEffect(() => authStore.getCurrentUser(), []);

  return (
    <Router>
      <Switch>
        <Route path="/u/:slug" exact component={ClientScreen} />
        {/* <Route path="/new/:slug" exact component={ClientScreen} /> */}
        {authStore.currentUser ? (
          <ServiceProviderRoutes />
        ) : (
          <UnauthorizedRoutes />
        )}
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};

export default observer(Routes);
