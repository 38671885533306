import React from "react";
import {
  Company,
  Avatar,
  WhatsAppButton,
  CompanyInfo,
  CompanyText,
} from "./styles";
import { ICompany } from "src/services/companies";
import whatsAppWhiteIcon from "src/assets/svgs/whatsapp-white.svg";
import Skeleton from "react-loading-skeleton";
import masks from "src/resources/masks";

interface Props {
  company?: ICompany;
  whatsApp?: string;
  isSmall?: boolean;
  isLoading: boolean;
}

const CompanyCard: React.FC<Props> = ({
  company,
  isLoading,
  isSmall = false,
  whatsApp,
}) => {
  if (isLoading || !company) return <Skeleton height={300} />;

  return (
    <Company
      primaryColor={company.primary_color}
      textColor={company.font_color}
    >
      <CompanyInfo isSmall={isSmall}>
        <Avatar src={company.avatar} />

        <CompanyText>
          <h1>{company.company_name}</h1>
          <p>{company.address}</p>
        </CompanyText>
      </CompanyInfo>

      {whatsApp && (
        <WhatsAppButton
          href={`https://wa.me/${
            whatsApp.startsWith("55") ? whatsApp : "55" + whatsApp
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={whatsAppWhiteIcon} />
          <span>{masks.phone(whatsApp)}</span>
        </WhatsAppButton>
      )}
    </Company>
  );
};

export default CompanyCard;
