import React from "react";

import { Container } from "./styles";

interface IProps {
  primaryColor?: string;
  secondaryColor?: string;
  highlightedTextColor?: string;
  absolute?: boolean;
}

export const Footer: React.FC<IProps> = (props) => {
  const { primaryColor, secondaryColor, highlightedTextColor, absolute } =
    props;
  return (
    <Container
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      highlightedTextColor={highlightedTextColor}
      absolute={absolute}
    >
      <span>
        Uma ferramenta <b>Facilite Link</b>
      </span>
      <hr />
      <p>FACILITE LINK INTERMEDIACAO DE NEGOCIOS LTDA</p>
      <p>CNPJ: 37272042/0001-91</p>
      <p>Av João Sacavem, 571 - Sala 404</p>
      <p>Navegantes - SC</p>
    </Container>
  );
};
