/* eslint-disable @typescript-eslint/restrict-template-expressions */
import client from "src/http/client";

export interface TotalSchedules {
  total_day: number;
  total_week: number;
  data: [
    {
      date: string;
      quantity: number;
    },
  ];
  appointment_blocks: [
    {
      created_at: string;
      ends_at: string;
      id: number;
      starts_at: string;
      updated_at: string;
      user_id: number;
      type: string;
    },
  ];
}

export interface BlockedDays {
  appointment_blocks: [
    {
      created_at: string;
      ends_at: string;
      id: number;
      starts_at: string;
      updated_at: string;
      user_id: number;
      type: string;
    },
  ];
}
export interface DayCustomer {
  id: number;
  start_time: string;
  end_time: string;
  status: string;
  created_at: string;
  updated_at: string;
  coupon: {
    name: string;
  };
  customer: {
    id: number;
    name: string;
    phone: string;
    cpf: string;
  };
  service_with_provider: {
    id: number;
    name: string;
    price: number;
    fixed_price: boolean;
    duration_time: string;
    observation: string;
    user_id: number;
    user_name: string;
  };
}

export interface DayCustomers {
  total_records: number;
  data: DayCustomer[];
}

export interface Appointments {
  total_records: number;
  data: [
    {
      id: number;
      start_time: string;
      end_time: string;
      status: string;
      created_at: string;
      updated_at: string;
      coupon: {
        name: string;
      };
      customer: {
        id: number;
        name: string;
        phone: string;
        cpf: string;
      };
      service_with_provider: {
        id: number;
        name: string;
        price: number;
        fixed_price: true;
        duration_time: string;
        observation: string;
        user_id: number;
        user_name: string;
      };
    },
  ];
}

export interface AvailableHour {
  time: string;
}

export interface NewAppointment {
  start_time: string;
  customer: {
    name: string;
    cpf?: string;
    phone: string;
  };
  payment_method?: number;
  service_id: any;
  coupon_id?: string;
  user_id?: string;
}

export default class AppointmentsService {
  public async getAppointments(
    filters: {
      date?: string;
      status?: string;
      user_id?: string;
    },
    allStatus?: boolean,
  ): Promise<Appointments> {
    let searchParams = "";
    if (!allStatus) {
      if (filters.user_id === undefined) {
        delete filters.user_id;
      }
      searchParams = new URLSearchParams(filters).toString();
    } else {
      if (filters.user_id) {
        searchParams = `status[]=done&status[]=confirmed&date=${filters.date}&user_id=${filters.user_id}`;
      } else {
        searchParams = `status[]=done&status[]=confirmed&date=${filters.date}`;
      }
    }

    const response = await client.get<Appointments>(
      `storefront/v1/appointments?${searchParams}`,
    );
    return response.data;
  }

  public async getSchedule(
    filters: {
      start_time?: string;
      service_id?: string;
      status?: string;
    },
    user_id: string,
  ): Promise<AvailableHour[]> {
    const searchParams = new URLSearchParams(filters);
    const response = await client.get<{ data: AvailableHour[] }>(
      `storefront/v1/appointments/scheduler?${searchParams.toString()}&user_id=${user_id}`,
    );
    return response.data.data;
  }

  public async getScheduleByLink(
    companyId: string,
    filters: {
      start_time?: string;
      service_id?: any;
    },
  ): Promise<AvailableHour[]> {
    if (filters.service_id.length === 0) {
      filters.service_id.push(0);
    }
    const searchParams = new URLSearchParams(filters);
    const response = await client.get<{ data: AvailableHour[] }>(
      `storefront/v1/appointments/scheduler_link?${searchParams.toString()}`,
      {
        headers: {
          user_id: companyId,
        },
      },
    );
    return response.data.data;
  }

  public async getTotalSchedules(): Promise<TotalSchedules> {
    const response = await client.get<TotalSchedules>(
      "storefront/v1/appointments/scheduled",
    );
    return response.data;
  }

  public async getSchedulesOfMonth(
    startDate: string,
    endDate: string,
    userId?: string,
  ): Promise<TotalSchedules> {
    const user = userId ? "&user_id=" + userId : "";
    const response = await client.get<TotalSchedules>(
      `storefront/v1/appointments/scheduled?date_from=${startDate}&date_to=${endDate}${user}`,
    );
    return response.data;
  }

  public async getSchedulesOfMonthByLink(
    companyId: string,
    startDate: string,
    endDate: string,
  ): Promise<TotalSchedules> {
    const response = await client.get<TotalSchedules>(
      `storefront/v1/appointments/scheduled_link?date_from=${startDate}&date_to=${endDate}`,
      {
        headers: {
          user_id: companyId,
        },
      },
    );
    return response.data;
  }

  public async getDayCustomers(userId?: string): Promise<DayCustomers> {
    let url = "storefront/v1/appointments/day_customers";
    if (userId) {
      url += "?user_id=" + userId;
    }

    const response = await client.get<DayCustomers>(url);
    return response.data;
  }

  public async acceptAppointment(appointmentId: number) {
    await client.patch(`storefront/v1/appointments/${appointmentId}`, {
      status: "confirmed",
    });
  }

  public async declineAppointment(appointmentId: number) {
    await client.patch(`storefront/v1/appointments/${appointmentId}`, {
      status: "refused",
    });
  }

  public async createAppointment(newAppointment: NewAppointment) {
    await client.post("storefront/v1/appointments", newAppointment);
  }

  public async createAppointmentByLink(
    newAppointment: NewAppointment,
    companyId: string,
  ) {
    await client.post("storefront/v1/appointments/link", newAppointment, {
      headers: {
        user_id: companyId,
      },
    });
  }

  public async deleteAppointment(id: number) {
    return await client.delete(`storefront/v1/appointments/${id}`);
  }

  public async blockAppointment(date: string) {
    await client.post("storefront/v1/appointment_blocks", {
      appointment_blocks: [
        {
          kind: "day",
          starts_at: date,
        },
      ],
    });
  }

  public async unblockAppointment(id: number) {
    await client.delete(`storefront/v1/appointment_blocks/${id}`);
  }
}
