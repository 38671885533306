import { observer, useLocalStore } from "mobx-react";
import React from "react";

import SendIcon from "src/assets/svgs/send.svg";
import { useGlobalStore } from "src/context/global";
import theme from "src/theme";

import { Input, Button } from "../../components";
import Store from "./store";
import { Container } from "./styles";

interface RecoveryPasswordScreenProps {
  closeModal: () => void;
}

const RecoveryPasswordScreen: React.FC<RecoveryPasswordScreenProps> = (
  props,
) => {
  const { closeModal } = props;
  const { loadingStore } = useGlobalStore();
  const store = useLocalStore(() => new Store(loadingStore));

  return (
    <Container>
      <div>
        <img src={SendIcon} />
        <h1>Recuperar senha</h1>
        <span>
          Digite seu e-mail cadastrado para receber o passo a passo de
          recuperação.
        </span>
        <Input
          placeholder="seu@email.com"
          style={{ marginBottom: 40 }}
          type="email"
          {...store.formController.getField("email")}
        />
      </div>
      <Button
        title="Enviar e-mail"
        color={theme.colors.orangeDark}
        style={{ marginBottom: 50 }}
        onClick={() => store.recoveryPassword(closeModal)}
      />
    </Container>
  );
};

export default observer(RecoveryPasswordScreen);
