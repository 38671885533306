import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  z-index: 2;
  @media (max-width: ${({ theme }) => theme.dimentions.tablet}px) {
    display: none;
  }
`;

export const MenuOut = styled.div`
  flex: 1;
  height: 100%;
`;

export const Menu = styled.div`
  width: 250px;
  height: 100%;
  background: ${({ theme }) => theme.colors.purpleDark};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > ul {
    width: 100%;
    list-style: none;
    > img {
      width: 180px;
      padding: 20px;
      margin: 20px auto 30px auto;
      display: block;
      object-fit: contain;
    }
    li:hover {
      background-color: ${({ theme }) => theme.colors.greyLighter};
      a {
        color: ${({ theme }) => theme.colors.purpleDark};
        width: 100%;
      }
    }
    > li {
      cursor: pointer;
      display: flex;
      align-items: center;
      > a {
        display: block;
        padding-left: 20px;
        line-height: 60px;
        color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSizes.s18}px;
        width: 100%;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        > svg {
          margin-right: 15px;
        }
      }
    }
  }
`;
