import React from "react";
import { MdClose } from "react-icons/md";

import { Container, Header } from "./styles";

export type ModalPosition = "center" | "right" | "left";
export type ModalLargeSize = "large" | "middle" | "small";

export interface ModalProps {
  visible: boolean;
  content?: React.ReactElement;
  closeModal?: () => void;
  showCloseButton?: boolean;
  onClickOut?: () => void;
  position?: ModalPosition;
  size?: ModalLargeSize;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    visible,
    content,
    closeModal,
    showCloseButton,
    onClickOut,
    position = "center",
    size = "large",
  } = props;
  return (
    <>
      {visible && (
        <Container position={position} size={size}>
          <div className="modal-out" onClick={onClickOut} />
          <div className="modal">
            {showCloseButton && (
              <Header>
                <button className="close" onClick={closeModal}>
                  <MdClose size={30} />
                </button>
              </Header>
            )}
            {content}
          </div>
        </Container>
      )}
    </>
  );
};
