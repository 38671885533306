import styled from "styled-components";

export const Container = styled.div`
  > .plans-container {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
  .rec-slider-container {
    margin: 0 !important;
  }
  .rec-dot {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.grey};
    box-shadow: none !important;
  }
  .rec-dot_active {
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background: ${({ theme }) => theme.colors.orangeDark};
  }
`;
