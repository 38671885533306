import React from "react";

import { Container } from "./styles";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hour: string;
  selected?: boolean;
  color?: string;
}

export const Horary: React.FC<IProps> = (props) => {
  const { hour, selected, color } = props;
  return (
    <Container selected={selected} type="button" color={color} {...props}>
      {hour}
    </Container>
  );
};
