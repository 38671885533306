import React from "react";
import { InputHTMLAttributes } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { Container } from "./styles";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  required?: boolean;
  style?: React.CSSProperties;
}

export const Input: React.FC<InputProps> = (props) => {
  const { label, required, placeholder, style, type, ...rest } = props;
  const [visible, setVisible] = React.useState(false);

  return (
    <Container>
      {label && (
        <label>
          {label} {required && <span>*</span>}
        </label>
      )}
      <div className="input-container">
        <input
          placeholder={placeholder}
          style={style}
          type={visible === true ? "text" : type}
          {...rest}
        />
        {type === "password" && (
          <button
            type="button"
            className="toggleVisible"
            onClick={() => setVisible(!visible)}
          >
            {visible ? (
              <AiFillEye size={20} />
            ) : (
              <AiFillEyeInvisible size={20} />
            )}
          </button>
        )}
      </div>
    </Container>
  );
};
