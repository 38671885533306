import styled from "styled-components";

interface ICheckbox {
  colorBorder?: string;
}

export const Container = styled.label<ICheckbox>`
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #4f4f4f;
  cursor: pointer;
  > a {
    text-decoration: underline;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 23.69px;
    height: 22px;
    background-color: #fff;
    border: 1.26923px solid
      ${({ colorBorder, theme }) => colorBorder || theme.colors.greyDark};
    border-radius: 2.53846px;
  }

  &:hover input ~ .checkmark {
    background-color: #fff;
  }

  & input:checked ~ .checkmark {
    background-color: #fff;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 7px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: 1.26923px solid #c2c2c2;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
