import { action, makeObservable } from "mobx";

import api from "src/http/api";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  public loader: LoadingStore;

  constructor(loader: LoadingStore) {
    super();
    this.loader = loader;
    makeObservable(this, {
      createDaysAndTimes: action,
    });
  }
  public createDaysAndTimes = async (onSuccess: () => void, daysAndTimes) => {
    try {
      this.loader.start();

      await api.createDaysAndTimes(daysAndTimes);

      this.showSuccess("Dias e usuários configurados com sucesso!");
      onSuccess();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
