import React, { InputHTMLAttributes } from "react";

import { Container } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  color?: string;
  label: string;
  rowStyle?: boolean;
  datetimeLocal?: boolean;
}

export const InputDate: React.FC<InputProps> = (props) => {
  const { rowStyle, label, color, datetimeLocal, ...rest } = props;
  return (
    <Container rowStyle={rowStyle} color={color}>
      <label>{label}</label>
      <input
        type={datetimeLocal ? "datetime-local" : "date"}
        {...rest}
        max="2099-12-31"
        min="1899-01-01"
      />
    </Container>
  );
};
