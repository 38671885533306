import { action, makeObservable, observable } from "mobx";
import Swal from "sweetalert2";

import api, { INewPayment, INewUser, IOrder } from "src/http/api";
import masks from "src/resources/masks";
import CitiesService, { ICity } from "src/services/cities";
import StatesService, { IState } from "src/services/states";
import ViaCepService, { IZipCodeData } from "src/services/viaCep";
import AuthStore from "src/stores/AuthStore";
import FormControllerStore from "src/stores/FormControllerStore";
import LoadingStore from "src/stores/LoadingStore";
import UIStore from "src/stores/UIStore";

export default class Store extends UIStore {
  private loader: LoadingStore;
  private authStore: AuthStore;
  public citiesService: CitiesService;
  public statesService: StatesService;
  public viaCepServiceService: ViaCepService;

  public acceptTerms = false;
  public cities: ICity[] = [];
  public states: IState[] = [];
  public zipCodeData?: IZipCodeData;
  public order?: IOrder;

  public currentScreen: "initial" | "credit_card" | "pix" | "bank_slip" =
    "initial";

  public plans;
  public plan;
  public selectedPlan;
  public referKey;

  public formController = new FormControllerStore({
    plan_id: "",
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    acting: "",
    completePhone: "",
    cpf_cnpj: "",
    reference: "",
    zip_code: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    state_id: "",
    city_id: "",
    paymentMethod: "",
    cardName: "",
    cardNumber: "",
    cardExpirationMonth: "",
    cardCVV: "",
  });

  constructor(loader: LoadingStore, authStore: AuthStore) {
    super();
    this.loader = loader;
    this.authStore = authStore;
    this.statesService = new StatesService();
    this.citiesService = new CitiesService();
    this.viaCepServiceService = new ViaCepService();

    makeObservable(this, {
      getCitiesByState: action,
      getStates: action,
      getPlans: action,
      setCurrentScreen: action,
      toggleAcceptTerms: action,
      acceptTerms: observable,
      cities: observable,
      currentScreen: observable,
      order: observable,
      selectedPlan: observable,
      referKey: observable,
      states: observable,
      plan: observable,
      plans: observable,
    });
  }

  public toggleAcceptTerms = (accepted: boolean) => {
    this.acceptTerms = accepted;
  };

  public setCurrentScreen = (currentScreen: typeof this.currentScreen) => {
    this.currentScreen = currentScreen;
  };

  public updateSubscriptionPayment = async (onSuccess?: () => void) => {
    try {
      this.loader.start();
      const {
        plan_id,
        name,
        email,
        password,
        password_confirmation,
        acting,
        completePhone,
        cpf_cnpj,
        reference,
        paymentMethod,
        complement,
        state_id,
        city_id,
        neighborhood,
        street,
        number,
        zip_code,
        cardCVV,
        cardExpirationMonth,
        cardNumber,
        cardName,
      } = this.formController.getFields();

      const phone_prefix = completePhone.replace(/\D/gim, "").substr(0, 2);
      const phone = completePhone.replace(/\D/gim, "").substr(2, 11);

      const userPayload: INewUser = {
        name,
        email,
        password,
        password_confirmation,
        cpf_cnpj,
        phone,
        phone_prefix,
        acting,
        player_ids: "",
        profile: "hall",
        reference,
      };

      const ownerName = cardName.split(" ");

      const paymentPayload: INewPayment = {
        plan_id: parseInt(plan_id),
        payment_method: paymentMethod,
        card_params:
          paymentMethod === "credit_card"
            ? {
                first_name: ownerName[0],
                last_name: ownerName[ownerName.length - 1],
                number: cardNumber.trim(),
                verification_value: cardCVV.trim(),
                month: cardExpirationMonth.split("/")[0],
                year: cardExpirationMonth.split("/")[1],
                installments: 0,
              }
            : undefined,
        refer_key: this.referKey,
        user_address: {
          zip_code,
          number,
          street,
          neighborhood,
          city:
            this.cities.find((city) => city.id === parseInt(city_id))?.name ||
            "",
          state:
            this.states.find((state) => state.id === parseInt(state_id))
              ?.short_name || "",
          complement,
          address_charge: true,
        },
      };

      const createdUser = await api.register(userPayload);

      this.authStore.setAxiosHeadersForAuthenticatedUsers(createdUser);

      this.order = await api.createPayment(paymentPayload);

      if (paymentMethod === "credit_card" && onSuccess) {
        let timer;
        Swal.fire({
          icon: "success",
          title: "Pagamento Confirmado",
          html: "Redirecionamento automatico em <span></span>s",
          confirmButtonText: "Acessar o app",
          confirmButtonColor: "#218838",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          timer: 60000,
          didOpen: () => {
            timer = setInterval(() => {
              const seg = Swal.getTimerLeft();
              const htmlContent =
                Swal.getHtmlContainer()?.querySelector("span");
              if (htmlContent && seg) {
                htmlContent.textContent = (seg / 1000).toFixed(0);
              }
            }, 100);
          },
          willClose: () => {
            clearInterval(timer);
          },
        }).then(() => {
          onSuccess();
          this.authStore.loginRegister(
            this.formController.getField("email").value,
            this.formController.getField("password").value,
          );
        });
        return;
      }
      if (paymentMethod === "pix") {
        this.setCurrentScreen("pix");
        return;
      }
      if (paymentMethod === "bank_slip") {
        this.setCurrentScreen("bank_slip");
        return;
      }
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public getPlans = async () => {
    try {
      this.loader.start();
      this.plans = await api.getPlans();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public getStates = async () => {
    try {
      this.loader.start();
      this.states = await this.statesService.getStates();
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public getCitiesByState = async (stateId: number) => {
    try {
      this.loader.start();
      this.cities = await this.citiesService.getCitiesByState(stateId);
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };

  public onChangeZipCode = (e) => {
    this.formController.getField("zip_code").onChange(e, masks.zipCode);
    const zipCode = e.target.value.replace(/\D/g, "");
    this.getZipCodeData(zipCode);
  };

  public getZipCodeData = async (zipCode: string) => {
    if (zipCode.length === 0) {
      this.formController.setField("street", "");
      this.formController.setField("neighborhood", "");
      this.formController.setField("state_id", "");
      this.formController.setField("city_id", "");
    }

    if (zipCode.length < 8) {
      return;
    }

    try {
      this.loader.start();
      const response = await this.viaCepServiceService.getZipCodeData(zipCode);

      if (response.erro) {
        this.showError("Cep não encontrado!");
      }

      this.formController.setField("street", response.logradouro);
      this.formController.setField("neighborhood", response.bairro);
      const state = this.states
        .filter(function (uf) {
          return uf.short_name === response.uf;
        })
        .map(function (uf) {
          return uf.id;
        });

      this.formController.setField("state_id", state[0]);
      const cities = await this.citiesService.getCitiesByState(state[0]);
      const selectedCity = cities
        .filter(function (allCities) {
          return allCities.name === response.localidade;
        })
        .map(function (allCities) {
          return allCities.id;
        });

      this.formController.setField("city_id", selectedCity[0]);

      this.zipCodeData = response;
    } catch (e) {
      this.showError(this.handleError(e));
    } finally {
      this.loader.end();
    }
  };
}
