import React from "react";
import theme from "src/theme";

import { Button, Modal, ModalHeader, Input } from "../../../../components";
import { Container, BodyModal } from "./styles";

interface IProps {
  visible: boolean;
  setVisible: () => void;
}

const EmployeeProfileModal: React.FC<IProps> = (props) => {
  const { visible, setVisible } = props;

  const onSave = () => {
    // TODO: not implemented
  };

  return (
    <Container>
      <Modal
        visible={visible}
        closeModal={setVisible}
        content={
          <BodyModal>
            <ModalHeader title="Alterar senha" onGoBack={setVisible} />
            <Input label="Senha" type="password" required />
            <Input label="Confirmar senha" type="password" required />
            <Button title="Salvar senha" color={theme.colors.orangeDark} />
          </BodyModal>
        }
      />
    </Container>
  );
};

export default EmployeeProfileModal;
