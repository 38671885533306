import styled from "styled-components";

export const LinkTag = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.orangeDark};

  :hover {
    color: ${({ theme }) => theme.colors.purpleDark};
  }
`;
