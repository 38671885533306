import { observer, useLocalStore } from "mobx-react";
import moment from "moment";
import React, { ReactElement, useEffect } from "react";
import { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useDebouncedCallback } from "use-debounce";

import ScreenContainer from "src/layout/ScreenContainer";

import { useGlobalStore } from "src/context/global";
import { useModal } from "src/context/modal";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import formatters from "src/resources/formatters";
import theme from "src/theme";
import { Button, InputDate, InputCheckBox, Select } from "src/ui/components";

import FinancialDetailsScreen from "./../FinancialDetailsScreen";
import colorByCategory from "./mapper/colorByCategory";
import Store from "./store";
import {
  Container,
  Summary,
  ExtractUpdated,
  Legend,
  LegendItem,
  Icon,
  Value,
} from "./styles";
import { IUser } from "src/http/api";

const FinancialScreen: React.FC = () => {
  const { showModal, closeModal } = useModal();
  const { loadingStore } = useGlobalStore();
  const [filters, setFilters] = useState({});
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [categoriesFilters, setCategoriesFilters] = useState<string[]>([]);
  const store = useLocalStore(() => new Store(loadingStore));
  const { width } = useWindowDimensions();
  const [employee, setEmployee] = useState("");

  const user = JSON.parse(localStorage.getItem("user")!) as IUser;

  useEffect(() => {
    const date = new Date();
    const firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const initialFilters = {
      from: moment(firstDayMonth).format("YYYY-MM-DD"),
      to: moment(lastDayMonth).format("YYYY-MM-DD"),
    };

    setFrom(initialFilters.from);
    setTo(initialFilters.to);
    store.getEmployees(String(user.id));
    handleFilters(initialFilters);
  }, []);

  function handleFilterCategories(filterSelected) {
    let updatedFilters: string[] = [];

    const indexFilter = categoriesFilters.indexOf(filterSelected);
    if (indexFilter >= 0) {
      categoriesFilters.splice(indexFilter, 1);
      updatedFilters = categoriesFilters;
    } else {
      updatedFilters = [...categoriesFilters, filterSelected];
    }
    handleFilters({ "categories[]": updatedFilters.join(",") });
    setCategoriesFilters(updatedFilters);
  }

  const handleFilters = useDebouncedCallback((filterEnabled) => {
    const filtersUpdated = { ...filters, ...filterEnabled };
    store.getFinancials(filtersUpdated);
    setFilters(filtersUpdated);
  }, 1500);

  useEffect(() => {
    store.getFinancials(filters, employee);
  }, [employee]);

  const loggedInAs = JSON.parse(localStorage.getItem("loggedInAs")!) as string;

  return (
    <ScreenContainer>
      <Container>
        {width < 700 && (
          <>
            <span className="title-filters">
              Filtre o período desejado para obter os resultados.
            </span>
            <div className="filters">
              <InputDate
                label="Data inicial"
                value={from}
                onChange={(event) => {
                  setFrom(event.target.value);
                  handleFilters({ from: event.target.value });
                }}
              />
              <InputDate
                label="Data final"
                value={to}
                onChange={(event) => {
                  setTo(event.target.value);
                  handleFilters({ to: event.target.value });
                }}
              />
            </div>
          </>
        )}

        {user.profile === "hall" && !loggedInAs && (
          <Select
            label="Profissional"
            options={store.employees.map((e) => ({
              value: String(e.id),
              name: e.name,
            }))}
            fixed="Todos"
            onChange={(e) => setEmployee(e.target.value)}
            value={employee}
          />
        )}

        <span>Resumo do período</span>
        <Summary>
          <div>
            <span>RECEITAS</span>
            <span className="text-green">
              R$ {formatters.toCurrency(store.financials?.total_incoming || 0)}
            </span>
          </div>

          <div className="output">
            <div>
              <span>DESPESAS</span>
              <span className="text-red">
                R$ {formatters.toCurrency(store.financials?.total_output || 0)}
              </span>
            </div>
            <div className="output__outhers">
              <span>JÁ PAGO</span>
              <span className="text-red">
                R${" "}
                {formatters.toCurrency(
                  store.financials?.total_output_paid_true || 0,
                )}
              </span>
            </div>
            <div className="output__outhers">
              <span>A PAGAR</span>
              <span className="text-red">
                R${" "}
                {formatters.toCurrency(
                  store.financials?.total_output_paid_false || 0,
                )}
              </span>
            </div>
          </div>
        </Summary>

        <span>Extrato Atualizado</span>
        <ExtractUpdated>
          {width > 700 && (
            <div className="filters__legend">
              <span className="filters__title">
                Filtre o período desejado para obter os resultados.
              </span>
              <div className="filters__input">
                <InputDate
                  label="Data inicial"
                  value={from}
                  onChange={(event) => {
                    setFrom(event.target.value);
                    handleFilters({ from: event.target.value });
                  }}
                />
                <InputDate
                  label="Data final"
                  value={to}
                  onChange={(event) => {
                    setTo(event.target.value);
                    handleFilters({ to: event.target.value });
                  }}
                />
              </div>
            </div>
          )}

          <Legend>
            <LegendItem>
              <InputCheckBox
                label="Receitas"
                checked={categoriesFilters.includes("revenue")}
                onChange={useDebouncedCallback(() => {
                  handleFilterCategories("revenue");
                }, 1000)}
                colorBorder="#41CE88"
              />
            </LegendItem>

            <LegendItem>
              <InputCheckBox
                label="Despesas"
                checked={categoriesFilters.includes("expenses")}
                onChange={useDebouncedCallback(() => {
                  handleFilterCategories("expenses");
                }, 1000)}
                colorBorder="#F2E029"
              />
            </LegendItem>

            <LegendItem>
              <InputCheckBox
                label="Impostos"
                checked={categoriesFilters.includes("taxes")}
                onChange={useDebouncedCallback(() => {
                  handleFilterCategories("taxes");
                }, 1000)}
                colorBorder="#EB5757"
              />
            </LegendItem>

            <LegendItem>
              <InputCheckBox
                label="Salários"
                checked={categoriesFilters.includes("salary")}
                onChange={useDebouncedCallback(() => {
                  handleFilterCategories("salary");
                }, 1000)}
                colorBorder="#F2994A"
              />
            </LegendItem>

            <LegendItem>
              <InputCheckBox
                label="Outros"
                checked={categoriesFilters.includes("others")}
                onChange={useDebouncedCallback(() => {
                  handleFilterCategories("others");
                }, 1000)}
              />
            </LegendItem>
          </Legend>

          <div className="table">
            <div className="table__header">
              <span>Detalhes do lançamento</span>
              <span>Saldo</span>
            </div>

            <div className="table__section">
              {Object.keys(store.financialsReport).map((launchDay) => {
                const lunch: ReactElement[] = [];
                lunch.push(
                  <div className="table__title" key={launchDay}>
                    <span>{launchDay}</span>
                  </div>,
                );

                const financialOfTheDay = store.financialsReport[launchDay];

                for (const financial of financialOfTheDay) {
                  lunch.push(
                    <div
                      className="table__item"
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      key={`${financial.description} ${financial.id}`}
                    >
                      <div className="table__value">
                        <Icon color={colorByCategory[financial.category]} />
                        <span className="grow1">
                          {financial.user_name}
                          <br />
                          <span style={{ fontSize: ".9rem", color: "grey" }}>
                            {financial.description}
                          </span>
                          {/* <div className="table__details">14:00 - Pintura</div> */}
                        </span>
                        <Value
                          color={
                            financial.financial_type === "incoming"
                              ? theme.colors.green
                              : theme.colors.redDark
                          }
                        >
                          {financial.paid ? (
                            <span className="value__paid">Pago</span>
                          ) : (
                            " "
                          )}

                          {financial.discount ? (
                            <span className="value__discount ">
                              {"R$ "}
                              {formatters.toCurrency(financial.price)}
                            </span>
                          ) : (
                            " "
                          )}

                          <span>
                            {financial.financial_type === "incoming"
                              ? "+"
                              : "-"}{" "}
                            {"R$ "}
                            {formatters.toCurrency(
                              financial.price - financial.discount,
                            )}
                          </span>
                        </Value>
                        <FaAngleRight
                          onClick={() =>
                            showModal({
                              content: (
                                <FinancialDetailsScreen
                                  id={financial.id}
                                  closeModal={closeModal}
                                  onList={store.getFinancials}
                                  employees={store.employees}
                                />
                              ),
                              onClickOut: closeModal,
                            })
                          }
                          style={{
                            marginLeft: "6px",
                            fill: theme.colors.orange,
                          }}
                        />
                      </div>
                    </div>,
                  );
                }

                return lunch;
              })}
            </div>

            <div className="table__footer">
              <span>Total</span>
              <span>
                R${" "}
                {formatters.toCurrency(
                  store.financials?.total_incoming -
                    store.financials?.total_output || 0,
                )}
              </span>
            </div>

            <div className="btn-insert-manual">
              <Button
                title="Inserir Manual"
                color={theme.colors.orange}
                outlined
                onClick={() =>
                  showModal({
                    content: (
                      <FinancialDetailsScreen
                        closeModal={closeModal}
                        onList={store.getFinancials}
                        employees={store.employees}
                      />
                    ),
                    onClickOut: closeModal,
                  })
                }
              />
            </div>
          </div>
        </ExtractUpdated>
      </Container>
    </ScreenContainer>
  );
};

export default observer(FinancialScreen);
