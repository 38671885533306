import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const SwitchInput = styled.label`
  position: relative;
  display: inline-block;
  width: 57.52px;
  height: 23.43px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 6px;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    width: 21.3px;
    height: 21.3px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.colors.purpleDark};
  }

  input:focus + .slider {
    box-shadow: ${({ theme }) => `0 0 1px ${theme.colors.purpleDark}`};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 10.4822px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
