import styled from "styled-components";

export const Content = styled.div`
  margin-top: 16px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 32px;

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #198754;
  }

  svg {
    color: #198754;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;

  span {
    font-weight: 600;
    font-size: 16px;
    color: #495057;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #495057;
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
`;
