import styled from "styled-components";

interface IContainerProps {
  highlightedTextColor?: string;
}

export const Container = styled.div<IContainerProps>`
  // Mes (header)
  width: 100%;

  div.react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
    margin-bottom: 20px;

    button.react-calendar__navigation__arrow {
      height: 16px;
    }

    button.react-calendar__navigation__label {
      flex-grow: unset !important;
    }
  }

  span.react-calendar__navigation__label__labelText {
    color: ${({ theme }) => theme.colors.dark};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    font-weight: 500;
    text-transform: capitalize;
  }

  // Dias da semana (header)
  .react-calendar__month-view__weekdays {
    padding: 5px 0;
    text-transform: capitalize;

    > .react-calendar__month-view__weekdays__weekday {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.dark};
      font-size: ${({ theme }) => theme.fontSizes.s16}px;
      text-align: center;
    }
  }

  // Dias do mes
  .react-calendar__tile {
    border: none;
    background: ${({ theme }) => theme.colors.white};
    text-align: center;
    width: 32px;
    height: 32px;
    margin: 4px 0;
    padding: 0 4px;

    > abbr {
      color: ${({ theme }) => theme.colors.greyDarker};
      font-size: ${({ theme }) => theme.fontSizes.s16}px;
      font-weight: 500;
    }
  }

  // Dias do (proximo/anterior) mes
  .react-calendar__month-view__days__day--neighboringMonth {
    > abbr {
      color: ${({ theme }) => theme.colors.grey}!important;
      font-weight: ${({ theme }) => theme.fontWeights.normal}!important;
    }
  }

  // Dia selecionado
  .react-calendar__tile--active {
    background: ${({ color }) => color};
    border-radius: 5px;
    > abbr {
      color: ${({ theme }) => theme.colors.white};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }

  // Dia desabilitado
  .react-calendar__tile:disabled {
    cursor: default;
    > abbr {
      color: ${({ theme }) => theme.colors.grey}!important;
      text-decoration: line-through;
      font-weight: normal;
    }
  }
`;
