import React from "react";
import { Content } from "./styles";
import { Title } from "src/ui/pages/ClientScreen/styles";
import { Calendar } from "src/ui/components";
import { CompanyTheme } from "src/types/company.interface";
import moment from "moment";
import { useQuery } from "react-query";
import api from "src/http/api";
import Skeleton from "react-loading-skeleton";
import { CalendarTileProperties } from "react-calendar";
import { BlockedDays } from "src/services/appointments";

interface Props {
  daySelected?: Date;
  employeeId: string;
  appointmentsOfMonth: BlockedDays | null;

  theme: CompanyTheme;
  onSelectDay: (date: Date) => void;
}

const weekDayMap = {
  0: "domingo",
  1: "segunda",
  2: "terça",
  3: "quarta",
  4: "quinta",
  5: "sexta",
  6: "sábado",
};

const AppointmentCalendar = ({
  daySelected,
  employeeId,
  appointmentsOfMonth,
  theme,
  onSelectDay,
}: Props) => {
  console.log("🚀 ~ file: index.tsx:39 ~ employeeId:", employeeId);
  const {
    data: workingDays,
    isLoading,
    error,
  } = useQuery(["workingDays", employeeId], () =>
    api.getDaysAndTimes(employeeId),
  );

  const disponibleWeekDays = workingDays?.map((day) => day.weekday);
  const blockedDates = appointmentsOfMonth?.appointment_blocks.map((date) =>
    moment(date.starts_at).format("YYYY-MM-DD"),
  );

  function disabledDates({ date, view }: CalendarTileProperties) {
    if (view === "month") {
      const dateString = moment(date).format("YYYY-MM-DD");
      if (moment(date).isBefore(moment(), "day")) return true;
      if (
        disponibleWeekDays &&
        !disponibleWeekDays.includes(weekDayMap[date.getDay()])
      )
        return true;
      if (blockedDates?.includes(dateString)) return true;

      if (!appointmentsOfMonth) return false;
    }

    return false;
  }

  return (
    <Content>
      <Title>Selecione uma data:</Title>

      {isLoading && <Skeleton width={"100%"} height={272} />}
      {error && <p>Não foi possível carregar datas</p>}

      {!isLoading && !error && (
        <Calendar
          color={theme.primary}
          value={daySelected}
          minDate={new Date()}
          onChange={onSelectDay}
          tileDisabled={disabledDates}
        />
      )}
    </Content>
  );
};

export default AppointmentCalendar;
