import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: red;
  min-height: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.greyLighter};
  padding: 80px 0 220px 0;
  > .content-wrapper {
    max-width: 1200px;
    padding: 0 20px;
    margin-top: 45px;
    @media (min-width: 900px) {
      padding: 0 20px 0 270px;
      margin: auto;
    }
  }
`;
